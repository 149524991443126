export default {
    //login
    "loading": "加載中...",
    "SignUp": "註冊",
    "LoginSuccess": "成功登錄",
    "LoginOut": "成功登出",
    "email": "電子郵件",
    "password": "密碼",
    "logIn": "登錄",
    "WelcomeBack": "歡迎回來",
    "PleaseEnterYourEmailAndPassword": "請輸入您的電子郵件和密碼",
    "CharactersRequired": "需要8個以上字符",
    "keepMeSignedIn": "保持我登錄狀態",
    "forgetPassword": "忘記密碼",
    "dontHaveAnAccount": "還沒有帳戶？",
    "signUpNow": "立即註冊",
    "AllRightsReserved": "版權所有",
    "privacyPolicy": "隱私政策",
    "and": "和",
    "termsOfService": "服務條款",
    "Name": "姓名",
    "viewAll": "查看全部",
    "fold": "折疊",
    "updateCreditCard": "更新信用卡",
    "role": "角色",
    "noLimit": "無限制",

    //signup
    "company": "公司",
    "companyName": "公司名稱",
    "signUp": "註冊",
    "alreadyHaveAnAccount": "已經有帳戶？",
    "getStarted": "開始使用！用我們的平台賦能您的業務",
    "signUpSuccess": "成功註冊",

    //forget
    "noWorries": "不用擔心，輸入您的註冊電子郵件，我們將發送重置說明",

    //reset
    "reset": "重置密碼",
    "forgotPassword": "忘記密碼",
    "RequestPasswordReset": "請求重置密碼",
    "backToLogin": "返回登錄",
    "verifyPassword": "驗證密碼",
    "EnterANewPasswordFor": "為 example@gmail.com 輸入新密碼",
    "ResetPassword": "重置密碼",
    "newPassword": "新密碼",

    //top-nav
    "Home": '首頁',
    "Search": '搜索',
    "Lead": '潛在客戶',
    "Prospects": '潛在客戶',
    "filterYourSearchToFindHighlySpecific": '過濾您的搜索以找到高度特定的',
    "groupsOfTargetedLeads": '目標潛在客戶組',
    "filterYourSearchToFindPreciselyTargeted": '過濾您的搜索以找到精確定位的',
    "listsOfCompanies": '公司列表',
    "transformAnonymousWebsiteTrafficInto": '將匿名網站流量轉換為',
    "b2bLeadsWithRealtimeIdentification": '實時識別的B2B潛在客戶',
    "discoverAndConnectWithPotentialBuyers": '發現並與潛在客戶聯繫',
    "activelySeekingYourSolutionToday": '今天積極尋求您的解決方案',
    "Intent_Signals": '意圖信號',
    "Website_Intent": '網站意圖',
    "Market_Intent": '市場意圖',
    "Sequence": '工作流程',
    "Tools": '工具',
    "Services": '企業服務',
    "technologyLookup": '技術查詢',
    "domainLookup": '域名查詢',
    "emailVerification": '電子郵件驗證',
    "upgrade": '升級',
    "helpCenter": '幫助中心',
    "contactUs": '聯繫我們',
    "feedback": '反饋',
    "hello": '你好',
    "clickToChange": '點擊更改',
    "yourProfile":"您的個人資料", 
    "currentUsage":"當前使用情況", 
    "upgradePlan":"升級計劃", 
    "integrations":"集成", 
    "featureRequests":"功能請求", 
    "change":"更改", 
    "en":"英文", 
    "cn":"中文（简）", 
    "tw":"中文（繁）", 
    "logout":"登出", 
    "switchLanguage":"成功切換語言",

    //not available
    "ComingSoon": "敬請期待！",
    "TheFeatureIscurrentlyUnder": "該功能目前正在",
    "development": "開發中",
    "weAppreciateYourPatienceAsWeWorkToImprove": "我們感謝您在我們努力改善",
    "yourExperience": "您的體驗時的耐心。",
    "inTheMeantimeIfYouHaveAnyQuestionsOrNeed": "在此期間，如果您有任何問題或需要",
    "assistancePleaseContactOurSupportTeam": "協助，請聯繫我們的支持團隊。",
    "contactSupport": "聯繫支持",
    "ok": "確定",
    "thankYouForBeingAValuedUserOfOurPlatform": "感謝您成為我們平台的珍貴用戶。",

    //search-company
    "people": "潛在客戶",
    "companies": "公司",
    "clearAll": "清除所有",
    "searchProspects": "搜索潛在客戶、關鍵字...",
    //actions
    "isFoundIn": "在 ",
    "isNotFoundWithinAnyLists": "未在任何列表中找到",
    "l": " 列表",
    "remove": "移除",
    "saveToLists": "保存到列表",
    "DeleteFailed": "刪除失敗",
    //delete dialog
    "areYouSureYouWantToDeleteThisRecord": "您確定要刪除此記錄嗎？",
    "proceedingWithDeletionWillResultInPermanentRemovalOfTheList": "進行刪除將導致該列表永久移除。",
    "delete": "刪除",
    "byCancelingYouEnsureThatNoListsWillBeRemoved": "通過取消，您可以確保不會移除任何列表。",
    //export dialog
    "holdOnWereGeneratingYourExport": "請稍候，我們正在生成您的導出文件。",
    "UseThisButtonOnlyIfYouWishToCancelYourAction": "僅在您希望取消操作時使用此按鈕。",
    //save search pop
    "saveSearch": "保存搜索",
    "newSaveSearch": "新保存搜索",
    "UpdateSavedSearch": "更新已保存搜索",
    "enterNewSearchName": "輸入新搜索名稱",
    "visibility": "可見性",
    "manageWhoCanSeeYourSavedSearch": "管理誰可以查看您的已保存搜索",
    "private": "私有",
    "onlyYouCanSeeYourSavedSearch": "只有您可以查看您的已保存搜索",
    "everyoneCanSeeYourSavedSearch": "每個人都可以查看您的已保存搜索",
    "pinSavedSearch": "釘選已保存搜索",
    "findSavedSearch": "查找已保存的搜索",
    "enterSavedSearchName": "輸入已保存搜索名稱",
    "duplicate": "重複",
    "update": "更新",
    "noSavedSearches": "沒有已保存的搜索",
    "RemoveCreditCard": '移除信用卡',
    "enterATitleForYourCard": '為您的卡片輸入標題',
    "enterYourCardNumber": '輸入您的卡號',
    "digit": '3位數',
    "provideYourCompleteAddress": '提供您的完整地址',
    "selectYourCountry": '選擇您的國家',
    //add credits pop
    "ClickTheButtonBelowToAddMoreCredits": '點擊下面的按鈕以添加更多信用點數！',
    "PleaseEnterTheAmountYouLikeToTopUpToYourCredit": '請輸入您希望充值的金額',
    //companyLink\contactBtn\functionBtn
    "more": "更多",
    "verified": "已驗證",
    "GetEmailSuccessfully": "成功獲取電子郵件",
    "CopySuccessfully": "成功複製",
    "recorded": "已記錄",
    "implement": "實施",
    "sequence": "工作流程",
    "of": '  總條數：',
    "toAssistYouEffectivelyWeRequireFurtherDetailsAbout": "為了有效協助您，我們需要進一步了解您的搜索偏好。請利用左側面板中的篩選選項來指定您所需的標準，以便我們能夠精煉搜索結果，並為您提供符合要求的目標。",
    //filter
    "all": "所有",
    "my": "我的",
    "team": "團隊",
    "lists": "列表",
    "employees": "員工",
    "technologies": "技術",
    "socialmedia": "社交媒體",
    "enterSocialMedia": "輸入社交媒體",
    "noResultsFound": "未找到結果",
    "searchCompanies": "搜索公司",
    "filters": "篩選器",
    "enterCompanies": "輸入公司名稱",
    "accountHqLocation": "帳戶總部位置",
    "CityStateCountryZIP": "城市/州/國家/郵政編碼",
    "enterLocations": "輸入地點",
    "revenue": "收入",
    "chooseARangeOfRevenue": "選擇收入範圍",
    "ofRevenueIsUnknown": "收入未知",
    "technology": "技術",
    "category": "類別",
    "categories": "類別",
    "enterCategories": "輸入類別",
    "enterTechnologies": "輸入技術",
    "enterIndustries": "輸入行業",
    "employee": "員工",
    "predefinedRange": "預定義範圍",
    "ofEmployeesIsUnknown": '員工數量未知',
    "enterNames": '輸入姓名',
    "jobTitle": '職位名稱',
    'searchForAJobTitle': '搜索職位名稱',
    'founded': '成立於',
    'chooseARangeOfFoundedTime': '選擇成立時間範圍',
    'from': '從',
    'to': '到',
    'specialties': '專業領域',
    'enterSpecialties': '輸入專業領域',
    'location': '地點',
    'total': '總計',
    'department': '部門',
    'EnterDepartments': '輸入部門',
    'JobTitle': '職位名稱',
    'EnterJobTitles': '輸入職位名稱',
    'ManagementLevel': '管理層級別',
    'EnterManagementLevels': '輸入管理層級別',
    'AccountHQ': '帳戶總部',
    'ContactHQ': '聯絡總部',
    'departmentFunction': '部門功能',
    'EnterJobFunctions': '輸入職務功能',
    'enterNamesAndPressTheEnterKey': '輸入姓名並按Enter鍵...',
    'EnterJobTitlesAndPressTheEnterLKey': '輸入職位並用“,”分隔，然後按Enter鍵...',
    'pleaseSelectRange': '請選擇範圍',

    //table
    "selectAllCompanies": "選擇所有公司",
    "selected": "已選擇",
    "export": "導出",
    "industry": "行業",
    "keywords": "關鍵字",
    "companyLocation": "公司位置",
    "action": "操作",
    "PleaseEnterTheSelectedQuantity": "請輸入所選數量",
    "contact": "聯絡人",
    "Title": "職稱",
    "selectThisPage": "選擇此頁面",
    "clearSelection": "清除選擇",
    "selectNumberOfCompanies": "選擇公司數量",
    "maxCompanies": "最多公司數量",
    "youCanOnlySelectAMaximumOf25Companies": "您最多只能選擇25家公司。",
    "UpgradeYourPlanNow": "立即升級您的計劃！",
    "viewPlans": "查看計劃",
    "applySelection": "應用選擇",
    "selectNumberOfProspects": "選擇潛在客戶數量",
    "maxProspects": "最多潛在客戶數量",
    "youCanOnlySelectAMaximumOf25Prospects": "您最多只能選擇25個潛在客戶。",
    "PleaseSelectTheDataToBeExported": "請選擇要導出的數據",
    "overview": "概覽",
    "genericContacts": "通用聯絡人",
    "size": "規模",
    "contactLocation": "聯絡人位置",
    "addYourCreditCardInformation": '添加您的信用卡信息',

    //account
    "addCreditCard": "添加信用卡",
    "zip": "郵政編碼",
    "country": "國家",
    "state": "州",
    "city": "城市",
    "address": "地址",
    "billingInformation": "帳單信息",
    "yourTeams": "您的團隊",
    "cvc": "CVC",
    "year": "年",
    "month": "月",
    "expirationDate": "到期日",
    "creditCardNumber": "信用卡號碼",
    "cardInformation": "卡片信息",
    "title": "職稱",
    "fullName": "全名",
    "yourDetail": "您的詳細信息",
    "creditsToUse": "可用信用點數。",
    "youHave": "您擁有",
    "download": "下載",
    "buyMoreCredits": "購買更多信用點數",
    "description": "描述",
    "topUpCredits": "充值信用點數",
    "cancel": "取消",
    "date": "日期",
    "invoiceHistory": "發票歷史",
    "confirmNewPass": "確認新密碼",
    "creditsUsed": "已使用信用點數",
    "newPass": "新密碼",
    "youHaveNotEnteredAnyCreditCardInformationYet": '您尚未輸入任何信用卡信息。',
    "oldPass": '舊密碼',
    "cardNumber": '卡號：',
    "changePassword": '更改密碼',
    "creditUsage": '信用點數使用情況',
    "typeTheNewTitle": '輸入新職稱',
    "save": '保存',
    "addMoreCredits": '添加更多信用點數',
    "featureRequest": '功能請求',
    "creditsMo": '{credit} 信用點數/月',
    "apiIntegrations": 'API 集成',
    "profileSubscription": '個人資料訂閱',
    "additional-user-licence": '額外用戶許可證',
    "agency-white-label": '代理商白標',
    "api-extended-limit": 'API 擴展限制',
    "profile-subscription": '個人資料訂閱',
    "creditsInYourPlan": '計劃中的信用點數',
    "plugin": '插件',
    "settings": '設置',
    "phone": '電話',
    "1User": '1個用戶',
    "users": '用戶',
    "billing": '帳單',
    "monthlyQueryLimit": '每月查詢上限',
    "price": '價格',
    "whatsIncluded": '包含內容',
    "givenName": '名',
    "profile_teams": "個人資料與團隊",
    "purchasePlan": "購買計劃",
    "accountOverview": "帳戶概覽",
    "familyName": "姓氏",
    "dailyQueryLimit": "每日查詢上限",
    "rateLimit": "查詢限額",
    "youAreOnA": "您正在使用一個",
    "PlanAndYourCreditsWillRefreshOn": "計劃，您的信用點數將在以下日期刷新",
    "过期": "",
    "searchSettings": "搜索設置...",
    "plan": "計劃",
    "accountInfo": "帳戶信息",
    "creditCardInformation": "信用卡信息",
    "apiUsage": "API 使用情況",
    "mo": "/月",
    "perUserPerMonth": "每位用戶每月",
    "billedAnnually": "按年計費",
    "dailyQuery": "每日查詢",
    "monthlyQuery": "每月查詢",
    "currentPlan": "當前計劃",
    "everythingInPlus": "所有加值內容",
    "mostPopular": "最受歡迎的",
    "topUpToIncreaseYourCredits": "充值以增加您的信用點數",
    "addCredits": "添加信用點數",
    "allPlansInclude": "所有計劃均包括",
    "Amount": "金額",
    "credits": "信用點數",
    "purchaseSuccess": "成功購買信用點數，您的總信用點數為 ",
    "Loading": "加載中...",
    "Selected": "已選擇",
    "SelectPlan": "選擇計劃",
    "currentPlan": "當前訂閱",
    "upgradeSuccess": "計劃切換成功",
    "purchaseFailed": "購買失敗",
    "upgradeFailed": "計劃切換失敗",
    "inviteSentSuccessfully": '邀請已成功發送',
    "DeleteSuccessfully": '刪除成功',
    "AccountupdatedSuccessfully": '帳戶更新成功',
    "AccountUpdateFailed": '帳戶更新失敗',
    "PasswordUpdatedSuccessfully": '密碼更新成功',
    "PasswordUpdateFailed": '密碼更新失敗',
    "teamDetails": '團隊詳細信息',
    "teamName": '團隊名稱',
    "changeTeam": "更改團隊",
    "clickToChangeTeam": "點擊以更改團隊",
    "changeTeamSuccessfully": "成功更改團隊",
    "status": "狀態",
    "Action": "操作",
    "pending": "待處理",
    "current": "當前",
    "AreYouSureYouWantToDeleteThisCreditCard": "您確定要刪除此信用卡嗎？",
    "AreYouSureYouWantToDeleteThisTeamMember": "您確定要刪除此團隊成員嗎？",
    "PasswordCannotBeEmpty": "密碼不能為空",
    "newUser": "新用戶",
    "deleteUser": "刪除用戶",
    "nickname": "暱稱",
    "admin": "管理員",
    "inviteYourTeam": "邀請您的團隊",
    "empowerEveryoneToGetMoreDealsDonefaster": "賦能每個人更快地完成更多交易",
    "addMultipleEmailAddressesSeparatedByCommas": "添加多個電子郵件地址，以逗號分隔",
    "sendInvites": "發送邀請",
    "FirstName": "名字",
    "LastName": "姓氏",
    "timezone": "時區",
    "contactNo": "聯繫電話",
    "controlYourTeamMembersAndTheirAccountAccessRightHere": "在此控制您的團隊成員及其帳戶訪問權限。",
    "pleaseEnterYourCurrentPasswordToChangeYourPassword": "請輸入您的當前密碼以更改密碼。",
    "updatePassword": "更新密碼",
    "oldPassword": "舊密碼",
    "confirmNewPassword": "確認新密碼",
    "InviteYourTeam": '邀請您的團隊',
    "empowerEveryoneToGetMoreDealsDoneFaster": '賦能每個人更快地完成更多交易。',
    "enterEmailAddressesMultipleCommaseparated": '輸入電子郵件地址（多個以逗號分隔）',
    "invoice": '發票',
    "accountAdmin": '帳戶管理員',
    "setAsDefault": '設為默認',
    "bankName": '銀行名稱',
    "expiry": '到期日',
    "viewAllYourPreviousInvoicesHere": '在此查看您所有的歷史發票。',
    "BillingHistory": '帳單歷史',
    "updateYourCreditCardInformation": '更新您的信用卡信息。',
    "ManageCreditCard": '管理信用卡',
    "CardNumber": '卡號',
    "billingAddress": '帳單地址',
    "yourCreditsWillRefreshOn": '您的信用點數將在以下日期刷新',
    "yourCreditsWillBeChargeOn": '您的信用點數將在以下日期收費',
    "被收取": "",
    "/year": "/年",
    "/month": "/月",
    "monthlyCredit": '每月信用點數',
    'Usage': '使用情況',
    'features': '功能',
    'credits_mo': '信用點數/月',
    'credits_year': '信用點數/年',
    '_mo': '/月',
    'seeOtherPlan': '查看其他計劃',
    'subscriptionTiers': '訂閱層級',
    'experiencePricingThatsEasyToUnderstandAndAdaptable': '體驗易於理解和適應您需求的定價。',
    'monthlyBilling': '每月計費',
    'annualBilling': '年度計費',
    'forPlanCustomizationContactOurSalesTeam': '如需計劃定制，請聯繫我們的銷售團隊。',
    'billedAnnually_user': '按年計費/用戶',
    'seePlanComparison': '查看計劃比較',
    'topUp': '充值',
    'changeTeamNameFailed': '更改團隊名稱失敗',
    // purchaseCreditsAtTheRateOf100PerCreditCustomizeyourCreditBundleBasedOnYourNeeds: '按1美元/信用點數購買信用點數，請根據您的需求定制信用點數包',
    "totalPrice": "總價格",
    "credit": "信用點數",
    "collapse": "折疊",
    "expand": "展開",

    // Intent
    "intentOverview": "概覽",
    "intentAnalytic": "分析",
    "intentFilter": "篩選",
    "searchCompany": "搜索公司、網站、LinkedIn...",
    "savedCompanyWebsite": "已保存的公司網站",
    "companiesFound": "間找到的公司",
    "companyFound": "間找到的公司",
    "filterList": {
        "technologies": "技術",
        "time": "時間",
        "locations": "帳戶位置",
        "verticals": "行業",
        "pageview_greater_than": "頁面瀏覽量",
        "session_greater_than": "會話數",
        "advertisements": "廣告",
        "company_groups": "列表",
        "start_date": "開始日期",
        "end_date": "結束日期",
        "search_term": "搜索詞"
    },
    "employeeFilterList": {
        "industry": "行業",
        "technology": "技術",
        "jobTitle": "職位名稱",
        "company": "公司",
        "list": "列表",
        "name": "姓名",
        "size": "規模",
        "location": "地點",
        "jobFunction": "職務功能"
    },
    "selectLists": '選擇列表...',
    "chooseARangeOfTime": '選擇時間範圍',
    "greaterThan": '大於',
    "selectAdvertisement": '選擇廣告',
    // intentIndustry: '行業',
    "tabActivity": '活動',
    "tabOverview": '概覽',
    "tabEmployees": '員工',
    "tabContacts": '通用聯絡人',
    "tabTechnologies": '技術',
    "intentStatistic": '統計',
    "intentCampaign": '活動',
    "newVisitors": '新訪客',
    "recurringVisitors": '回訪訪客',
    "mediocreLead": '一般潛在客戶',
    "sessionsTracked": '已追蹤會話',
    "sessionTracked": '已追蹤會話',
    "lastSeenOn": '最後一次出現於 ',
    "firstSeenOn": '第一次出現於 ',
    "viewTrackedWebSession": '查看已追蹤的網絡會話',
    "webSession": '網絡會話',
    "client": '客戶',
    "timeSpent": '花費時間',
    "pageviews": '頁面瀏覽量',
    "pageview": '頁面瀏覽量',
    "referrer": '來源',
    "sessions": '會話數',
    "session": '會話',
    "visited": '已訪問',
    // Time indicators
    "AM": "上午",
    "PM": "下午",
    "companyDetails":"公司詳細信息", 
    "overviewList":{
        "location":"地點", 
        "industry":"行業", 
        "founded":"成立於", 
        "ranking":"排名", 
        "size":"規模", 
        "revenue":"預估收入", 
        "keywords":"關鍵字"
    },
    "advancedSearch": "進階搜索",
    "jobFunction": "職務功能",
    "employeeLocation": "位置",
    "concatLocation": "聯絡位置",
    "contactEmail": "電子郵件",
    "categoryAndTechnology": "類別與技術",
    "firstDetected": "首次檢測",
    "lastDetected": "最後檢測",
    "saved": "已保存",
    "addToContactList": "添加到聯絡人列表",
    "noCampaignIdentified": "未識別任何活動",
    "copyEmail": "複製電子郵件",
    "willBeSavedToTheFollowingSelectedLists": "將保存到以下選定列表：",
    "addToLists": "添加到列表",
    "noDataToDisplay": "無數據可顯示",
    "intentFounded": "成立於",
    "intentIndustry": "行業",
    "intentRevenue": "收入",
    "intentSize": "規模",
    "applyFilters": "應用篩選器",
    "youCanEditTheseFilters": "您可以隨時編輯這些篩選器以精煉結果",
    "installB2BDirectLeadFinder": '在您的網站上安裝B2B直接潛在客戶查找器',
    "addSite": '添加網站',
    "areYouSureYouWantToRemoveThisWebsite": '您確定要移除此網站嗎？',
    "Cancel": '取消',
    "installB2BLeadFinder": '安裝B2B潛在客戶查找器',
    "siteUrl": '網站網址',
    "copyToClipboard": '複製到剪貼板',
    "pleaseSelectAContactFirst": '請先選擇一個聯絡人',
    "pasteCodeBelowIntoTheHead": '將以下代碼粘貼到每個您希望追蹤訪問者的頁面',
    "ofEveryPageYouWishToTrackVisitorOn": '的每個頁面。',
    "forWordpressWeRecommendInstalling": '對於WordPress，我們建議安裝來自Stefano Lissa的',
    "pluginFromStefanoLissa": '插件。',
    "afterInstallingAndActivating": '安裝並啟用插件後，請導航至',
    "inYourWordPressDashboard": '在您的WordPress儀表板中。',
    "youWillSeeANumberOfTabs": '您將在插件界面中看到多個標籤。確保您在默認的“標頭和頁腳”標籤中，然後複製並粘貼以下代碼片段：',
    "settingHeaderAndFooter": '設置 → 標頭和頁腳',
    "dontForgetToClearYourCache": '不要忘記清除您的緩存',
    "ifYouAreUsingAnyCaChing": '如果您使用任何緩存插件，例如',
    "orSimilar": '或類似的，請確保在安裝代碼片段後清除緩存！',
    "GTMshouldAlreadyBeInstalled": 'Google Tag Manager 應該已經安裝在您的網站上，然後再按照這些說明操作。',
    "ifYouDontHaveGTM": '如果您尚未安裝Google Tag Manager，您可以在以下位置了解如何安裝：',
    "GTMsupportArticleAboutInstall": 'Google Tag Manager 支持文章有關安裝的內容。',
    "signInToGTMAndClick": '登錄Google Tag Manager，然後單擊“添加新標籤”按鈕。接下來，',
    "clickWhereItSays": '單擊顯示的地方，',
    "chooseATagTypeToBeginSetup": '選擇一個標籤類型以開始設置',
    "andSelect": '並選擇 ',
    "fromTheList": '從列表中。然後，將以下代碼複製並粘貼到文本字段中：',
    "nextClick": '接下來，單擊 ',
    "giveTheTagAName": '. 給標籤命名，然後單擊保存按鈕。',
    "dontForgetToPublishYourChange": '不要忘記發布您的更改',
    "afterCreateTheCustom": '創建自定義HTML標籤後，不要忘記 ',
    "yourChanges": ' 您的更改！',
    "submitAndPublish": '提交並發布',
    "copySuccessfully": '成功複製',
    "addToCompanyList": '添加到公司列表',
    "addToList": '添加到列表',
    "enterOrCreateList": '輸入或創建列表',
    "installCode": '安裝代碼',
    "wordpress": 'WordPress',
    "googleTagManager": 'Google Tag Manager',
    "noVisitorsYet": '尚無訪問者',
    "onceTheInsightsRollIn": '一旦獲得見解，您將解鎖有關訪問者及更多的寶貴信息。',
    "noTechAreFound": '未找到技術信息',
    "updateSuccessfully": '成功更新',
    "enterJobFunction":"輸入職務功能", 
    "pinSuccessfully":"釘選成功", 
    "cancelPinSuccessfully":"取消釘選成功", 
    "adsName":"廣告名稱", 
    "utmTerm": 'utmTerm',
    "utmCampaign": 'utmCampaign',
    "utmSource": 'utmSource',
    "utmMedium": 'utmMedium',
    "enterNumber":"輸入數字", 
    "enterName":"輸入姓名", 
    "enterJobTitle":"輸入職位名稱", 
    "areYouSureYouWantToRemovePeopleOfThisGroup":"您確定要移除此組中的聯絡人嗎？", 
    "areYouSureYouWantToRemoveCompanyOfThisGroup":"您確定要移除此組中的公司嗎？", 
    "byCancelingYouEnsureThatNoPeopleWillBeRemoved":"通過取消，您可以確保不會移除任何聯絡人。", 
    "byCancelingYouEnsureThatNoCompanyWillBeRemoved":"通過取消，您可以確保不會移除任何公司。", 
    "howToGetStarted":"如何開始", 
    "Data": '條數據',
    "deactivateWebsite":"停用網站", 
    "areYouCertainAboutDeactivatinYourWebsite":"您確定要停用您的網站嗎？請注意，這將導致不接收任何潛在公司的活動。", 
    "byCancelingYouWillContinueToActivateAndUse":"通過取消，您將繼續啟用和使用此工具。", 
    "yourTrialHasEnded":"您的試用已結束。", 
    "reactivateNow":"立即重新啟用", 
    "yourWebsiteHasBeenAutomaticallyInactivated":"您的網站已自動停用。您是否希望重新啟用此工具以繼續監控數據？", 
    "Inactivated":"已停用", 
    "onTrial":"試用中", 
    "Active": '啟用設定',
    "youAreOnTrail": "您正在進行7天的試用",
    "yourTrailHasEnded": "您的試用已結束",
    "createNewWebIntent": "創建新的網絡意圖",
    "clickOnTheButton": "1. 點擊（+）按鈕",
    "typeInUrlOrWebAddress": "2. 輸入網址或網站地址",
    "theTrialWillActivate": "勾選此框將啟動7天的試用",
    "pleaseEnterASiteUrl": "請輸入網站網址",
    "noMoreData": "沒有更多數據",
    "pleaseEnterANewName": "請輸入一個新名稱",
    "pleaseSelectASearchName": "請選擇一個搜索名稱",
    "clickToGetEmail": "點擊以獲取電子郵件",
    "emailIsNotAvailable": "電子郵件不可用",
    "enterOrCreateLists": "輸入或創建列表",
    "viewMore": "查看更多",
    "removeFromContactList": "從聯絡人列表中移除",
    "willBeRemovedFromTheFollowingLists": "將從以下列表中移除",
    "removeFromLists": "從列表中移除",
    "pleaseSelectOrCreateAList": "請選擇或創建一個列表",
    "chooseATriggerToMakeThisTagFire": "選擇一個觸發器以使此標籤觸發...",
    "youWontBeChargedYet": "您尚未被收費",
    "youDontHaveEnoughCredits": "您沒有足夠的信用點數",
    "wouldYouLikeToAdd50Credits": "您想添加50點（最低充值金額）以繼續發送電子郵件嗎？",
    "ThisEmailRequire": '此電子郵件需要',
    "TheseEmailsRequires": '這些電子郵件需要',
    "intentCredit": '信用點數',
    "intentCredits": '信用點數',
    // analytic
    "showNumber": "顯示數字",
    "Website": "網站",
    "b2bTraffic": "B2B 流量",
    "b2bReturningVsNew": "B2B 回訪與新訪客",
    "visitors": "訪客",
    "totalView": "總瀏覽量",
    "totalSessions": "總會話數",
    "operatingSystem": "操作系統",
    "browser": "瀏覽器",
    "device": "設備",
    "companySize": "公司規模",
    "topIndustry": "主要行業",
    "topCountries": "主要國家",
    "topReferrals": "主要推薦來源",
    "new": "新訪客",
    "returning": "回訪者",
    "countriesStatistics": "國家統計",
    "topCompaigns": "主要活動",
    "topPagesByVisits": "按訪問量排序的頁面",
    "topPagesByEngagement": "按互動量排序的頁面",
    "Referrals": "推薦來源",
    "Pages": "頁面",
    "avgTime": "平均時間",
    "totalCompanies": '總公司數',
    "b2bPageViews": 'B2B 頁面瀏覽量',
    "TotalSessions": '總會話數',
    "viewCompanyList": '查看公司列表',
    "noDiscussionsYet": '尚無討論',
    "useThisSpaceToAskQuestions": '您可以使用此空間提出問題、獲取更新，並直接與我們討論詳情',
    // mycontact
    "prospects": '潛在客戶',
    "list": '列表',
    "view": '查看',
    "add": '添加',
    "newList": '新列表',
    "savedLists": '已保存列表',
    "searchList": '搜索列表名稱',
    "owner": '擁有者',
    "records": '記錄',
    "actions": '操作',
    "lastModified": '最後修改時間',
    "name": '名稱',
    "enterAListName": '輸入列表名稱',
    "pleaseSelectAListFirst": '請先選擇一個列表',
    "saveSuccessfully": '保存成功',
    "pleaseEnterAListName": '請輸入列表名稱',
    "editList": '編輯列表',
    "fromThisList": '從此列表中。您確定要繼續嗎？',
    "areYouSureYouWantToChange": '您確定要將列表類型更改為',

    // yes: '是', 
    // no: '否', 
    "noListCreated": "未創建列表", 
    "enhanceTheOrganization": "增強您在目標市場內聯絡人和帳戶的組織。創建列表後，它們將顯示在此頁面上。",
    "createSuccessfully": "創建成功", 
    "removeSuccessfully": "移除成功", 
    "createNewList": "創建新列表", 
    "groupTeam": "團隊", 
    "groupPersonal": "個人", 
    "edit": "編輯", 
    "areYouSureYouWantToDeleteTheList": "您確定要刪除該列表嗎？", 
    "proceedingWithDeletion": "進行刪除將導致該列表永久移除。", 
    "byCanceling": "通過取消，您可以確保不會移除任何列表。" , 
    "byCancelingYouEnsureThatNo": "通過取消，您可以確保不會移除任何", 
    "willBeRemoved": "將被移除", 
    "groupPeople":"聯絡人", 
    "groupCompany":"公司", 
    "myList":"我的列表", 
    "teamList":"團隊的列表", 
    "resetAssured":"請放心，您可以根據需要在不同類型之間切換。", 
    "wontBeChanged":"通過取消，您可以確保此列表類型不會更改", 
    "priority":"優先級", 
    "proceedingWithDeletionWillResultInPeopleDelete":"進行刪除將導致聯絡人永久移除", 
    "proceedingWithDeletionWillResultInCompanyDelete":"進行刪除將導致公司永久移除", 
    "areYouSureYouWantToRemoveCompanyFromList":"您確定要從列表中移除公司嗎？", 
    "areYouSureYouWantToRemovePeopleFromList":"您確定要從列表中移除聯絡人嗎？", 
    "groupExisted":"該組已存在", 
    "sorryTheListIsCurrrntlyOpen":"抱歉，該列表目前是開放的。您確定要刪除它嗎？", 
    "listName":"名稱", 
    "pleaseEnterAValidSiteUrl":"請輸入有效的網站網址",

    // market-intent
    "comingSoon":"即將推出", 
    "weAreCurrentlyWorkingOnThisFeature":"我們目前正在開發此功能，將很快推出", 
    "stayConnectedWithUs":"通過我們的社交媒體渠道保持聯繫，以接收更新。", 
    "selectAWebsite":"選擇一個網站",

    // sequence
    "Sequences": "工作流程",
    "label": "標籤",
    "template": "模板",
    "folder": "文件夾",
    "single": "單一",
    "group": "群組",
    "steps": "步驟",
    "sizes": "大小",
    "time": "時間",
    //email
    "SendATestEmail": "發送測試電子郵件",
    "Categories": "類別",
    "addToCategory": "添加到類別",
    "EnterOrCreateCategories": "輸入或創建類別",
    "addSuccessfully": "添加成功",
    "Channels": "渠道",
    "emails": "電子郵件",
    "enterChannel": "輸入渠道",
    "CategoryName": "類別名稱",
    "PleaseEnterANewCategoryName": "請輸入新類別名稱：",
    "EnterANewCategoryName": "輸入新類別名稱",
    "Steps": "步驟",
    "Actions": "操作",
    "Time": "時間",
    "From": "來自",
    "To": "至",
    "CancelSequenceCreation": "取消工作流程創建",
    "AreYouSureYouWantToCancelTheSequenceCreationProcess": "您確定要取消工作流程創建過程嗎？",
    "SaveAsDraft": "保存為草稿",
    "CancelSequence": "取消工作流程",
    "ByCancelingAllSettingsWillBeLost": "通過取消，您所做的所有設置和進度將會丟失。",
    "ImportContacts": '導入聯絡人',
    "PleaseUploadACSVFileByDraggingAndDroppingItHere": '請通過拖放CSV文件到此處上傳',
    "ChooseAFile": '選擇文件',
    "CSVFileFields": 'CSV文件字段',
    "ReplyFields": '回覆字段',
    "continue": '繼續',
    "Contacts": '聯絡人',
    "Back": '返回',
    "Import": '導入',
    "Imported": '已導入',
    "Skipped": '已跳過',
    "Done": '完成',
    "NewCustomField": '新自定義字段',
    "EnterNewLabel": '輸入新標籤',
    "EnterNewType": '輸入新類型',
    "removeRecorded": '移除記錄',
    "YouHaveUnsavedChanges": '您有未保存的更改',
    "LeavingDiscard": '離開此頁面將丟棄所有未保存的內容。您確定要繼續嗎？',
    "StayPage": '留在此頁面',
    "LeavePage": '離開此頁面',
    "NewCategory": '新類別',
    "Create": '創建',
    "UnlockingSequence": '解鎖此工作流程使您能夠進行修改',
    "ModifyingLeadDeselection": '修改此內容將導致當前模板版本的取消選擇',
    "Modify": '修改',
    "CancelingPreserveCurrent": '取消將保留當前版本',
    "rename": '重新命名',
    "CreatNewLabel": "創建新標籤", 
    "NewLabel": "新標籤", 
    "PleaseEnterANewLabelName": "請輸入新標籤名稱：",
    "EnterANewLabelName": "輸入新標籤名稱",
    "SequenceName": "工作流程名稱",
    "PleaseProvideNameForNewSequence": "請提供新工作流程的名稱",
    "EnterNewSequenceName": "輸入新工作流程名稱",
    "SetUpEmail": "設置電子郵件帳戶以發送",
    "NewSchedule": "新日程",
    "PleaseEnterANewScheduleName": "請輸入新日程名稱：",
    "EnterNewScheduleName": "輸入新日程名稱",
    "AddNewTemplate": "添加新模板",
    "RemoveFromTemplate": "從模板中移除",
    "deleteSuccess": "刪除成功",
    "removeSuccess": "移除成功",
    "GroupName": "群組名稱",
    "PleaseEnterNewGroupName": "請輸入新群組名稱",
    "EnterNewGroupName": "輸入新群組名稱",
    "TemplateName": "模板名稱",
    "PleaseEnterANewTemplateName": "請輸入新模板名稱",
    "EnterNewTemplateName": "輸入新模板名稱",
    "renameSuccessfully": "重命名成功",
    "CreateSuccessfully": "創建成功",
    "addToGroup": "添加到群組",
    "SelectChannelForNewTemplate": "為新模板選擇渠道",
    "Role": "角色",
    "Account": "帳戶",
    "customFields": "自定義字段",
    "contactsToStartEngagingWithThem": "聯絡人以開始與他們互動。",
    "thisSequenceDoesntHaveAnyProspectsOrLeadsYetConsid": "此工作流程尚未有任何潛在客戶或線索。考慮添加",
    "noProspectsAdded": "未添加潛在客戶",
    "deliveryCurrentlyNoMessagesHaveBeenSentThroughTheS": "投遞。目前，尚未通過該工作流程發送任何消息。",
    "ensureEfficientCommunicationByMonitoringYourSequen": "通過監控您的工作流程消息來確保有效的溝通。",
    "noMessagesHaveBeenSent": "尚未發送任何消息",
    "yourNeedsGetStartedByClickingNewSequence": "滿足您的需求。通過點擊 [+ 新工作流程] 開始。",
    "beginBuildingYourSalesStrategiesByDesigningSequenc": "通過設計符合您需求的工作流程開始構建銷售策略。",
    "noSequencesCreatedYet": "尚未創建工作流程",
    "leadActions": "潛在客戶操作",
    "leadInformation": "潛在客戶信息",
    "thisGroupHasNoTemplatesYet": "該組尚無模板",
    "key": "{expr}",
    "copyContent": "複製內容",
    "default": "默認",
    "selectVersion": "選擇版本",
    "subject": "主題",
    "searchTemplatesfolders": "搜索模板/文件夾",
    "send": "發送",
    "subject2": "主題：",
    "enterASubjectOrLeaveItBlankToContinueInTheSameThre": "輸入主題或留空以繼續在同一線程中進行",
    "chooseYourChannel": "選擇您的渠道",
    "kl": 'KL',
    "addContacts": '添加聯絡人',
    "addContactsToSequence": '將聯絡人添加到工作流程',
    "addremoveCustomContactField": '添加/移除自定義聯絡人字段',
    "contactCustomFields": '聯絡人自定義字段',
    "type": '類型',
    "typePhoneNumber": '輸入電話號碼',
    "addAPhoneNumber": '添加電話號碼',
    "fieldType": '字段類型',
    "fieldLabel": '字段標籤',
    "skipTheFollowingNationalHolidaysLaborDayIndependen": '跳過以下國定假日：勞動節、獨立日、紀念日、感恩節、聖誕前夕、聖誕節、新年。',
    "checked": '已勾選',
    "useTheContactsLocalTimeZoneInsteadOfTheSchedulesTi": '如果聯絡人包含位置數據，則使用聯絡人的本地時區而不是日程的時區。',
    "definePostreplyOrMeetingActionAndSequenceCompletio": '定義回覆後或會議行動及工作流程完成標準。',
    "stopRepliesHandling": '停止回覆處理',
    "timeZone": '時區：',
    "country2": '國家',
    "setMaxInitialEmailsDelaysEnabledisableOpensAndLink": '設置最大初始電子郵件、延遲、啟用/禁用打開和鏈接跟踪。',
    "configuration": '配置',
    "additionalInformation": '附加信息',
    "connectALinkedinAccount": '連接LinkedIn帳戶',
    "newLinkedinAccount": '新LinkedIn帳戶',
    "feelFreeToRevisitAnyOfThePreviousStepsAtAnyTimeToR": '隨時可以重新訪問之前的任何步驟，以檢查或更新您的信息，然後再\"啟動\"工作流程。',
    "template2":"模板：", 
    "next":"下一步", 
    "jacquelineLeung":"Jacqueline Leung", 
    "settings2":"設置", 
    "editThisTemplateScheduleFromThe":"從中編輯此模板日程", 
    "typeEmail":"輸入電子郵件", 
    "templateScheduling":"模板排程", 
    "launch":"啟動", 
    "linkedin":"LinkedIn", 
    "addAEmail":"添加電子郵件", 
    "addEmailAccount":"添加電子郵件帳戶", 
    "addStep":"添加步驟", 
    "newEmailAccount":"新電子郵件帳戶", 
    "craftYourLeadgenerationJourneyWithANewSequenceDefi":"使用新的工作流程打造您的潛在客戶生成旅程！定義您的目標受眾、消息和行動，以創建有效的潛在客戶生成工作流程。", 
    "craftYourCampaign":"打造您的活動", 
    "tailorYourOwnHoursToSuitSpecificSchedulingNeeds":"根據特定排程需求量身定制自己的時間。", 
    "personalizedScheduling":"個性化排程", 
    "requiredInformation":"所需信息", 
    "chooseEmailAccountsForSendingCampaignMessages":"選擇發送活動消息的電子郵件帳戶", 
    "accounts":"帳戶", 
    "enterSequenceName":"輸入工作流程名稱", 
    "thisPageEmpowersYouToTailorYourSchedulingSequenceS":"此頁面使您能夠根據您的偏好調整排程工作流程設置。", 
    "schedule":"排程", 
    "useThisOptionToAddContactsToTheSequenceYouHaveCrea":"使用此選項將聯絡人添加到您創建的工作流程中。", 
    "addProspects":"添加潛在客戶", 
    "save2":"保存", 
    "days":"天", 
    "days2":"天", 
    "within":"內部", 
    "opened":"已打開", 
    "day":"天", 
    "day2":"天", 
    "waitFor": "等待",
    "sendTestEmailToMe": "發送測試電子郵件給我",
    "create": "創建",
    "enterANameForCustomContactField": "輸入自定義聯絡人字段的名稱",
    "a": "A",
    "byCancelingYouEnsureThatNoStepsWillBeDeleted": "通過取消，您可以確保不會刪除任何步驟",
    "to2": "至",
    "enterNameForNewVersion": "輸入新版本的名稱",
    "activated": "已啟用",
    "currentVersion": "當前版本",
    "setTheNewVersionDefaultNow": "立即設置新版本為默認",
    "enterNewName": "輸入新名稱",
    "newVersion": "新版本",
    "dontForgetToSetTheNewVersionDefaultOnceYouveFinish": "完成修改後，請勿忘記設置新版本為默認。",
    "theDeletionOfThisConditionWillAlsoRemoveAllSubsequ": "刪除此條件將同時移除所有後續步驟。",
    "pleaseEnterANewName2": "請輸入一個新名稱：",
    "aNewVersionOfTheSequenceHasBeenCreated": "工作流程的新版本已創建。",
    "youCanEitherEditTheCurrentVersionOrSaveItAndCreate": "您可以編輯當前版本或保存並創建一個新版本。",
    "areYouSureYouWantToProceed": "您確定要繼續嗎？",
    "newVersionName": "新版本名稱",
    "sequenceStart": "工作流程開始",
    "chooseActions": "選擇操作",
    "chooseSteps": "選擇步驟",
    "enterChannel2": "輸入渠道",
    "chooseCategories": "選擇類別",
    "htmlMode": "HTML模式",
    "considerBroadeningYourSearchCriteria": "- 考慮擴大您的搜索標準。",
    "checkYourSpellingAndMakeSureThereAreNoTypos": "- 檢查拼寫並確保沒有錯別字。",
    "tryUsingDifferentKeywordsOrPhrases": "- 嘗試使用不同的關鍵字或短語。",
    "noResultsFoundForYourSearchCriteria": "未找到符合您搜索標準的結果",
    "fromScratch": "從頭開始",
    "sender": "發件人",
    "finished": "完成",
    "inProgress": "進行中",
    "fromTemplate": "來自模板",
    "newSequence": "新工作流程",
    "nameOwner": "名稱擁有者",
    "sort": "排序",
    "ownedBy": "擁有者",
    "starredOnly": "僅顯示星標",
    "starred": "星標",
    "box2": '箱子2',
    "box1": '箱子1',
    "add2": "+ 添加",
    "lastSynced": '最後同步',
    "hourlyLimit": '每小時限制',
    "dailyLimit": '每日限制',
    "account": '帳戶',
    "skipTheFollowingNationalHolidaysLaborDayIndependen2": '跳過以下國定假日：勞動節、獨立日、紀念日、感恩節、聖誕前夕、聖誕節、新年。',
    "useTheContactsLocalTimeZoneInsteadOfTheSchedulesTi2":"如果聯絡人包含位置數據，則使用聯絡人的本地時區而不是日程的時區。",
    "saveChanges":"保存更改", 
    "emailSignature":"電子郵件簽名", 
    "reset2":"重置", 
    "pleaseNoteTheValueShouldBe0OrEmails":"請注意，值應該 >0 或 < {expr}", 
    "youHaveNoScheduledTemplateYet":"您尚未安排模板", 
    "discoverPremiumOptions":"發現高級選項", 
    "mailbox":"郵箱", 
    "sendingLimits":"發送限制", 
    "connect":"連接", 
    "joinOurCommunityOfPremiumUsersTransformingEmailEng":"加入我們的高級用戶社區，通過Pubrio轉變電子郵件互動並實現卓越的銷售成果。", 
    "enterYourEmailAddress":"輸入您的電子郵件地址", 
    "toJustMailbox":"僅發送到 {mailNum} 郵箱", 
    "yourCurrentBasicPlanAllowsAccess":"您當前的基本計劃允許訪問", 
    "setAsDefault2":"設為默認", 
    "createNewSchedule":"創建新日程", 
    "enterTemplateName":"輸入模板名稱", 
    "googleMeet":"Google Meet", 
    "emailPreview":"電子郵件預覽", 
    "addTemplate":"添加模板", 
    "simplifyYourWorkflowGroupYourSequenceTemplatesToKe":"簡化您的工作流程！將您的工作流程模板分組以保持組織，方便查找、編輯和根據需要使用。", 
    "startCreatingPersonalizedTemplatesForYourSequences":"開始為您的工作流程創建個性化模板！單擊「+」按鈕以創建新模板，輕鬆流暢地進行通信。", 
    "noTemplateYet":"尚無模板", 
    "preview":"預覽", 
    "creatNewGroup":"創建新群組", 
    "creatNewGroup2":"創建新群組", 
    "stayOrganizedWithYourSequenceTemplatesCreateGroups":"保持您的工作流程模板有序！創建群組以輕鬆管理和定位您的模板，使編輯和使用變得更加簡單。", 
    "noGroupsHaveBeenCreated":"尚未創建任何群組", 
    "creatNewTemplate": "創建新模板",
    "addSequenceTemplateToTheGroup": "將工作流程模板添加到群組",
    "byCancelingYouEnsureThatNoChangesAreMade": "通過取消，您可以確保不會進行任何更改",
    "sequenceTemplates": "工作流程模板",
    "youCanDeselectAndClickSaveToRemoveTheTemplateFromT": "您可以取消選擇並單擊「保存」以將模板從群組中移除",
    "selectTemplateToAddToGroup": "選擇要添加到群組的模板",
    "sequenceStatus": "工作流程狀態",
    "sequenceStep": "工作流程步驟",
    "newField": "新字段",
    "enableTailoredDataEntryAndAdaptationToUniqueBusine": "啟用量身定制的數據輸入和適應獨特的業務流程",
    "customContactFields": "自定義聯絡人字段",
    "modality": "模式",
    "enterADescriptionForCustomContactField": "輸入自定義聯絡人字段的描述",
    "description2": "描述",
    "enterADefaultValueForCustomContactField": "輸入自定義聯絡人字段的默認值",
    "defaultValue": "默認值",
    "enterAExampleForCustomContactField": "輸入自定義聯絡人字段的示例",
    "example": "示例",
    "enterPicklistoptionsForCustomContactField": "輸入自定義聯絡人字段的下拉選項",
    "newCustomContactFields": "新的自定義聯絡人字段",
    "engagementMetrics": "互動指標",
    "message": "消息",
    "to3": "至：",
    "receiver": "接收者",
    "claraHan": "Clara Han",
    "createNewCategory": "創建新類別",
    "bestRegards": "最好的問候，",
    "thanksAgain": "再次感謝！",
    "lookingForwardToAFruitfulCollaborationIfTheresAnyt": "期待富有成效的合作。如果有任何具體事項需要在開始之前解決，請隨時告訴我們。",
    "jacquelineLeungProjectInCharge": "Jacqueline Leung（負責項目）",
    "kingLaiAdviser": "King Lai（顧問）",
    "iAppreciateTheIntroductionToOurMainContacts": "感謝您介紹我們的主要聯絡人：",
    "contacted": "已聯絡",
    "sendTestEmail": "發送測試電子郵件",
    "thankYouSoMuchForTheWarmWelcomeWereEquallyExcitedA": "非常感謝您的熱情歡迎！我們同樣對與Pubrio GTM啟動項目和成為Venture AI團隊的一部分感到興奮。",
    "hiJacqueline": "嗨，Jacqueline，",
    "nov152023118Pm": "2023年11月15日，下午1:18",
    "message2": "{total_messages} 訊息",
    "topEcommerceAutomationPlatf": "頂級電子商務自動化平台...",
    "cindyHsu": "Cindy Hsu",
    "includeSignature": "包含簽名",
    "ch": "CH",
    "continueOnFail": "失敗時繼續",
    "welcomeToPubrioGtm": "歡迎來到Pubrio GTM",
    "replyToPreviousThread": "回覆之前的線程",
    "sendAutomatic": "發送自動 {title}",
    "searchByContacts": "按聯絡人搜索",
    "processingErrorOccurredMaximumNumberOfUniqueContac": "處理錯誤：每月唯一聯絡人的最大數量已達到。",
    "oct140954Pm": "10月14日，09:54 PM",
    "shiftdragToMoveTheCanvas": 'Shift+拖動以移動畫布',
    "active": '活躍',
    "inactive": '不活躍',
    "archived": '已歸檔',
    "archive": '歸檔',
    "unarchive": '取消歸檔',
    "archivedSuccessfully": '成功歸檔',
    "unarchivedSuccessfully": '成功取消歸檔',
    "sequenceArchivedSuccess": '工作流程成功歸檔',
    "sequenceUnarchivedSuccess": '工作流程成功取消歸檔',
    "alphabetical": '按字母順序',
    "creationDate": '創建日期',
    "viewDate": '查看日期',
    "staredFirst": '首標星優先',
    "SaveAsANewVersion":"另存為新版本", 
    "SaveCurrentVersion":"保存當前版本", 
    "schedules":"日程", 
    "EmailsSentPerDay":"每日發送的電子郵件", 
    "EmailsSentPerHour":"每小時發送的電子郵件", 
    "DelayBetweenEachEmailSent":"每封電子郵件發送之間的延遲（秒）", 
    "RecommendedDailyLimit":"建議每日限制：50-100封電子郵件，只有在發送高度個性化的活動且保持強大的域名聲譽時，才可例外達到100封以上。", 
    "RecommendedHourlyLimit":"建議每小時限制：將電子郵件數量保持在100封以下。每小時不得超過120封電子郵件。", 
    "currentDelayAllows":"當前延遲允許您每小時最多發送120封電子郵件。", 
    "SetAsDefault":"設為默認", 
    "monday":"星期一", 
    "tuesday":"星期二", 
    "wednesday":"星期三", 
    "thursday":"星期四", 
    "friday":"星期五", 
    "saturday":"星期六", 
    "sunday":"星期日", 
    "Templates":"模板", 
    "Analytics":"分析", 
    "Meetings":"會議", 
    "Settings":"設置", 
    "EnterTemplateName":"輸入模板名稱", 
    "EnterGroupName":"輸入群組名稱", 
    "ExportAllData":"導出所有數據", 
    "ExportAsCSV":"導出為CSV", 
    "Total":"總計", 
    "Opened":"已打開", 
    "Clicked":"已點擊", 
    "NotOpened":"未打開", 
    "Bounced":"退回", 
    "Replied": "已回覆",
    "Failed": "失敗",
    "Inbox": "收件箱",
    "Report": "報告",
    "Log": "日誌",
    "UseThisTemplate": "使用此模板",
    "UseThisFolder": "使用此文件夾",
    "UpdateThisTemplate": "更新此模板",
    "RemoveFromGroup": "從群組中移除",
    "DeleteThisTemplate": "刪除此模板",
    "MarkAsFinished": "標記為完成",
    "Remove": "移除",
    "Pause": "暫停",
    "Resume": "恢復",
    "AddToSequence": "添加到工作流程",
    "Export": "導出",
    "InProgress": "進行中",
    "Finished": "完成",
    "Paused": "已暫停",
    "Engaged": "參與中",
    "Reply": "回覆",
    "NotSent": "未發送",
    "MaxNumberOfEmailsPerDay": "每日最大電子郵件數",
    "MaxNumberOfEmailsPerHour": "每小時最大電子郵件數",
    "EnableOpensTracking": "啟用打開跟踪",
    "EnableReplyTracking": "啟用回覆跟踪",
    "EnableClickTracking": "啟用點擊跟踪",
    "ReplyByMessage": "通過消息回覆",
    "BookAMeeting": "預訂會議",
    "ClickOnLink": "點擊鏈接",
    "CreateManually": "手動創建",
    "AddContactsToTheSequenceManually": "手動將聯絡人添加到工作流程，一個一個。",
    "ImportFromCSV": "從CSV導入",
    "UploadCSVFileToAddContactsToSequence": "上傳CSV文件以將聯絡人添加到工作流程。",
    "AddFromExisting": "從現有中添加",
    "AddExistingContactsIntoTheSequence": "將現有聯絡人添加到工作流程。",
    "ChooseFile": "選擇文件",
    "MapAttributes": "映射屬性",
    "Options": "選項",
    "ReviewAndImport": "審核並導入",
    "UpdateMissingFields": "更新缺失字段",
    "DetectContactTimeZoneAutomatically": "自動檢測聯絡人時區",
    "account_website": '帳戶網站',
    'linkedin_url': 'LinkedIn網址',
    'raw_address': '原始地址',
    'first_name': '名字',
    'last_name': '姓氏',
    'account_name': '帳戶名稱',
    "Identifier":"識別碼", 
    "firstName":"名字", 
    "lastName":"姓氏", 
    "EnterJobTitleOrPosition":"輸入職位名稱或職位", 
    "EnterCompanyName":"輸入公司名稱", 
    "EnterYourCustomerID":"輸入您的客戶ID", 
    "EnterCompanyWebsiteURL":"輸入公司網站URL", 
    "EnterLinkedInProfileURL":"輸入LinkedIn個人資料URL", 
    "EnterCity":"輸入城市", 
    "versionName":"版本名稱", 
    "PleaseEnterANewVersionName":"請輸入新版本名稱", 
    "EnterNewVersionName":"輸入新版本名稱", 
    "versions":"版本", 
    "SequenceNameUpdatedSuccessfully":"工作流程名稱更新成功", 
    "SequenceNameCannotBeEmpty":"工作流程名稱不能為空",
    "delete_success": "刪除成功",
    "delete_fail": "刪除失敗",
    "set_default_success": "設置默認成功",
    "DuplicateVersionSuccessfully": "成功複製版本",
    "CreateNewVersionSuccessfully": "成功創建新版本",
    "UpdateSuccessfully": "更新成功",
    "AddToGroupSuccessfully": "成功添加到群組",
    "sendAutomaticEmail": "發送自動電子郵件",
    "sendMessage": "發送消息",
    "uploadFailed": "上傳失敗",
    "InvalidEmailFormat": "電子郵件格式無效",
    "FailedToSetUpEmailAccount": "設置電子郵件帳戶失敗",
    "noAccount": "沒有帳戶",
    "addAnAccountToPersonalizYourExperienceAndUnlock": "添加帳戶以個性化您的體驗並解鎖高級功能",
    "trackingFeaturesForThisSequence": "此工作流程的跟踪功能。",
    "UpdatedSuccessfully": "更新成功",
    "update_fail": "更新失敗",
    "connectYourGoogleMeetAccountToPubrioConversations": "將您的Google Meet帳戶連接到Pubrio會話",
    "set_default_fail": "設置默認失敗",
    "duplicatedSuccessfully": "成功複製",
    "noStepsInSequencePleaseAddStepsFirst": "工作流程中沒有步驟，請先添加步驟。",
    "addLabelSuccessfully": "成功添加標籤",
    "PleaseEnterSequenceName": "請輸入工作流程名稱",
    "sequenceNameCannotBeEmpty": "工作流程名稱不能為空",
    "sequenceNameUpdatedSuccessfully": "工作流程名稱更新成功",
    "createStepFlowSuccessfully": "成功創建步驟流程",
    "updateStepFlowSuccessfully": "成功更新步驟流程",
    "testEmailSentSuccessfully": "測試電子郵件發送成功",
    "removeEmailAccountSuccessfully": "成功移除電子郵件帳戶",
    "pleaseSelectTheDataToBeExported": "請選擇要導出的數據",
    "confirm": "確認",
    "saveFailed": "保存失敗",
    "deleteThisStep": "刪除此步驟",
    "Email":"電子郵件", 
    "LinkedIn":"LinkedIn", 
    "API":"API", 
    "Task":"任務", 
    "Custom":"自定義", 
    "proceedImmediately":"立即進行", 
    "dayss":"天", 
    "hourss":"小時", 
    "minutess":"分鐘", 
    "theConditionAndAllTheStepsThatFollowFromItWillBeDeleted":"該條件及其後所有步驟將被刪除，您確定要繼續嗎？", 
    "sendConnectionRequest":"發送連接請求", 
    "viewProfile":"查看個人資料", 
    "callAnAPI":"調用API", 
    "createAManualTask":"創建手動任務", 
    "hasEmailAddress":"有電子郵件地址", 
    "hasLinkedInURL":"有LinkedIn網址", 
    "hasPhoneNumber":"有電話號碼", 
    "customCondition":"自定義條件", 
    "openedEmail":"已打開的電子郵件", 
    "clickOnLinkInEmail":"點擊電子郵件中的鏈接", 
    "unsubscribeFromEmail":"退訂電子郵件", 
    "bookAMeeting":"預訂會議", 
    "acceptedInvite":"接受邀請", 
    "conditions":"條件", 
    "Yes":"是", 
    "No":"否", 
    "newVariable":"新變量", 
    "selectVariable":"選擇變量", 
    "ifLeadVariable":"如果潛在客戶變量 ", 
    "condition":"條件", 
    "selectCondition":"選擇條件", 
    "contains": "包含",
    "is_equal_to": "等於",
    "is_empty": "為空",
    "is_not_empty": "不為空",
    "picture": "圖片",
    "company_domain": "公司域名",
    "company_name": "公司名稱",
    "icebreaker": "破冰話題",
    "change_steps_conditions": "更改步驟/條件",
    "ForceASpecificSenderForThisStep": "強制此步驟使用特定發件人",
    "method": "方法",
    "selectASender": "選擇發件人",
    "assignAPITaskTo": "將API任務分配給",
    "enterCompanyNamesAndPressEnterKey": "輸入公司名稱並按Enter鍵",
    "feelFreeToEditTheContentAndSaveYourChangesDirectly": "隨時編輯內容並在點擊後直接保存更改。",
    "permission": "權限",
    "addAnyAdditionalStepsOrConditionsAsNeeded": "根據需要添加任何其他步驟或條件",
    "reviewAndFinalizeSequenceSettingsAndPreferences": "審核並最終確定工作流程設置和偏好",

    // setting
    "nameOnCard": "卡片上的姓名",
    "expiryDate": "到期日期",
    "cvccvv": "CVC/CVV",
    "details": "詳情",
    "notification": "通知",
    "markAsAllRead": "標記為全部已讀",
    "Personal": "個人",
    "profile": "個人資料",
    "extensions": "擴展功能",
    "notifications": "通知",
    "Company": "公司",
    "roles": "角色",
    "teams": "團隊",
    "current-usage": "當前使用情況",
    "upgrade-plan": "升級計劃",
    "Products": "產品",
    "APIKeys": "API金鑰",
    "popular": "熱門",
    "priceSequence": "工作流程（即將推出）",
    "free": "免費計劃",
    "lite": "輕量計劃",
    "growth": "初創計劃",
    "business": "商務計劃",
    "enterEmailAddressesMultipleCommaSeparated": "輸入電子郵件地址（多個，以逗號分隔）",
    "sharingRulesSelectTeamToEnableThisField": "共享規則（選擇團隊以啟用此字段）",
    "allSeatsAreCurrentlyOccupiedAnyNewUsersNeedingAPaidSeatWillIncurAdditionalCharges":"所有座位目前已被佔用。任何需要付費座位的新用戶將產生額外費用。",
    "dueToday":"今天到期", 
    "costPerBillingCycle":"每個計費週期的成本", 
    "select...":"選擇...", 
    "invite":"邀請", 
    "services":"企業服務", 
    "services-group":"服務組", 
    "all-notification-services":"所有通知服務", 
    "services-status-update":"服務狀態更新", 
    "system-group":"系統組", 
    "all-notification-system":"所有通知系統", 
    "system-new-feature-release":"系統新功能發布", 
    "system-news-letter":"系統新聞信", 
    "system-generic-alarm":"系統通用警報", 
    "markAsRead":"標記為已讀", 
    "showLess":"顯示較少", 
    "showMore":"顯示更多", 
    "changedStatusTo":"狀態已更改為", 
    "access-management-group":"訪問管理組", 
    "access-management-roles":"訪問管理角色", 
    "access-management-create-roles":"訪問管理創建角色", 
    "access-management-edit-user-roles":"訪問管理編輯用戶角色", 
    "access-management-add-role-to-users":"訪問管理將角色添加到用戶", 
    "access-management-delete-users-role":"訪問管理刪除用戶角色", 
    "access-management-delete-roles":"訪問管理刪除角色", 
    "access-management-read-roles-and-role-users":"訪問管理讀取角色及其用戶", 
    "access-management-teams":"訪問管理團隊", 
    "access-management-delete-users-team":"訪問管理刪除用戶團隊", 
    "access-management-read-teams-sharing-rules-teams-users":"訪問管理讀取團隊共享規則及其用戶", 
    "access-management-create-teams":"訪問管理創建團隊", 
    "access-management-edit-teams-sharing-rules":"訪問管理編輯團隊共享規則", 
    "access-management-delete-teams-sharing-rules":"訪問管理刪除團隊共享規則", 
    "access-management-add-and-update-team-to-users":"訪問管理將團隊添加並更新至用戶", 
    "access-management-read-team-relations":"訪問管理讀取團隊關係", 
    "access-management-modify-team-relations":"訪問管理修改團隊關係", 
    "intent-signal-group": "意圖信號組",
    "market-intent-signal": "市場意圖信號",
    "web-intent-signal": "網絡意圖信號",
    "web-intent-signal-add-and-activate": "網絡意圖信號添加和激活",
    "web-intent-signal-read-data": "網絡意圖信號讀取數據",
    "web-intent-signal-export-data": "網絡意圖信號導出數據",
    "web-intent-signal-delete-data": "網絡意圖信號刪除數據",
    "web-intent-signal-read-analytic": "網絡意圖信號讀取分析",
    "search-group": "搜索組",
    "company-search": "公司搜索",
    "prospects-search": "潛在客戶搜索",
    "sequence-group": "工作流程組",
    "sequences": "工作流程",
    "service-create-request": "服務創建請求",
    "service-update-request": "服務更新請求",
    "service-read-request": "服務讀取請求",
    "service-delete-service-attachment": "服務刪除服務附件",
    "service-delete-service-message": "服務刪除服務消息",
    "service-send-and-edit-service-message": "服務發送和編輯服務消息",
    "service-upload-service-attachment": "服務上傳服務附件",
    "settings-group": "設置組",
    "custom-fields": "自定義字段",
    "user-management-and-billing-group": "用戶管理和計費組",
    "billing-read-credit-card": "計費讀取信用卡",
    "billing-download-invoice": "計費下載發票",
    "billing-read-invoice": "計費讀取發票",
    "credit-usage": "信用點數使用",
    "credit-usage-read-profile-detail": "信用點數使用讀取個人資料詳情",
    "credit-usage-read-profile-usage": "信用點數使用讀取個人資料使用情況",
    "profile-and-team": "個人資料與團隊",
    "profile-and-team-invite-team-member": "個人資料與團隊邀請團隊成員",
    "profile-and-team-update-profile": "個人資料與團隊更新個人資料",
    "profile-and-team-delete-team-member": "個人資料與團隊刪除團隊成員",
    "profile-and-team-get-user-secret-token": "個人資料與團隊獲取用戶密鑰令牌",
    "profile-and-team-update-user-and-password": "個人資料與團隊更新用戶及密碼",
    "profile-and-team-read-profile-users": "個人資料與團隊讀取個人資料用戶",
    "upgrade-plan-read-subscription": "升級計劃讀取訂閱",
    "upgrade-plan-upgrade-subscription": "升級計劃升級訂閱",
    "all-access-access-management-roles": "所有訪問權限管理角色",
    "all-access-access-management-teams": "所有訪問權限管理團隊",
    "all-access-market-intent-signal-page": "所有訪問市場意圖信號頁面",
    "all-access-web-intent-signal-page": "所有訪問網絡意圖信號頁面",
    "all-access-company-search-page": "所有訪問公司搜索頁面",
    "all-access-prospects-search-page": "所有訪問潛在客戶搜索頁面",
    "all-access-sequence-page": "所有訪問工作流程頁面",
    "all-access-service-page": "所有訪問服務頁面",
    "all-access-custom-fields-page": '所有訪問自定義字段頁面',
    'all-access-billing-page': '所有訪問計費頁面',
    "all-access-credit-usage-page":"所有訪問信用點數使用頁面", 
    "all-access-profile-and-team-page":"所有訪問個人資料與團隊頁面",
    "newPermissionProfile": "新權限配置檔",
    "enterAName": "輸入名稱",
    "addUserToRole": "將用戶添加到角色",
    "changePermission": "更改權限",
    "selectPermissionProfiles": "選擇權限配置檔",
    "backToRoles": "返回角色",
    "updateRole": "更新角色",
    "enterADescription": "輸入描述",
    "createANewRole": "創建新角色",
    "AreYouSureYouWantToRemoveThisRole": "您確定要移除此角色嗎？",
    "AreYouSureYouWantToRemoveThisUserFromTheTeam": "您確定要將此用戶從團隊中移除嗎？",
    "ProceedingWithDeletionWillPermanentlyRemoveTheRoleFromTheUser": "進行刪除將永久移除該用戶的角色。",
    "ProceedingWithDeletionWillPermanentlyRemoveTheUserFromTheTeam": "進行刪除將永久移除該用戶從團隊中。",
    "relation": "關係",
    "newTeam": "新團隊",
    "full-sharing-market-intent-signal-data":"完整共享市場意圖信號數據", 
    "full-sharing-web-intent-signal-data":"完整共享網絡意圖信號數據", 
    "full-sharing-company-search-data":"完整共享公司搜索數據", 
    "full-sharing-prospects-search-data":"完整共享潛在客戶搜索數據", 
    "full-sharing-sequence-data":"完整共享工作流程數據", 
    "full-sharing-service-data":"完整共享服務數據", 
    "share-service-attachments":"共享服務附件", 
    "share-service-messages":"共享服務消息", 
    "RenameSharingRules":"重命名共享規則", 
    "enterNewSharingRulesNameBelow":"在下方輸入新共享規則名稱：", 
    "enterNewSharingRulesName":"輸入新共享規則名稱", 
    "enterNewSharingRulesDescriptionBelow":"在下方輸入新共享規則描述：", 
    "enterNewSharingRulesDescription":"輸入新共享規則描述", 
    "enterSharingRulesName":"輸入共享規則名稱", 
    "currentUsers":"當前用戶", 
    "pendingUsers":"待處理用戶", 
    "addRole":"添加角色", 
    "addTeam":"添加團隊", 
    "addPermission":"添加權限", 
    "Re-invite":"重新邀請", 
    "relations":"關係", 
    "is-parent-of":"是...的父項", 
    "addRelation":"添加關係", 
    "deleteRelation":"刪除關係", 
    "updateRelation":"更新關係", 
    "API_Keys":"API金鑰", 
    "createNewKey":"創建新金鑰", 
    "createAPIKey":"創建API金鑰", 
    "APIs":"API", 
    "setAsMasterKey":"設置為主金鑰", 
    "growth-annual": "增長-年度",  
    "business": "商業",  
    "website-intent-data": "網站意圖數據",  
    "demo-identification": "演示識別",

    // services
    "serviceMenuOverview": "概覽",
    "serviceMenuTeams": "團隊",
    "serviceMenuPipeline": "管道",
    "serviceMenuAnalytics": "分析",
    "serviceMenuResources": "資源",
    "salesServices": "企業服務",
    "chooseYourTypeofServicesToStreamlineAndEnhanceYourBusinessOperations": "選擇您的服務類型，以簡化和增強您的業務運營",
    "dataPreparation": "數據處理",
    "salesAssistant": "銷售助手",
    "appointmentSetting": "預約設置",
    "othersCustomService": "其他（自定義服務）",
    "requestService": "請求服務",
    "learnMore": "了解更多",
    "serviceRequestStatus": "服務請求狀態",
    "noServiceRequestsYet": "尚無服務請求",
    "submitYourFirstRequestToUnlockThePower": "提交您的第一次請求，以解鎖我們銷售服務的力量！在此跟蹤您的請求狀態，提升您的銷售工作。讓我們開始吧！",
    "serviceTableItemName": "名稱",
    "serviceTableItemStatus": "狀態",
    "serviceTableItemType": "類型",
    "serviceTableItemProgress": "進度",
    "serviceTableItemStart": "開始時間",
    "serviceTableItemEnd": "預計結束時間",
    "serviceTableItemPriority": "優先級",
    "addASubject": "添加主題",
    "created":"已創建", 
    "closed":"已關閉", 
    "paused":"已暫停", 
    "working":"工作中", 
    "completed":"已完成", 
    "resuming":"恢復中", 
    "reviewing":"審核中", 
    "others":"其他", 
    "data_preparation":"數據處理", 
    "sales_assistant":"銷售助手", 
    "appointment_setting":"預約設置", 
    "appointment_booking":"預約預訂", 
    "low":"低", 
    "normal":"正常", 
    "urgent":"緊急", 
    "high":"高", 
    "requiredCredits":"所需信用點數", 
    "serviceCreated":"已創建", 
    "QandARecord":"問答記錄", 
    "serviceAttachments":"附件", 
    "discussionBoard":"討論板", 
    "wannaAddSomething":"想添加一些東西嗎？", 
    "serviceEdit":"編輯", 
    "serviceDelete":"刪除", 
    "addDescription":"添加描述", 
    "DragDropor":"拖放或", 
    "chooseFile":"選擇文件", 
    "toUpload":"上傳",
    "ourDataPreparationService": "我們的數據處理服務提供全面的解決方案，旨在優化您的銷售數據管理。我們專注於簡化流程，使您能夠增強現有數據集，通過數據清理確保準確性，並創建完全符合您需求的自定義列表。這種方法使您獲得靈活性和效率，以有效管理您的數據。",
    "moreoverWithOurDedicatedDataManagement": "此外，通過我們專門的數據管理服務Pubrio，過程變得更加輕鬆。您可以無縫地附加文件並增強您的數據集，添加額外信息。Pubrio不僅提供增強功能，還可以根據您的具體標準創建全新的列表。這項個性化服務確保您的數據集精確符合您的目標和行業標準，讓您掌控自己的數據環境。",
    "flexibilityToAttach": "附加現有列表的靈活性：或指示Pubrio根據指定標準查找新列表。",
    "comprehensiveDataEnrichment": "全面的數據增強：添加缺失的詳細信息，增強數據集的完整性。",
    "dataCleansingServicesToEnsureAccuracy": "數據清理服務以確保準確性：包括刪除重複項和錯誤修正，以確保準確性和質量。",
    "abilityToInstructPubrio": "指示Pubrio的能力：根據精心定義的標準策劃全新的列表。",
    "tailoringDatasetsPrecisely": "精確調整數據集：使其與特定目標和行業標準對齊。",
    "dataEnrichmentWithExistingList": "使用現有列表進行數據增強：輕鬆通過將當前列表上傳到Pubrio來增強您的列表。我們將填補缺失的詳細信息，例如電子郵件地址和電話號碼，以滿足您的要求。例如，提供您的客戶數據庫，我們將用必要的信息更新它。",
    "customListGenerationIf": "自定義列表生成：如果您沒有列表，讓Pubrio為您查找一個。只需告訴我們您的標準，如職位或行業，我們將創建完美的列表。例如，如果您需要特定區域內頂級技術高管的名單，我們將為您處理。",
    "dataCleansingForExistingLists": "現有列表的數據清理：通過Pubrio的數據清理服務確保您的數據準確可靠。將您的列表發送給我們，我們將刪除重複項並修正任何錯誤。例如，如果您有一份最近潛在客戶的名單，我們將確保它為您的營銷活動做好準備。",
    "empoweringYourSalesEeavors": "通過我們的銷售助手服務，賦能您的銷售努力，我們提供全面支持，涵蓋多個關鍵領域，以確保您的外展和溝通策略精心調整以取得成功。無論您是想創作引人入勝的內容、無縫組織活動、開發量身定制的演示文稿，還是完善銷售和溝通策略，我們專門的團隊都在這裡幫助您提升銷售工作並提高品牌在市場上的存在感。",
    "craftingCompellingContent": "創作引人入勝的內容：有效的溝通是吸引受眾注意力的關鍵。我們的團隊擅長創作針對您的品牌和受眾偏好的引人入勝內容。從引人深思的LinkedIn帖子到信息豐富的博客文章和引人注目的社交媒體更新，我們確保您的信息與目標市場共鳴。",
    "seamlessEventAndWebinarPreparation": "無縫的活動和網絡研討會準備：活動和網絡研討會是與受眾互動和生成潛在客戶的強大工具。我們的銷售助手服務處理所有細節，從計劃和推廣到執行。無論是舉辦信息豐富的網絡研討會還是組織網絡活動，我們都確保您的活動留下深刻印象。",
    "tailoredContentDevelopmentAndPresentation": "量身定制內容開發和演示：用引人入勝的演示和個性化的信息吸引受眾。我們的團隊專門創建視覺吸引人的演示文稿並製作針對特定受眾細分市場的信息。無論您是在向投資者推介還是培養潛在客戶，我們都確保您的內容脫穎而出。",
    "strategicSalesAndCommunicationFrameworks": "戰略銷售和溝通框架：通過戰略指導和有效的溝通腳本簡化銷售流程。我們的銷售助手服務為您的團隊提供推動轉換和促進有意義聯繫所需的工具和策略。",
    "craftingCompellingContent": "創作引人入勝的內容",
    "companyLinkedInPosts": "公司 LinkedIn 貼文（頻率：每週或每月）",
    "personalLinkedInPosts": "個人 LinkedIn 貼文（頻率：靈活，根據偏好和內容可用性）",
    "websiteBlogPosts": "網站部落格文章（頻率：通常每週或每兩週）",
    "socialMediaPosts": "社交媒體貼文（頻率：每日或根據內容策略）",
    "emailMarketingCampaigns": "電子郵件行銷活動（頻率：每週或每兩週）",
    "seamlessEventAndWebinarPreparation": "無縫的活動和網路研討會準備",
    "webinarAndWorkshopPreparation": "網路研討會和工作坊準備",
    "networkingAndOfflineEvent": "網絡和線下活動協調",
    "tailoredContentDevelopment": "量身定制的內容開發和呈現",
    "companyDeckPreparation": "公司簡報準備（用於演示、推介等）",
    "highlyPersonalizedMessage": "高度個性化的訊息創建（針對特定受眾群體量身定制）",
    "strategicSales": "戰略銷售和溝通框架",
    "salesCallScripts": "銷售電話腳本",
    "emailOutreachTemplate": "電子郵件外展模板",
    "salesChannelStrategies": "銷售渠道策略",
    "ourAppointmentSettingService": "我們的約會安排服務旨在提升您的業務，促進關鍵聯繫，增強行業曝光，並培養有價值的潛在客戶。我們專注於個性化的行業曝光、無縫的研討會和網路研討會協調、熱絡新潛在客戶，以及對新受眾的吸引性接觸。",
    "individualIndustryAppointment": "個別行業約會曝光和反應",
    "tailoredOutreach": "量身定制的外展策略針對行業內的關鍵決策者和影響者，確保您的品牌獲得精準曝光。",
    "strategicFollowups": "戰略性跟進幫助我們評估潛在客戶的反應、解決他們的疑慮，並促進更深入的互動。",
    "eachInteraction": "每次互動都根據收件人的特徵和偏好進行個性化設計，以最大化影響力。",
    "seminarWebinarCoordination": "研討會與網絡研討會協調",
    "ourExpertTeamHandles": "我們的專業團隊負責所有研討會和網絡研討會的協調工作，從最初的規劃到活動結束後的跟進。",
    "weMeticulouslyPlan": "我們精心策劃和執行每一場活動，展示您的專業知識，確保參與者獲得流暢而引人入勝的體驗。",
    "byLeveragingAdvancedTechnologyPlatforms": "通過利用先進的技術平台，我們增強互動性和參與度，最大化活動的影響力。",
    "newProspectWarmUp": "新潛在客戶預熱",
    "proactiveEngagementStrategies": "採用主動參與策略來熱身新潛在客戶，為成功的商業對話鋪平道路。",
    "tailoredCommunicationStrategies": "部署量身定制的溝通策略，以建立良好的關係、信任感和對您產品的興趣。",
    "throughStrategicNurturing": "通過戰略性培養，我們保持潛在客戶的參與度，為轉換做好準備，提高成功約會的機會。",
    "newAudienceApproach": "新受眾接觸策略",
    "weEmployInnovativeStrategies": "我們採用創新策略來接觸新受眾，擴大您的業務範圍，超越傳統渠道。",
    "targetedOutreachCampaigns": "根據深入的受眾研究和細分制定針對性的外展活動，以確保相關性和效果。",
    "ourApproachIsContinuouslyOptimized": "我們的方法根據反饋和見解不斷優化，確保我們在接觸和吸引新受眾方面始終走在前列。",
    "individualIndustry": "個別行業會議預約及跟進",
    "personalizedAppointmentSetting": "與行業利益相關者進行個性化約會安排，以促進商機。",
    "strategicFollowupCommunication": "進行戰略性跟進溝通以促進進一步參與，增強轉換率。",
    "comprehensivePlanning": "全面規劃和執行活動，以確保每個細節都能達成預期效果。",
    "utilizationOfAdvancedTechnologies": "利用先進技術增強互動性。",
    "newProspectWarmUp": "新潛在客戶預熱",
    "proactiveEngagementStrategy": "針對潛在客戶的主動參與策略。",
    "customizedCommunication": "量身定制的溝通以保持興趣。",
    "newAudienceApproach": "新受眾接觸策略",
    "innovativeOutreach": "為新受眾設計的創新外展活動。",
    "continuousRefinement": "根據反饋和見解不斷完善。",
    "customService": "定制服務",
    "tailoredToMeet": "我們的定制服務旨在滿足您的獨特需求，提供靈活性和適應性，以應對各種要求。無論您需要協助進行網絡研討會參與者追蹤、線下活動推廣、廣告增強或其他專業任務，我們的專業團隊都能高效且有效地幫助您實現目標。",
    "webinarParticipantsTracking": "網絡研討會參與者追蹤",
    "comprehensiveTrackingOfWebinar": "全面追蹤網絡研討會參與者，以分析參與程度並收集有價值的見解。",
    "customizedReportsAndAnalytics": "量身定制的報告和分析，以評估網絡研討會的有效性並優化未來策略。",
    "seamlessIntegration": "與現有系統無縫集成，以簡化數據管理和報告。",
    "offlineEventPromotion": "線下活動推廣",
    "strategicPlanning": "戰略規劃和執行線下活動推廣活動，以最大化出席率和參與度。",
    "utilizationOfTargetedMarketingChannels": "利用針對性的營銷渠道和推廣策略，有效接觸目標受眾。",
    "creativeApproaches": "根據每個活動的獨特特徵量身定制創意方法，確保最大影響力和投資回報率。",
    "adsEnhancement": "廣告增強",
    "optimizationOfAdCampaigns": "優化各平台上的廣告活動，以提高可見性、參與度和轉換率。",
    "ABTesting": "A/B測試和性能分析，以識別最有效的廣告創意、信息傳遞和目標策略。",
    "continuousMonitoring": "持續監控和調整廣告活動，以適應市場動態變化並最大化結果。",
    "customizedSolutions": "定制解決方案",
    "ourCustomService": "我們的定制服務超越預定義功能，旨在滿足您的具體需求和偏好。",
    "whetherYouNeedAssistance": "無論您需要數據分析、內容創建、戰略規劃或業務其他方面的協助，我們都能根據您的需求量身定制服務。",
    "ourFlexibleApproach": "我們靈活的方法使我們能夠與您密切合作，開發定制解決方案，實現可見成果並推動業務增長。",
    "setupAndImplementation": "網絡研討會參與者追蹤系統的設置和實施。",
    "customizedReporting": "量身定制的報告和分析，以衡量網絡研討會的表現和觀眾參與度。",
    "developmentAndExecution": "針對性的線下活動推廣活動的開發和執行。",
    "coordinationOfPromotionalActivities": "推廣活動的協調，包括印刷材料、直郵和地方廣告。",
    "evaluationAndOptimization": "對數字平台上的廣告活動進行評估和優化。",
    "creativeDevelopment": "創意開發、目標細化和性能監控，以最大化投資回報率。",
    "tailoredServices": "量身定制的服務，以滿足特定需求和目標。",
    "collaborationWithOurTeam": "與我們的團隊合作，開發滿足您獨特需求的定制解決方案。",
    "serviceGetStarted": "開始使用",
    "keyFeatures": "主要特點",
    "dataEnrichment": "數據增強",
    "dataCleansing": "數據清理",
    "customListGeneration": "自定義列表生成",
    "serviceOfferings": "服務項目",
    "introDataPreparation": "我們的數據處理服務提供全面的解決方案，旨在優化您的銷售數據管理。我們專注於簡化流程，使您能夠增強現有數據 ...",
    "introSalesAssitant": "銷售助手服務超越了一般的行政支持，專注於銷售過程中不可或缺的任務。無論您是想創作引人入勝的內容 ...",
    "introAppointmentSetting": "這項服務採取個性化和實地的方法，根據提供的信息將客戶與其目標受眾聯繫，增強行業曝光，並培養 ...",
    "introOtherService": "'其他'服務允許客戶通過提交有關其獨特需求的詳細信息來請求定制服務，提供靈活性和適應性，以應對各種要求 ...",
    "questionCommonPagePriorityLevelTitle": "您對此任務的優先級別是什麼？",
    "questionCommonPagePriorityLevelDesc": "說明您的任務優先級有助於我們及時滿足您的需求。您可以在提交後更改它。",
    "questionCommonPageTimelineTitle": "考慮訂閱期限或時間表嗎？",
    "questionCommonPageTimelineDesc": "告訴我們您首選的時間框架以及計劃開始的時間。",
    "questionCommonPageServiceRequestTitle": "準備開始了嗎？首先，給您的申請一個獨特的名稱",
    "questionCommonPageServiceRequestDesc": "我們將用它來跟蹤並在您的儀表板上更新進度。",
    "questionCommonPageServiceDurationTitle": "您的服務期將持續多久？",
    "quesitonCommonPageInvestmentBudget": "您每月在業務發展和項目上的投資預算是否超過HKD 12000？",
    "questionCommonPageHearAboutUs": "您從哪裡聽說我們的？",
    "questionCommonPageUploadTitle": "請在此處上傳您的Excel/CSV文件以進行數據增強或數據清理",
    "questionCommonPageUploadDesc": "如果您選擇創建自定義列表生成，可以跳過或點擊'下一步'而不上傳。",
    "questionDataPreparationPageInterestedDataOptimizationTitle": "您對哪些服務感興趣以進行數據優化？",
    "questionDataPreparationPageInterestedDataOptimizationDesc": "您需要在下一步中附加一個CSV文件，以選擇數據增強或數據清理。",
    "questionDataPreparationPageDataAttributesTitle": "選擇您希望納入文件的特定數據屬性（如適用）",
    "questionDataPreparationPageDataAttributesDesc": "選擇一個或多個",
    "questionDataPreparationPageNumberOfLeadsTitle": "您希望為您的業務生成或獲取多少潛在客戶？",
    "questionDataPreparationPageNumberOfLeadsDesc": "指定數量或範圍，以便我們為您定制服務。",
    "website_url": "網站網址",
    "contact_first_name": "聯絡人名字",
    "contact_last_name": "聯絡人姓氏",
    "contact_title": "聯絡人職稱",
    "contact_location": "聯絡人地點",
    "contact_work_email": "聯絡人工作電子郵件",
    "contact_personal_email": "聯絡人個人電子郵件",
    "contact_work_phone": "聯絡人工作電話",
    "contact_personal_phone": "聯絡人個人電話",
    "company_size": "公司規模",
    "company_industry": "公司行業",
    "company_location": "公司地點",
    "company_founded_year": "公司成立年份",
    "company_keywords": "公司關鍵詞",
    "company_ranking_in_global": "公司在全球的排名",
    "company_financial_status": "公司財務狀況",
    "company_office_address": "公司辦公地址",
    "website_total_visit": "網站總訪問量",
    "company_funding_status": "公司融資狀況",
    "slugServiceName": "服務名稱",
    "slugServiceDesc": "服務描述",
    "errEnterServiceName": "請輸入服務名稱",
    "errEnterDescription": "請輸入服務描述",
    "errSelectAService": "請選擇一項服務",
    "errSelectSpecificDataAttributes": "請選擇特定數據屬性",
    "errInputTheQuantity": "請輸入數量",
    "errSelectPriorityLevel": "請選擇您的優先級別",
    "errSelectTimeline": "請選擇時間表",
    "errInputContent": "請輸入內容",
    "errInvestmentBudget": "請選擇是或否",
    "errServiceDuration": "請選擇服務期限",
    "errHearAboutUs": "請選擇來源",
    "placeHolderEnterServiceName": "輸入服務名稱",
    "placeHolderEnterServiceDescription": "輸入服務描述",
    "placeHolderEnterListGeneration": "輸入列表生成",
    "placeHolder150TO250": "150 - 250",
    "Submit": "提交",
    "serviceDownload": "下載",
    "serviceExample": "範例",
    "forYourReference": "供您參考",
    "yourFileNeedsToInclude": "您的文件需要至少包括網站或公司名稱",
    "includeAll": "包括所有",
    "serviceNext": "下一步",
    "serviceBack": "返回",
    "data_enrichment": "數據增強",
    "data_cleansing": "數據清理",
    "custom_list_generation": "自定義列表生成",
    "job_title": "職位名稱",
    "timeline_within_one_weeks": "時間範圍在一周內",
    "timeline_one_to_two_weeks": "時間範圍一到兩周內",
    "timeline_two_to_four_weeks": "時間範圍兩到四周內",
    "timeline_recurring": "定期時間範圍",
    "timeline_custom_range": "自定義時間範圍",
    "hear_about_us_social_media": "社交媒體",
    "hear_about_us_google": "Google",
    "hear_about_us_online_article": "在線文章",
    "hear_about_us_webinar": '網絡研討會',
    "hear_about_us_referral": '推薦',
    "hear_about_us_custom_input": '其他',
    "duration_last_than_one_month": '少於一個月',
    "duration_one_to_three_months": '一到三個月',
    "duration_over_three_months": '超過三個月',
    "duration_decide_later": '稍後決定',
    "yes": '是',
    "no": '否',
    "byCancelingYouCanEnsureThatNoTasksWillBeClosed": '通過取消，您可以確保沒有任務將被關閉',
    "areYouSureYouWantToCloseThisTask": '您確定要關閉此任務嗎？',
    "onceTheTaskIsClosed": '一旦任務關閉，將無法撤銷，您將無法恢復它。',
    "Close": '關閉',
    "ourTeamIsWorkingOnThisTask": '我們的團隊正在處理此任務',
    "pleaseProviceYourReasonForThisAction": '請提供您此行動的理由',
    "sendRequest": '發送請求',
    "OK": '確定',
    "areYouSureYouWantToDelete": "您確定要刪除嗎？",
    "proceedingWithDeletionWillResultInPermanentRemovalOfTheFile": "進行刪除將導致文件永久刪除。",
    "byCancelYouEnsureThatNoFileWillBeDeleted": "通過取消，您可以確保不會刪除任何文件。",
    "areYouSureYouWantToDeleteThis": "您確定要刪除這個嗎？",
    "serviceMessage": "消息",
    "serviceFile": "文件",
    "proceedingWithDeletionWillResultInPermanentRemovalOfThisMessage": "進行刪除將導致此消息永久刪除。",
    "proceedingWithDeletionWillResultInPermanentRemovalOfThisFile": "進行刪除將導致此文件永久刪除。",
    "youEnsureThatNoMessagesWillBeDeleted": "通過取消，您可以確保不會刪除任何消息。",
    "youEnsureThatNoFilessWillBeDeleted": "通過取消，您可以確保不會刪除任何文件。",
    "serviceDeleteFail": "刪除失敗",
    "enterYourReason": "輸入您的理由",
    "seriveStartDate": "開始日期",
    "serviceEndDate": "預計結束日期",
    "yourRequestHasBeenSent": "您的請求已發送",
    "sendUsYourFeedback": "發送您的反饋給我們",
    "weAreConstantlyImproving": "我們不斷改進！分享您的反饋以塑造我們平台的未來。",
    "describeYourExperienceHere": "在此描述您的體驗...",
    "additionallyYouCanAttachAnyScreenshots": "此外，您可以附加任何截圖或文件以提供更多細節。",
    "sendFeedback": "發送反饋",
    "serviceResume": "恢復",
    "yourRequestHasBeenResumed": "您的請求已恢復",
    "yourRequestIsCurrentlyPaused": "您的請求目前已暫停",
    "serviceEdited": "已編輯",
    "theFileSizeCannotExceed50M": "文件大小不能超過50M",
    "activateTheNewVersionNow": "立即啟用新版本",
    "feedbackSendSuccessfully": "反饋已成功發送",
    "feedbackSendFailed": "反饋發送失敗",
    "autoSave": "自動保存",
    "serviceDescShowMore": "顯示更多",
    "serviceDescShowLess": "顯示較少",
    "members": "成員",
    "shareTo": "分享至...",
    "findTeamMembers": "尋找團隊成員",
    "select": "選擇",
    "whenBalanceFallsBelow": "當餘額低於",
    "defaultPaymentMethod": "默認付款方式",
    "creditCardDetails": "信用卡詳細信息",
    "automaticallyTopUp": "自動充值",
    "autoTopupWillAutomaticallyChargeYourPaymentMethodT": "自動充值將自動向您的付款方式收費。要禁用此功能，請保持此框未勾選。",
    "enableAutoTopups": "啟用自動充值",
    "validThru": "有效期至",
    "currentBalance": "當前餘額",
    "cardHolder": "持卡人姓名",
    "gotIt": "明白了",
    "pleaseEnsureYourPaymentMethodIsUptodateAndThatYouA": "請確保您的付款方式是最新的，並且在啟用之前您對此自動過程感到滿意。",
    "thresholdCredits": "閾值：{creditLimit} 信用點數",
    "creditAmountCredits": "充值金額：{choosedTopUpCredit} 信用點數",
    "enablingAutoTopupsWillAutomaticallyAddCreditsToYou": "啟用自動充值將在您的餘額低於指定閾值時自動向您的帳戶添加信用點數。這可能會導致對您選擇的付款方式進行自動收費。",
    "thisWillAutomaticallyAddCreditsToYourAccountWhenev": "{choosedTopUpCredit} 信用點數將在您的餘額低於 {creditLimit} 信用點數時自動添加到您的帳戶。",
    "autoTopupNotice": "自動充值通知",
    "roleName": "角色名稱",
    "addUser": "添加用戶",
    "newRole": "新角色",
    "userName": "用戶名稱",
    "joined": "已加入",
    "thisRoleCurrentlyHasNoUsersAssignedToItStartAddingTeamMembersToDefineTheirResponsibilitiesAndStreamlineYourWorkflow": "此角色目前沒有分配任何用戶。開始添加團隊成員以定義他們的責任並簡化工作流程。",
    "noUsersAssigned": "未分配用戶",

    "addUserToTeam": "添加用戶到團隊",
    "user": "用戶",
    "sharingRuleGroup": "共享規則組",
    "updateTeam": "更新團隊",
    "createANewTeam": "創建新團隊",
    "backToTeams": "返回團隊",
    "listOfSharingRules": "共享規則列表",
    "sharingRules": "共享規則",
    "manageSharingRules": "管理共享規則",
    "CreateAndCustomizeSharingRulesToControlAccessForDifferentUsersClickTheButtonBelowToAddNewSharingRules": "創建和自定義共享規則以控制不同用戶的訪問權限。單擊下面的按鈕以添加新的共享規則。",
    "newSharingRules": "新共享規則",

    // price
    "exportLimit": "導出上限",
    "customRolePermission": "自定義角色權限",
    "teamManagement": "團隊管理",
    "API": "API",
    "linkedinAccount": "LinkedIn 帳戶",
    "perPageLimit": "每頁上限",
    "recordUploadLimit": "記錄上傳上限",
    "creditRollover": "信用點數結轉",
    "quotaSharing": "配額共享",
    "quotaAdjustment": "配額調整",
    "add_On": "附加功能",
    "topUpCredits": "充值信用點數",
    "webVisitorIdentification": "網絡訪客識別",
    "dataExportCredit": "數據導出",
    "companySearchCredit": "公司搜索",
    "peopleSearchCredit": "潛在客戶搜索",
    "emailRedeemCredit": "電子郵件搜索",
    "phoneNumberRedeemCredit": "電話號碼搜索",
    "recordSelection": "記錄選擇",
    "portal": "平台",
    "queryLimitPerHour": "每小時查詢上限",
    "queryLimitPerDay": "每日查詢上限",
    "callLimitPerHour": "每小時請求上限",
    "callLimitPerDay": "每日請求上限",
    "callLimitPerMonth": "每月請求上限",
    "basicSequencing": "基本工作流程設置",
    "numberOfSequences": "工作流程數量",
    "numberOfMailboxesPerUser": "每個用戶的郵箱數量",
    "numberOfLinkedinAccountPerUser": "每個用戶的LinkedIn帳戶數量",
    "numberOfSlackAccountPerUser": "每個用戶的Slack帳戶數量",
    "automatedFollowUp": "自動跟進",
    "advancedSequencing": "進階工作流程設置",
    "abTesting": "A/B測試",
    "multipleEmailTemplates": "多個電子郵件模板",
    "reporting": "報告",
    "emailOpenTracking": "電子郵件打開跟踪",
    "emailClickTracking": "電子郵件點擊跟踪",
    "emailReplyTracking": "電子郵件回覆跟踪",
    "support": "支持",
    "priorityEmailSupport": "優先電子郵件支持",
    "companySelectionLimit":"公司選擇上限", 
    "prospectSelectionLimit":"潛在客戶選擇上限", 
    "totalPageLimit":"總頁面上限", 
    "pricingYear":"年", 
    "growth-annual":"初創計劃", 
    "business-annual":"商務計劃", 
    "pricingSearch":"搜索", 
    "pricingSearches":"搜索次數", 
    "pricingMonth":"月", 
    "topupCredit":"充值信用點數", 
    "perUserPerYear":"每位用戶，每年\n按年計費", 
    "perUserPerMonth":"每位用戶，每月\n按月計費", 
    "customCredits":"自定義信用點數", 
    "customSearches":"自定義搜索", 
    "queryLimitPerMonth":"每月查詢上限", 
    "customized":"自定義", 
    "custom":"自定義 ", 
    "free":"免費計劃", 
    "growth":"初創計劃", 
    "business":"商務計劃", 
    "organization":"定制方案", 
    "search":"數據搜索",

    // setting
    "pleaseSelectTheUserYouWantToAdd":"請選擇您想添加的用戶", 
    "pleaseSelectTheUserYouWantToDelete":"請選擇您想刪除的用戶", 
    "pleaseSelectTheUserYouWantToAddPermission":"請選擇您想添加權限的用戶"

}
