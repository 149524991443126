import * as ComObj from "@/api/company";
 const _ = require('lodash');



const state = {
    searchCompanyList:[],//搜索公司列表
    total:0,
    savedList:[],//已保存的公司
    companyGroup:[],//公司saved分组
    companyLists:[],
    companySize:[],
    mediaList:localStorage.getItem('mediaList')?JSON.parse(localStorage.getItem('mediaList')):[],
    locationList:localStorage.getItem('locationList')?JSON.parse(localStorage.getItem('locationList')):[],
    techList:[],
    techCategoryList:JSON.parse(localStorage.getItem('techCategoryList') || '[]'),
    industryList:localStorage.getItem('IndustryList')?JSON.parse(localStorage.getItem('IndustryList')):[],
   /* specialtyList:localStorage.getItem('SpecialtyList')?JSON.parse(localStorage.getItem('SpecialtyList')):[],*/
    isLoading:false,
    isShow:true, //控制filter是否隐藏
    clearAll:false,//控制是否清空
    savedSearch_C:localStorage.getItem('searchSavedList_C')?JSON.parse(localStorage.getItem('searchSavedList_C')):[], //save search 中的SaveList
    savedSearch_P:[], //save search 中的SaveList
    isStopSearch:false,//控制是否停止搜索
    addCreditsVisible:false,//控制是否显示添加credits弹窗
    selected: {
        locations:[],
        verticals:[],
        categories:[],
        technologies:[],
        company_groups: [],
        companies:[],
        social_media:[],
        company_name:'',
        employees:[],
        founded_dates:[],
        revenues:[],
        page: 1,
        per_page: parseInt(process.env.VUE_APP_PER_PAGE),
        profile_id: '',
    },
}

const mutations = {
    setCollapse(state){
        state.isShow = !state.isShow
    },
    setClear(state){
        state.clearAll = true
    },
    setLoading(state,val){
        state.isLoading = val
    },
    setAddCreditsVisible(state,val){
        state.addCreditsVisible = val
    },
    /*------------综合搜索----------------*/
    setSelected(state,val){
        let id= this.state.user.currentId.profile_id
        let temp = {...state.selected, page: 1,...val,profile_id:id}
        if (_.isEqual(state.selected,temp)){
            state.isStopSearch = true
        }else{
            state.selected = temp
        }
    },
    setIsStopSearch(state,val){
        state.isStopSearch = val
    },
    searchCompanyList(state,List){
        state.searchCompanyList = List.companies
        state.total = List.pagination
        state.savedList = List.companies && List.companies.filter(item=>{
            if (item.saved_lists !== null){
                return item
        }})
    },
    setLocationList(state,locationList){
        localStorage.setItem('locationList',JSON.stringify(locationList))
    },
    setIndustryList(state,industryList){
        localStorage.setItem('IndustryList',JSON.stringify(industryList))
    },
    setSpecialityList(state,specialityList){
        localStorage.setItem('SpecialityList',JSON.stringify(specialityList))
    },
    setTechList(state,techList){
        state.techList = techList
    },
    setTechCategoryList(state,techCategoryList){
        techCategoryList?.length && localStorage.setItem('techCategoryList',JSON.stringify(techCategoryList))
    },
    setCompanyGroup(state,companyList){
       state.companyGroup = companyList
    },
    setCompanyList(state,companyList){
        state.companyLists = companyList
    },
    setMediaList(state,mediaList){
        localStorage.setItem('mediaList',JSON.stringify(mediaList))
    },
    setSearchSavedList_C(state,savedList){
        state.savedSearch_C = savedList
        localStorage.setItem('searchSavedList_C',JSON.stringify(savedList))
    },
    setSearchSavedList_P(state,savedList){
        state.savedSearch_P = savedList
        localStorage.setItem('searchSavedList_P',JSON.stringify(savedList))
    },
    setCompanySize(state,companySize){
        state.companySize = companySize
        localStorage.setItem('companySize',JSON.stringify(companySize))
    },
}

const actions = {
  async searchCompanyList(context, val) {
    try {
      // await context.commit('setSelected', val);
      if (this.state.company.isStopSearch) {
        context.commit('setIsStopSearch', false);
        return;
      }
      const result = await ComObj.searchCompanyList(val);
      context.commit('searchCompanyList', result?.data);
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  // async searchCompanyList(context, val) {
  //   try {
  //     // await context.commit('setSelected', val);
  //     let num = [];
  //     for (let i in this.state.company.selected) {
  //       if (i === 'page' || i === 'per_page' || i === 'profile_id') {
  //         continue;
  //       }
  //       if (this.state.company.selected[i]?.length) {
  //         num.push(i);
  //       }
  //     }
  //     if (this.state.company.isStopSearch) {
  //       context.commit('setIsStopSearch', false);
  //       return;
  //     }
  //     if (num.length === 0) {
  //       context.commit('searchCompanyList', { companies: [], pagination: {} });
  //       return;
  //     }
  //     const result = await ComObj.searchCompanyList(this.state.company.selected);
  //     context.commit('searchCompanyList', result?.data);
  //     return result?.data;
  //   } catch (err) {
  //     console.log(err);
  //     throw err;
  //   }
  // },
  /*---------------推荐公司列表----------------------*/
  async getCompanyList(context, val) {
    try {
      const CompanyList = await ComObj.getCompanyList({ "company_name": val });
      context.commit('setCompanyList', CompanyList?.data);
      return CompanyList?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getIndustry(context) {
    try {
      const IndustryList = await ComObj.getIndustry();
      context.commit('setIndustryList', IndustryList?.data);
      return IndustryList?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getSpeciality(context) {
    try {
      const SpecialityList = await ComObj.getSpeciality();
      context.commit('setSpecialityList', SpecialityList?.data);
      return SpecialityList?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  /*------------------------------------companyGroup-------------------------------------------------*/
  async getCompanyGroup(context) {
    try {
      const result = await ComObj.getCompanyGroup({
        profile_id: this.state.user.currentId.profile_id,
      });
      context.commit('setCompanyGroup', result?.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  /*----------------------------handle list-----------------------------------*/
  async addCompanyToGroup(context, val) {
    try {
      await ComObj.addCompany(val);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async deleteCompany(context, val) {
    try {
      const result = await ComObj.deleteCompany({ ...val, profile_id: this.state.user.currentId.profile_id });
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  /*-------------------------------------------------------------------------------------------------*/
  async getMediaList(context) {
    try {
      const result = await ComObj.getMediaList();
      context.commit('setMediaList', result?.data);
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getLocationList(context) {
    try {
      const result = await ComObj.getLocationList();
      context.commit('setLocationList', result?.data);
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getTechList(context, data) {
    try {
      const result = await ComObj.getTechList(data);
      context.commit('setTechList', result?.data);
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getCategoryList(context, data) {
    try {
      const result = await ComObj.getTechCategoryList(data);
      context.commit('setTechCategoryList', result?.data);
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  /*--------------获取公司详情--------------------*/
  async getCompanyDetail(context, data) {
    try {
      const result = await ComObj.getCompanyDetail(data);
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  /*-----根据groups返回domain ID------*/
  async getDomainIdByGroup(context, data) {
    try {
      const result = await ComObj.getDomainIdByGroup({ ...data, profile_id: this.state.user.currentId.profile_id });
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  /*---------------export------------------*/
  async export(context, data) {
    try {
      const result = await ComObj.exportCompany({ ...data, profile_id: this.state.user.currentId.profile_id });
      return result;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  /*---------------saved search------------------*/
  async getSaveList(context, data) {
    try {
      const result = await ComObj.getSaveList({
        profile_id: this.state.user.currentId.profile_id,
        search_type_id: data?.search_type_id,
        saved_search_name: data?.saved_search_name
      });
      if (data?.search_type_id === 1) {
        context.commit('setSearchSavedList_C', result?.data);
      } else {
        context.commit('setSearchSavedList_P', result?.data);
      }
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async addSaveList(context, data) {
    try {
      let type = data?.search_type_id === 1 ? this.state.company.selected : this.state.people.selected;
      let params = data?.params ? data?.params : type;
      await ComObj.addSaveList({
        profile_id: this.state.user.currentId.profile_id,
        saved_search_name: data?.saved_search_name,
        is_personal: data?.is_personal,
        search_type_id: data?.search_type_id,
        is_pinned: data?.is_pinned,
        parameters: params
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async deleteSaveList(context, data) {
    try {
      await ComObj.deleteSaveList(data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async updateSaveList(context, data) {
    try {
      let payload = {};
      if (data?.params) {
        payload = {
          profile_id: this.state.user.currentId.profile_id,
          saved_searches: data?.saved_searches,
          saved_search_name: data?.saved_search_name,
          is_personal: data?.is_personal,
          is_pinned: data?.is_pinned,
          parameters: data?.params,
        };
      } else {
        payload = {
          profile_id: this.state.user.currentId.profile_id,
          saved_searches: data?.saved_searches,
          saved_search_name: data?.saved_search_name,
          is_personal: data?.is_personal,
          is_pinned: data?.is_pinned,
          parameters: data?.search_type_id === 1 ? this.state.company.selected : this.state.people.selected
        };
      }
      let result = await ComObj.updateSaveList(payload);
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getCompanySize(context) {
    try {
      const result = await ComObj.getCompanySize();
      context.commit('setCompanySize', result?.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
};


export default {
    namespaced: true,
    state,
    mutations,
    actions
}
