export default {
        //login
        "loading": "加载中...",
        "SignUp": "注册",
        "LoginSuccess": "成功登录",
        "LoginOut": "成功登出",
        "email": "电子邮件",
        "password": "密码",
        "logIn": "登录",
        "WelcomeBack": "欢迎回来",
        "PleaseEnterYourEmailAndPassword": "请输入您的电子邮件和密码",
        "CharactersRequired": "需要8个以上字符",
        "keepMeSignedIn": "保持我登录状态",
        "forgetPassword": "忘记密码",
        "dontHaveAnAccount": "还没有账户？",
        "signUpNow": "立即注册",
        "AllRightsReserved": "版权所有",
        "privacyPolicy": "隐私政策",
        "and": "和",
        "termsOfService": "服务条款",
        "Name": "姓名",
        "viewAll": "查看全部",
        "fold": "折叠",
        "updateCreditCard": "更新信用卡",
        "role": "角色",
        "noLimit": "无限制",

        //signup
        "company": "公司",
        "companyName": "公司名称",
        "signUp": "注册",
        "alreadyHaveAnAccount": "已经有账户？",
        "getStarted": "开始使用！用我们的平台赋能您的业务",
        "signUpSuccess": "成功注册",

        //forget
        "noWorries": "不用担心，输入您的注册电子邮件，我们将发送重置说明",

        //reset
        "reset": "重置密码",
        "forgotPassword": "忘记密码",
        "RequestPasswordReset": "请求重置密码",
        "backToLogin": "返回登录",
        "verifyPassword": "验证密码",
        "EnterANewPasswordFor": "为 example@gmail.com 输入新密码",
        "ResetPassword": "重置密码",
        "newPassword": "新密码",

        //top-nav
        "Home": '首页',
        "Search": '搜索',
        "Lead": '潜在客户',
        "Prospects": '潜在客户',
        "filterYourSearchToFindHighlySpecific": '过滤您的搜索以找到高度特定的',
        "groupsOfTargetedLeads": '目标潜在客户组',
        "filterYourSearchToFindPreciselyTargeted": '过滤您的搜索以找到精确定位的',
        "listsOfCompanies": '公司列表',
        "transformAnonymousWebsiteTrafficInto": '将匿名网站流量转换为',
        "b2bLeadsWithRealtimeIdentification": '实时识别的B2B潜在客户',
        "discoverAndConnectWithPotentialBuyers": '发现并与潜在客户联系',
        "activelySeekingYourSolutionToday": '今天积极寻求您的解决方案',

        "Intent_Signals":"意图信号", 
        "Website_Intent":"网站意图", 
        "Market_Intent":"市场意图", 
        "Sequence":"工作流程", 
        "Tools":"工具", 
        "Services":"企业服务", 
        "technologyLookup":"技术查询", 
        "domainLookup":"域名查询", 
        "emailVerification":"电子邮件验证", 
        "upgrade":"升级", 
        "helpCenter":"帮助中心", 
        "contactUs":"联系我们", 
        "feedback":"反馈", 
        "hello":"你好", 
        "clickToChange":"点击更改", 
        "yourProfile":"您的个人资料", 
        "currentUsage":"当前使用情况", 
        "upgradePlan":"升级计划", 
        "integrations":"集成", 
        "featureRequests":"功能请求", 
        "change":"更改", 
        "en":"英文", 
        "cn":"中文（简）", 
        "tw":"中文（繁）", 
        "logout":"登出", 
        "switchLanguage":"成功切换语言",

        //not available
        "ComingSoon": "敬请期待！",
        "TheFeatureIscurrentlyUnder": "该功能目前正在",
        "development": "开发中",
        "weAppreciateYourPatienceAsWeWorkToImprove": "我们感谢您在我们努力改善",
        "yourExperience": "您的体验时的耐心。",
        "inTheMeantimeIfYouHaveAnyQuestionsOrNeed": "在此期间，如果您有任何问题或需要",
        "assistancePleaseContactOurSupportTeam": "协助，请联系我们的支持团队。",
        "contactSupport": "联系支持",
        "ok": "确定",
        "thankYouForBeingAValuedUserOfOurPlatform": "感谢您成为我们平台的珍贵用户。",

        //search-company
        "people": "潜在客户",
        "companies": "公司",
        "clearAll": "清除所有",
        "searchProspects": "搜索潜在客户、关键字...",

        //actions
        "isFoundIn": "在 ",
        "isNotFoundWithinAnyLists": "未在任何列表中找到",
        "l": " 列表",
        "remove": "移除",
        "saveToLists": "保存到列表",
        "DeleteFailed": "删除失败",

        //delete dialog
        "areYouSureYouWantToDeleteThisRecord": "您确定要删除此记录吗？",
        "proceedingWithDeletionWillResultInPermanentRemovalOfTheList": "进行删除将导致该列表永久移除。",
        "delete": "删除",
        "byCancelingYouEnsureThatNoListsWillBeRemoved": "通过取消，您可以确保不会移除任何列表。",

        //export dialog
        "holdOnWereGeneratingYourExport": "请稍候，我们正在生成您的导出文件。",
        "UseThisButtonOnlyIfYouWishToCancelYourAction": "仅在您希望取消操作时使用此按钮。",

        //save search pop
        "saveSearch": "保存搜索",
        "newSaveSearch": "新保存搜索",
        "UpdateSavedSearch": "更新已保存搜索",
        "enterNewSearchName": "输入新搜索名称",
        "visibility": "可见性",
        "manageWhoCanSeeYourSavedSearch": "管理谁可以查看您的已保存搜索",
        "private": "私有",
        "onlyYouCanSeeYourSavedSearch": "只有您可以查看您的已保存搜索",
        "everyoneCanSeeYourSavedSearch": "每个人都可以查看您的已保存搜索",
        "pinSavedSearch": "固定已保存搜索",
        "findSavedSearch": "查找已保存的搜索",
        "enterSavedSearchName": "输入已保存搜索名称",

        "duplicate":"重复", 
        "update":"更新", 
        "noSavedSearches":"没有已保存的搜索", 
        "RemoveCreditCard":"移除信用卡", 
        "enterATitleForYourCard":"为您的卡片输入标题", 
        "enterYourCardNumber":"输入您的卡号", 
        "digit":"3位数", 
        "provideYourCompleteAddress":"提供您的完整地址", 
        "selectYourCountry":"选择您的国家",

        //add credits pop
        "ClickTheButtonBelowToAddMoreCredits":"点击下面的按钮以添加更多信用点数！", 
        "PleaseEnterTheAmountYouLikeToTopUpToYourCredit":"请输入您希望充值的金额", 

        //companyLink\contactBtn\functionBtn
        "more":"更多", 
        "verified":"已验证", 
        "GetEmailSuccessfully":"成功获取电子邮件", 
        "CopySuccessfully":"成功复制", 
        "recorded":"已记录", 
        "implement":"实施", 
        "sequence":"工作流程", 
        "of":" 总条数：", 
        "toAssistYouEffectivelyWeRequireFurtherDetailsAbout":"为了有效协助您，我们需要进一步了解您的搜索偏好。请利用左侧面板中的筛选选项来指定您所需的标准，以便我们能够精炼搜索结果，并为您提供符合要求的目标。",

        //filter
        "all":"所有", 
        "my":"我的", 
        "team":"团队", 
        "lists":"列表", 
        "employees":"员工", 
        "technologies":"技术", 
        "socialmedia":"社交媒体", 
        "enterSocialMedia":"输入社交媒体", 
        "noResultsFound":"未找到结果", 
        "searchCompanies":"搜索公司", 
        "filters":"筛选器", 
        "enterCompanies":"输入公司名称", 
        "accountHqLocation":"帐户总部位置", 
        "CityStateCountryZIP":"城市/州/国家/邮政编码", 
        "enterLocations":"输入地点", 
        "revenue":"收入", 
        "chooseARangeOfRevenue":"选择收入范围", 
        "ofRevenueIsUnknown":"收入未知", 
        "technology":"技术", 
        "category":"类别", 
        "categories":"类别", 
        "enterCategories":"输入类别", 
        "enterTechnologies":"输入技术", 
        "enterIndustries":"输入行业", 
        "employee":"员工", 
        "predefinedRange":"预定义范围", 
        "ofEmployeesIsUnknown":"员工数量未知", 
        "enterNames":"输入姓名", 
        "jobTitle":"职位名称", 
        "searchForAJobTitle":"搜索职位名称", 
        "founded":"成立于", 
        "chooseARangeOfFoundedTime":"选择成立时间范围", 
        "from":"从", 
        "to":"到",  
        'specialties':'专业领域',
        'enterSpecialties':'输入专业领域',
        'location':'地点',
        'total':'总计',
        'department':'部门',
        'EnterDepartments':'输入部门',
        'JobTitle':'职位名称',
        'EnterJobTitles':'输入职位名称',
        'ManagementLevel':'管理层级别',
        'EnterManagementLevels':'输入管理层级别',
        'AccountHQ':'帐户总部',
        'ContactHQ':'联系总部',
        'departmentFunction':'部门功能',
        'EnterJobFunctions':'输入职务功能',
        'enterNamesAndPressTheEnterKey':'输入姓名并按Enter键...',
        'EnterJobTitlesAndPressTheEnterLKey':'输入职位并用“,”分隔，然后按Enter键...',
        'pleaseSelectRange':'请选择范围',

        //table
        "selectAllCompanies": "选择所有公司",
        "selected": "已选择",
        "export": "导出",
        "industry": "行业",
        "keywords": "关键字",
        "companyLocation": "公司位置",
        "action": "操作",
        "PleaseEnterTheSelectedQuantity": "请输入所选数量",
        "contact": "联系人",
        "Title": "职称",
        "selectThisPage": "选择此页面",
        "clearSelection": "清除选择",
        "selectNumberOfCompanies": "选择公司数量",
        "maxCompanies": "最多公司数量",
        "youCanOnlySelectAMaximumOf25Companies": "您最多只能选择25家公司。",
        "UpgradeYourPlanNow": "立即升级您的计划！",
        "viewPlans": "查看计划",
        "applySelection": "应用选择",
        "selectNumberOfProspects": "选择潜在客户数量",
        "maxProspects": "最多潜在客户数量",
        "youCanOnlySelectAMaximumOf25Prospects": "您最多只能选择25个潜在客户。",
        "PleaseSelectTheDataToBeExported": "请选择要导出的数据",
        "overview": "概览",
        "genericContacts": "通用联系人",
        "size": "规模",
        "contactLocation": "联系人位置",

        "addYourCreditCardInformation":"添加您的信用卡信息", 

        //account
        "addCreditCard":"添加信用卡", 
        "zip":"邮政编码", 
        "country":"国家", 
        "state":"州", 
        "city":"城市", 
        "address":"地址", 
        "billingInformation":"账单信息", 
        "yourTeams":"您的团队", 
        "cvc":"CVC", 
        "year":"年", 
        "month":"月", 
        "expirationDate":"到期日", 
        "creditCardNumber":"信用卡号码", 
        "cardInformation":"卡片信息", 
        "title":"职称", 
        "fullName":"全名", 
        "yourDetail":"您的详细信息", 
        "creditsToUse":"可用信用点数。", 
        "youHave":"您拥有", 
        "download":"下载", 
        "buyMoreCredits":"购买更多信用点数", 
        "description":"描述", 
        "topUpCredits":"充值信用点数", 
        "cancel":"取消", 
        "date":"日期", 
        "invoiceHistory":"发票历史", 
        "confirmNewPass":"确认新密码", 
        "creditsUsed":"已使用信用点数", 
        "newPass":"新密码", 
        "youHaveNotEnteredAnyCreditCardInformationYet":"您尚未输入任何信用卡信息。", 
        "oldPass":"旧密码", 
        "cardNumber":"卡号：", 
        "changePassword":"更改密码", 
        "creditUsage":"信用点数使用情况", 
        "typeTheNewTitle":"输入新职称", 
        "save":"保存", 
        "addMoreCredits":"添加更多信用点数", 
        "featureRequest":"功能请求", 
        "creditsMo":"{credit} 信用点数/月", 
        "apiIntegrations":"API 集成", 
        "profileSubscription":"个人资料订阅", 
        "additional-user-licence": "额外用户许可证",
        "agency-white-label": "代理商白标",
        "api-extended-limit": "API 扩展限制",
        "profile-subscription": "个人资料订阅",
        "creditsInYourPlan": "计划中的信用点数",
        "plugin": "插件",
        "settings": "设置",
        "phone": "电话",
        "1User": "1个用户",
        "users": "用户",
        "billing": "账单",
        "monthlyQueryLimit": "每月查询上限",
        "price": "价格",
        "whatsIncluded": "包含内容",
        "givenName": "名",
        "profile_teams": "个人资料与团队",
        "purchasePlan": "购买计划",
        "accountOverview": "帐户概览",
        "familyName": "姓氏",
        "dailyQueryLimit": "每日查询上限",
        "rateLimit": "查询限额",
        "youAreOnA": "您正在使用一个",
        "PlanAndYourCreditsWillRefreshOn": "计划，您的信用点数将在以下日期刷新",

        "过期":"", 
        "searchSettings":"搜索设置...", 
        "plan":"计划", 
        "accountInfo":"帐户信息", 
        "creditCardInformation":"信用卡信息", 
        "apiUsage":"API 使用情况", 
        "mo":"/月", 
        "perUserPerMonth":"每位用户每月", 
        "billedAnnually":"按年计费", 
        "dailyQuery":"每日查询", 
        "monthlyQuery":"每月查询", 
        "currentPlan":"当前计划", 
        "everythingInPlus":"所有加值内容", 
        "mostPopular":"最受欢迎的", 
        "topUpToIncreaseYourCredits":"充值以增加您的信用点数", 
        "addCredits":"添加信用点数", 
        "allPlansInclude":"所有计划均包括", 
        "Amount":"金额", 
        "credits":"信用点数", 
        "purchaseSuccess":"成功购买信用点数，您的总信用点数为 ", 
        "Loading":"加载中...", 
        "Selected":"已选择", 
        "SelectPlan":"选择计划", 
        "currentPlan":"当前订阅", 
        "upgradeSuccess":"计划切换成功", 
        "purchaseFailed":"购买失败", 
        "upgradeFailed":"计划切换失败", 
        "inviteSentSuccessfully":"邀请已成功发送", 
        "DeleteSuccessfully":"删除成功", 
        "AccountupdatedSuccessfully":"帐户更新成功", 
        "AccountUpdateFailed":"帐户更新失败", 
        "PasswordUpdatedSuccessfully":"密码更新成功", 
        "PasswordUpdateFailed":"密码更新失败", 
        "teamDetails":"团队详细信息", 
        "teamName":"团队名称", 
        "changeTeam":"更改团队", 
        "clickToChangeTeam":"点击以更改团队", 
        "changeTeamSuccessfully":"成功更改团队", 
        "status": '状态',
        "Action": '操作',
        "pending": '待处理',
        "current": '当前',
        "AreYouSureYouWantToDeleteThisCreditCard": '您确定要删除此信用卡吗？',
        "AreYouSureYouWantToDeleteThisTeamMember": '您确定要删除此团队成员吗？',
        "PasswordCannotBeEmpty": '密码不能为空',
        "newUser": '新用户',
        "deleteUser": '删除用户',
        "nickname": '昵称',
        'admin': '管理员',
        'inviteYourTeam': '邀请您的团队',
        'empowerEveryoneToGetMoreDealsDonefaster': '赋能每个人更快地完成更多交易',
        'addMultipleEmailAddressesSeparatedByCommas': '添加多个电子邮件地址，以逗号分隔',
        'sendInvites': '发送邀请',
        'FirstName': '名字',
        'LastName': '姓氏',
        'timezone': '时区',
        'contactNo': '联系电话',
        'controlYourTeamMembersAndTheirAccountAccessRightHere': '在此控制您的团队成员及其账户访问权限。',
        'pleaseEnterYourCurrentPasswordToChangeYourPassword': '请输入您的当前密码以更改密码。',
        'updatePassword': '更新密码',
        'oldPassword': '旧密码',
        'confirmNewPassword': '确认新密码',
        'InviteYourTeam': '邀请您的团队',
        'empowerEveryoneToGetMoreDealsDoneFaster': '赋能每个人更快地完成更多交易。',
        'enterEmailAddressesMultipleCommaseparated': '输入电子邮件地址（多个以逗号分隔）',
        'invoice': '发票',
        'accountAdmin': '账户管理员',
        'setAsDefault': '设为默认',
        'bankName': '银行名称',
        'expiry': '到期日',
        'viewAllYourPreviousInvoicesHere': '在此查看您所有的历史发票。',
        'BillingHistory': '账单历史',
        "updateYourCreditCardInformation": "更新您的信用卡信息。",
        "ManageCreditCard": "管理信用卡",
        "CardNumber": "卡号",
        "billingAddress": "账单地址",
        "yourCreditsWillRefreshOn": "您的信用点数将在以下日期刷新",
        "yourCreditsWillBeChargeOn": "您的信用点数将在以下日期收费",
        "被收取": "",
        "/year": "/年",
        "/month": "/月",
        "monthlyCredit": "每月信用点数",
        "Usage": "使用情况",
        "features": "功能",
        "credits_mo": "信用点数/月",
        "credits_year": "信用点数/年",
        "_mo": "/月",
        "seeOtherPlan": "查看其他计划",
        "subscriptionTiers": "订阅层级",
        "experiencePricingThatsEasyToUnderstandAndAdaptable": "体验易于理解和适应您需求的定价。",
        "monthlyBilling": "每月计费",
        "annualBilling": "年度计费",
        "forPlanCustomizationContactOurSalesTeam": "如需计划定制，请联系我们的销售团队。",
        "billedAnnually_user": "按年计费/用户",
        "seePlanComparison": "查看计划比较",
        "topUp": "充值",
        "changeTeamNameFailed": "更改团队名称失败",

        // purchaseCreditsAtTheRateOf100PerCreditCustomizeyourCreditBundleBasedOnYourNeeds
        // '按1美元/信用点数购买信用点数，请根据您的需求定制信用点数包',

        "totalPrice": "总价格",
        "credit": "信用点数",
        "collapse": "折叠",
        "expand": "展开",

        // Intent
        "intentOverview": "概览",
        "intentAnalytic": "分析",
        "intentFilter": "筛选",
        "searchCompany": "搜索公司、网站、LinkedIn...",
        "savedCompanyWebsite": "已保存的公司网站",
        "companiesFound": "间找到的公司",
        "companyFound": "间找到的公司",
        "filterList": {
        "technologies": "技术",
        "time": "时间",
        "locations": "账户位置",
        "verticals": "行业",
        "pageview_greater_than": "页面浏览量大于",
        "session_greater_than": "会话数大于",
        "advertisements": "广告",
        "company_groups": "列表",
        "start_date": "开始日期",
        "end_date": "结束日期",
        "search_term": "搜索词"
        },
        "employeeFilterList": {
        "industry": "行业",
        "technology": "技术",
        "jobTitle": "职位名称",
        "company": "公司",
        "list": "列表",
        "name": "姓名",
        "size": "规模",
        "location": "地点",
        "jobFunction": "职务功能"
        },
        "selectLists": "选择列表...",
        "chooseARangeOfTime": "选择时间范围",
        "greaterThan": "大于",
        "selectAdvertisement": "选择广告",
        "tabActivity": "活动",
        "tabOverview": "概览",
        "tabEmployees": "员工",
        "tabContacts": "通用联系人",
        "tabTechnologies": "技术",
        "intentStatistic": "统计",
        "intentCampaign": "活动",
        "newVisitors": "新访客",
        "recurringVisitors": "回访访客",
        "mediocreLead": "一般潜在客户",
        "sessionsTracked": "已追踪会话",
        "sessionTracked": "已追踪会话",
        "lastSeenOn": "最后一次出现在 ",
        "firstSeenOn": "第一次出现在 ",
        "viewTrackedWebSession": "查看已追踪的网络会话",
        "webSession": "网络会话",
        "client": '客户',
        "timeSpent": '花费时间',
        "pageviews": '页面浏览量',
        "pageview": '页面浏览量',
        "referrer": '来源',
        "sessions": '会话数',
        "session": '会话',
        "visited": '已访问',
        "AM": '上午',
        "PM": '下午',
        "companyDetails":"公司详细信息", 
        "overviewList":{
        "location":"地点", 
        "industry":"行业", 
        "founded":"成立于", 
        "ranking":"排名", 
        "size":"规模", 
        "revenue":"预估收入", 
        "keywords":"关键字"
        },
        "advancedSearch":"进阶搜索", 
        "jobFunction":"职务功能", 
        "employeeLocation":"位置", 
        "concatLocation":"联系位置", 
        "contactEmail":"电子邮件", 
        "categoryAndTechnology":"类别与技术", 
        "firstDetected":"首次检测", 
        "lastDetected":"最后检测", 
        "saved":"已保存", 
        "addToContactList":"添加到联系人列表", 
        "noCampaignIdentified":"未识别任何活动", 
        "copyEmail":"复制电子邮件", 
        "willBeSavedToTheFollowingSelectedLists":"将保存到以下选定列表：", 
        "addToLists":"添加到列表", 
        "noDataToDisplay":"无数据可显示", 
        "intentFounded":"成立于", 
        "intentIndustry":"行业", 
        "intentRevenue":"收入", 
        "intentSize":"规模", 
        "applyFilters":"应用筛选器", 
        "youCanEditTheseFilters":"您可以随时编辑这些筛选器以精炼结果", 
        "installB2BDirectLeadFinder":"在您的网站上安装B2B直接潜在客户查找器",
        "addSite":"添加网站",
        "areYouSureYouWantToRemoveThisWebsite":"您确定要移除此网站吗？",
        "Cancel":"取消",
        "installB2BLeadFinder":"安装B2B潜在客户查找器",
        "siteUrl":"网站网址",
        "copyToClipboard":"复制到剪贴板",
        "pleaseSelectAContactFirst":"请先选择一个联系人",
        "pasteCodeBelowIntoTheHead":"将以下代码粘贴到您希望追踪访问者的每个页面的头部中。",
        "ofEveryPageYouWishToTrackVisitorOn":"的每个页面。",
        "forWordpressWeRecommendInstalling":"对于WordPress，我们建议安装来自Stefano Lissa的插件。",
        "pluginFromStefanoLissa":"插件。",
        "afterInstallingAndActivating":"安装并启用插件后，请导航至",
        "inYourWordPressDashboard":"在您的WordPress仪表板中。",
        "youWillSeeANumberOfTabs":"您将在插件界面中看到多个标签。确保您在默认的“标头和页脚”标签中，然后复制并粘贴以下代码片段：",
        "settingHeaderAndFooter":"设置 → 标头和页脚",
        "dontForgetToClearYourCache":"不要忘记清除您的缓存。",
        "IfYouAreUsingAnyCaChing":"如果您使用任何缓存插件，例如",
        "orSimilar":"或类似的，请确保在安装代码片段后清除缓存！",
        "GTMshouldAlreadyBeInstalled":"Google Tag Manager 应该已经安装在您的网站上，然后再按照这些说明操作。",
        "IfYouDontHaveGTM":"如果您尚未安装Google Tag Manager，您可以在以下位置了解如何安装：",
        "GTMsupportArticleAboutInstall":"Google Tag Manager 支持文章有关安装的内容。",
        "signInToGTMAndClick": "登录Google Tag Manager，然后单击“添加新标签”按钮。接下来，",
        "clickWhereItSays": "单击显示的地方，",
        "chooseATagTypeToBeginSetup": "选择一个标签类型以开始设置",
        "andSelect": "并选择 ",
        "fromTheList": "从列表中。然后，将以下代码复制并粘贴到文本字段中：",
        "nextClick": "接下来，单击 ",
        "giveTheTagAName": ". 给标签命名，然后单击保存按钮。",
        "dontForgetToPublishYourChange": "不要忘记发布您的更改",
        "afterCreateTheCustom": "创建自定义HTML标签后，不要忘记 ",
        "yourChanges": " 您的更改！",
        "submitAndPublish": "提交并发布",
        "copySuccessfully": "成功复制",
        "addToCompanyList": "添加到公司列表",
        "addToList": "添加到列表",
        "enterOrCreateList": "输入或创建列表",
        "installCode": "安装代码",
        "wordpress": "WordPress",
        "googleTagManager": "Google Tag Manager",
        "noVisitorsYet": "尚无访问者",
        "onceTheInsightsRollIn": "一旦获得见解，您将解锁有关访问者及更多的宝贵信息。",
        "noTechAreFound": "未找到技术信息",
        "updateSuccessfully": "成功更新",

        "enterJobFunction":"输入职务功能", 
        "pinSuccessfully":"钉选成功", 
        "cancelPinSuccessfully":"取消钉选成功", 
        "adsName":"广告名称", 
        "utmTerm":"utmTerm", 
        "utmCampaign":"utmCampaign", 
        "utmSource":"utmSource", 
        "utmMedium":"utmMedium", 
        "enterNumber":"输入数字", 
        "enterName":"输入姓名", 
        "enterJobTitle":"输入职位名称", 
        "areYouSureYouWantToRemovePeopleOfThisGroup":"您确定要移除此组中的联系人吗？", 
        "areYouSureYouWantToRemoveCompanyOfThisGroup":"您确定要移除此组中的公司吗？", 
        "byCancelingYouEnsureThatNoPeopleWillBeRemoved":"通过取消，您可以确保不会移除任何联系人。", 
        "byCancelingYouEnsureThatNoCompanyWillBeRemoved":"通过取消，您可以确保不会移除任何公司。", 
        "howToGetStarted":"如何开始", 
        "Data": '条数据',
        "deactivateWebsite":"停用网站", 
        "areYouCertainAboutDeactivatinYourWebsite":"您确定要停用您的网站吗？请注意，这将导致不接收任何潜在公司的活动。", 
        "byCancelingYouWillContinueToActivateAndUse":"通过取消，您将继续启用和使用此工具。", 
        "yourTrialHasEnded":"您的试用已结束。", 
        "reactivateNow":"立即重新启用", 
        "yourWebsiteHasBeenAutomaticallyInactivated":"您的网站已自动停用。您是否希望重新启用此工具以继续监控数据？", 
        "Inactivated":"已停用", 
        "onTrial":"试用中", 
        "Active": '启用设置',
        "youAreOnTrail": "您正在进行7天的试用",
        "yourTrailHasEnded": "您的试用已结束",
        "createNewWebIntent": "创建新的网络意图",
        "clickOnTheButton": "1. 点击（+）按钮",
        "typeInUrlOrWebAddress": "2. 输入网址或网站地址",
        "theTrialWillActivate": "勾选此框将启动7天的试用",
        "pleaseEnterASiteUrl": "请输入网站网址",
        "noMoreData": "没有更多数据",
        "pleaseEnterANewName": "请输入一个新名称",
        "pleaseSelectASearchName": "请选择一个搜索名称",
        "clickToGetEmail": "点击以获取电子邮件",
        "emailIsNotAvailable": "电子邮件不可用",
        "enterOrCreateLists": "输入或创建列表",
        "viewMore": "查看更多",
        "removeFromContactList": "从联系人列表中移除",
        "willBeRemovedFromTheFollowingLists": "将从以下列表中移除",
        "removeFromLists": "从列表中移除",
        "pleaseSelectOrCreateAList": "请选择或创建一个列表",
        "chooseATriggerToMakeThisTagFire": "选择一个触发器以使此标签触发...",
        "youWontBeChargedYet": "您尚未被收费",
        "youDontHaveEnoughCredits": "您没有足够的信用点数",
        "wouldYouLikeToAdd50Credits": "您想添加50点（最低充值金额）以继续发送电子邮件吗？",
        "ThisEmailRequire": "此电子邮件需要",
        "TheseEmailsRequires": "这些电子邮件需要",
        "intentCredit": "信用点数",
        "intentCredits": "信用点数",

        // analytic
        "showNumber": "显示数字",
        "Website": "网站",
        "b2bTraffic": "B2B 流量",
        "b2bReturningVsNew": "B2B 回访与新访客",
        "visitors": "访客",
        "totalView": "总浏览量",
        "totalSessions": "总会话数",
        "operatingSystem": "操作系统",
        "browser": "浏览器",
        "device": "设备",
        "companySize": "公司规模",
        "topIndustry": "主要行业",
        "topCountries": "主要国家",
        "topReferrals": "主要推荐来源",
        "new": "新访客",
        "returning": "回访者",
        "countriesStatistics": "国家统计",
        "topCompaigns": "主要活动",
        "topPagesByVisits": "按访问量排序的页面",
        "topPagesByEngagement": "按互动量排序的页面",
        "Referrals": "推荐来源",
        "Pages": "页面",
        "avgTime": "平均时间",
        "totalCompanies": '总公司数',
        "b2bPageViews": 'B2B 页面浏览量',
        "TotalSessions": '总会话数',
        "viewCompanyList": '查看公司列表',
        "noDiscussionsYet": '尚无讨论',
        "useThisSpaceToAskQuestions": '您可以使用此空间提出问题、获取更新，并直接与我们讨论详情',

        // mycontact
        "prospects": '潜在客户',
        "list": '列表',
        "view": '查看',
        "add": '添加',
        "newList": '新列表',
        "savedLists": '已保存列表',
        "searchList": '搜索列表名称',
        "owner": '拥有者',
        "records": '记录',
        "actions": '操作',
        "lastModified": '最后修改时间',

        "name":"名称", 
        "enterAListName":"输入列表名称", 
        "pleaseSelectAListFirst":"请先选择一个列表", 
        "saveSuccessfully":"保存成功", 
        "pleaseEnterAListName":"请输入列表名称", 
        "editList":"编辑列表", 
        "fromThisList":"从此列表中。您确定要继续吗？", 
        "areYouSureYouWantToChange":"您确定要将列表类型更改为",

        // confirmation messages
        // yes: '是', 
        // no: '否', 
        "noListCreated":"未创建列表", 
        "enhanceTheOrganization":"增强您在目标市场内联系人和账户的组织。创建列表后，它们将显示在此页面上。",
        "createSuccessfully":"创建成功", 
        "removeSuccessfully":"移除成功", 
        "createNewList":"创建新列表", 
        "groupTeam":"团队", 
        "groupPersonal":"个人", 
        "edit":"编辑", 
        "areYouSureYouWantToDeleteTheList":"您确定要删除该列表吗？", 
        "proceedingWithDeletion":"进行删除将导致该列表永久移除。", 
        "byCanceling":"通过取消，您可以确保不会移除任何列表。", 
        "byCancelingYouEnsureThatNo":"通过取消，您可以确保不会移除任何", 
        "willBeRemoved":"将被移除", 
        "groupPeople":"联系人", 
        "groupCompany":"公司", 
        "myList":"我的列表", 
        "teamList":"团队的列表", 
        "resetAssured":"请放心，您可以根据需要在不同类型之间切换。", 
        "wontBeChanged":"通过取消，您可以确保此列表类型不会更改。", 
        "priority":"优先级", 
        "proceedingWithDeletionWillResultInPeopleDelete":"进行删除将导致联系人永久移除。", 
        "proceedingWithDeletionWillResultInCompanyDelete":"进行删除将导致公司永久移除。", 
        "areYouSureYouWantToRemoveCompanyFromList":"您确定要从列表中移除公司吗？", 
        "areYouSureYouWantToRemovePeopleFromList":"您确定要从列表中移除联系人吗？", 
        "groupExisted":"该组已存在。", 
        "sorryTheListIsCurrrntlyOpen":"抱歉，该列表目前是开放的。您确定要删除它吗？", 

        // additional fields
        "listName":"名称", 
        "pleaseEnterAValidSiteUrl":"请输入有效的网站网址",
        // market-intent
        "comingSoon": "即将推出",
        "weAreCurrentlyWorkingOnThisFeature": "我们目前正在开发此功能，将很快推出",
        "stayConnectedWithUs": "通过我们的社交媒体渠道保持联系，以接收更新。",
        "selectAWebsite": "选择一个网站",

        // sequence
        "Sequences": "工作流程",
        "label": "标签",
        "template": "模板",
        "folder": "文件夹",
        "single": "单一",
        "group": "群组",
        "steps": "步骤",
        "sizes": "大小",
        "time": "时间",

        // email
        "SendATestEmail": "发送测试电子邮件",
        "Categories": "类别",
        "addToCategory": "添加到类别",
        "EnterOrCreateCategories": "输入或创建类别",
        "addSuccessfully": "添加成功",
        "Channels": "渠道",
        "emails": "电子邮件",
        "enterChannel": "输入渠道",
        "CategoryName": "类别名称",
        "PleaseEnterANewCategoryName": "请输入新类别名称：",
        "EnterANewCategoryName": "输入新类别名称",
        "Steps": "步骤",
        "Actions": "操作",
        "Time": "时间",
        "From": "来自",
        "To": "至",

        "CancelSequenceCreation":"取消工作流程创建", 
        "AreYouSureYouWantToCancelTheSequenceCreationProcess":"您确定要取消工作流程创建过程吗？", 
        "SaveAsDraft":"保存为草稿", 
        "CancelSequence":"取消工作流程", 
        "ByCancelingAllSettingsWillBeLost":"通过取消，您所做的所有设置和进度将会丢失。", 
        "ImportContacts":"导入联系人", 
        "PleaseUploadACSVFileByDraggingAndDroppingItHere":"请通过拖放CSV文件到此处上传", 
        "ChooseAFile":"选择文件", 
        "CSVFileFields":"CSV文件字段", 
        "ReplyFields":"回复字段", 
        "continue":"继续", 
        "Contacts":"联系人", 
        "Back":"返回", 
        "Import":"导入", 
        "Imported":"已导入", 
        "Skipped":"已跳过", 
        "Done":"完成", 
        "NewCustomField":"新自定义字段", 
        "EnterNewLabel":"输入新标签", 
        "EnterNewType":"输入新类型", 
        "removeRecorded":"移除记录", 
        "YouHaveUnsavedChanges":"您有未保存的更改", 
        "LeavingDiscard":"离开此页面将丢弃所有未保存的内容。您确定要继续吗？", 
        "StayPage":"留在此页面", 
        "LeavePage":"离开此页面", 
        "NewCategory":"新类别", 
        "Create":"创建", 
        "UnlockingSequence":"解锁此工作流程使您能够进行修改", 
        "ModifyingLeadDeselection":"修改此内容将导致当前模板版本的取消选择", 
        "Modify":"修改", 
        "CancelingPreserveCurrent":"取消将保留当前版本", 
        "rename":"重新命名", 
        "CreatNewLabel":"创建新标签", 
        "NewLabel":"新标签", 
        "PleaseEnterANewLabelName":"请输入新标签名称：", 
        "EnterANewLabelName":"输入新标签名称", 
        "SequenceName":"工作流程名称", 
        "PleaseProvideNameForNewSequence":"请提供新工作流程的名称", 
        "EnterNewSequenceName":"输入新工作流程名称", 
        "SetUpEmail":"设置电子邮件账户以发送", 
        "NewSchedule":"新日程", 
        "PleaseEnterANewScheduleName":"请输入新日程名称：",  
        "EnterNewScheduleName": '输入新日程名称',  
        'AddNewTemplate': '添加新模板',  
        'RemoveFromTemplate': '从模板中移除',
        "deleteSuccess": "删除成功",
        "removeSuccess": "移除成功",
        "GroupName": "群组名称",
        "PleaseEnterNewGroupName": "请输入新群组名称",
        "EnterNewGroupName": "输入新群组名称",
        "TemplateName": "模板名称",
        "PleaseEnterANewTemplateName": "请输入新模板名称",
        "EnterNewTemplateName": "输入新模板名称",
        "renameSuccessfully": "重命名成功",
        "CreateSuccessfully": "创建成功",
        "addToGroup": "添加到群组",
        "SelectChannelForNewTemplate": "为新模板选择渠道",
        "Role": "角色",
        "Account": "账户",
        "customFields": "自定义字段",
        "contactsToStartEngagingWithThem": "联系人以开始与他们互动。",
        "thisSequenceDoesntHaveAnyProspectsOrLeadsYetConsid": "此工作流程尚未有任何潜在客户或线索。考虑添加",
        "noProspectsAdded": "未添加潜在客户",
        "deliveryCurrentlyNoMessagesHaveBeenSentThroughTheS": "投递。目前，尚未通过该工作流程发送任何消息。",
        "ensureEfficientCommunicationByMonitoringYourSequen": "通过监控您的工作流程消息来确保有效的沟通。",
        "noMessagesHaveBeenSent": "尚未发送任何消息",
        "yourNeedsGetStartedByClickingNewSequence": "满足您的需求。通过点击 [+ 新工作流程] 开始。",
        "beginBuildingYourSalesStrategiesByDesigningSequenc": "通过设计符合您需求的工作流程开始构建销售策略。",
        "noSequencesCreatedYet": "尚未创建工作流程",
        "leadActions": "潜在客户操作",
        "leadInformation": "潜在客户信息",
        "thisGroupHasNoTemplatesYet": "该组尚无模板",
        "key": "{expr}",
        "copyContent": "复制内容",
        "default": "默认",
        "selectVersion": "选择版本",
        "subject": "主题",
        "searchTemplatesfolders": "搜索模板/文件夹",

        "send":"发送", 
        "subject2":"主题：", 
        "enterASubjectOrLeaveItBlankToContinueInTheSameThre":"输入主题或留空以继续在同一线程中进行", 
        "chooseYourChannel":"选择您的渠道", 
        "kl":"KL", 
        "addContacts":"添加联系人", 
        "addContactsToSequence":"将联系人添加到工作流程", 
        "addremoveCustomContactField":"添加/移除自定义联系人字段", 
        "contactCustomFields":"联系人自定义字段", 
        "type":"类型", 
        "typePhoneNumber":"输入电话号码", 
        "addAPhoneNumber":"添加电话号码", 
        "fieldType":"字段类型", 
        "fieldLabel":"字段标签", 
        "skipTheFollowingNationalHolidaysLaborDayIndependen":"跳过以下国定假日：劳动节、独立日、纪念日、感恩节、圣诞前夕、圣诞节、新年。", 
        "checked":"已勾选", 
        "useTheContactsLocalTimeZoneInsteadOfTheSchedulesTi":"如果联系人包含位置数据，则使用联系人的本地时区而不是日程的时区。", 
        "definePostreplyOrMeetingActionAndSequenceCompletio":"定义回复后或会议行动及工作流程完成标准。", 
        "stopRepliesHandling":"停止回复处理", 
        "timeZone":"时区：", 
        "country2":"国家", 
        "setMaxInitialEmailsDelaysEnabledisableOpensAndLink":"设置最大初始电子邮件、延迟、启用/禁用打开和链接跟踪。", 
        "configuration":"配置", 
        "additionalInformation":"附加信息", 
        "connectALinkedinAccount":"连接LinkedIn账户", 
        "newLinkedinAccount":"新LinkedIn账户", 
        "feelFreeToRevisitAnyOfThePreviousStepsAtAnyTimeToR":"随时可以重新访问之前的任何步骤，以检查或更新您的信息，然后再“启动”工作流程。", 
        "template2":"模板：",  
        "next":"下一步",  
        "jacquelineLeung":"Jacqueline Leung",  
        "settings2":"设置",  
        "editThisTemplateScheduleFromThe":"从中编辑此模板日程",  
        "typeEmail":"输入电子邮件",  
        "templateScheduling":"模板排程",  
        "launch":"启动",  
        "linkedin":"LinkedIn",  
        "addAEmail":"添加电子邮件",  
        "addEmailAccount":"添加电子邮件账户",  
        "addStep":"添加步骤",  
        "newEmailAccount":"新电子邮件账户",
        "craftYourLeadgenerationJourneyWithANewSequenceDefi":"使用新的工作流程打造您的潜在客户生成旅程！定义您的目标受众、消息和行动，以创建有效的潜在客户生成工作流程。",
        "craftYourCampaign":"打造您的活动",
        "tailorYourOwnHoursToSuitSpecificSchedulingNeeds":"根据特定排程需求量身定制自己的时间。",
        "personalizedScheduling":"个性化排程",
        "requiredInformation":"所需信息",
        "chooseEmailAccountsForSendingCampaignMessages":"选择发送活动消息的电子邮件账户",
        "accounts":"账户",
        "enterSequenceName":"输入工作流程名称",
        "ThisPageEmpowersYouToTailorYourSchedulingSequenceS":"此页面使您能够根据您的偏好调整排程工作流程设置。",
        "schedule": "排程",
        "useThisOptionToAddContactsToTheSequenceYouHaveCrea": "使用此选项将联系人添加到您创建的工作流程中。",
        "addProspects": "添加潜在客户",
        "save2": "保存",
        "days": "天",
        "days2": "天",
        "within": "内部",
        "opened": "已打开",
        "day": "天",
        "day2": "天",
        "waitFor": "等待",
        "sendTestEmailToMe": "发送测试电子邮件给我",
        "create": "创建",
        "enterANameForCustomContactField": "输入自定义联系人字段的名称",
        "a": "A",
        "byCancelingYouEnsureThatNoStepsWillBeDeleted": "通过取消，您可以确保不会删除任何步骤",
        "to2": "至",
        "enterNameForNewVersion": "输入新版本的名称",
        "activated": "已启用",
        "currentVersion": "当前版本",
        "setTheNewVersionDefaultNow": "立即设置新版本为默认",
        "enterNewName": "输入新名称",
        "newVersion": "新版本",

        "dontForgetToSetTheNewVersionDefaultOnceYouveFinish":"完成修改后，请勿忘记设置新版本为默认。", 
        "theDeletionOfThisConditionWillAlsoRemoveAllSubsequ":"删除此条件将同时移除所有后续步骤。", 
        "pleaseEnterANewName2":"请输入一个新名称：", 
        "aNewVersionOfTheSequenceHasBeenCreated":"工作流程的新版本已创建。", 
        "youCanEitherEditTheCurrentVersionOrSaveItAndCreate":"您可以编辑当前版本或保存并创建一个新版本。", 
        "areYouSureYouWantToProceed":"您确定要继续吗？", 
        "newVersionName":"新版本名称", 
        "sequenceStart":"工作流程开始", 
        "chooseActions":"选择操作", 
        "chooseSteps":"选择步骤", 
        "enterChannel2":"输入渠道", 
        "chooseCategories":"选择类别", 
        "htmlMode":"HTML模式", 
        "considerBroadeningYourSearchCriteria":"- 考虑扩大您的搜索标准。", 
        "checkYourSpellingAndMakeSureThereAreNoTypos":"- 检查拼写并确保没有错别字。", 
        "tryUsingDifferentKeywordsOrPhrases":"- 尝试使用不同的关键字或短语。", 
        "noResultsFoundForYourSearchCriteria":"未找到符合您搜索标准的结果", 
        "fromScratch":"从头开始", 
        "sender":"发件人", 
        "finished":"完成", 
        "inProgress":"进行中", 
        "fromTemplate":"来自模板", 
        "newSequence":"新工作流程", 
        "nameOwner":"名称拥有者", 
        "sort":"排序", 
        "ownedBy":"拥有者", 
        "starredOnly":"仅显示星标", 
        "starred":"星标", 
        "box2":"箱子2", 
        "box1":"箱子1", 
        "add2":"+ 添加", 
        "lastSynced":"最后同步", 
        "hourlyLimit":"每小时限制", 
        "dailyLimit":"每日限制", 
        "account":"账户", 
        "skipTheFollowingNationalHolidaysLaborDayIndependen2": '跳过以下国定假日：劳动节、独立日、纪念日、感恩节、圣诞前夕、圣诞节、新年。',
        "useTheContactsLocalTimeZoneInsteadOfTheSchedulesTi2": '如果联系人包含位置数据，则使用联系人的本地时区而不是日程的时区。',
        "saveChanges": '保存更改',  
        "emailSignature": '电子邮件签名',  
        "reset2": '重置',  
        "pleaseNoteTheValueShouldBe0OrEmails": '请注意，值应该 >0 或 < {expr}',  
        "youHaveNoScheduledTemplateYet": '您尚未安排模板',  
        "discoverPremiumOptions": '发现高级选项',  
        "mailbox": '邮箱',
        "sendingLimits": "发送限制",
        "connect": "连接",
        "joinOurCommunityOfPremiumUsersTransformingEmailEng": "加入我们的高级用户社区，通过Pubrio转变电子邮件互动并实现卓越的销售成果。",
        "enterYourEmailAddress": "输入您的电子邮件地址",
        "toJustMailbox": "仅发送到 {mailNum} 邮箱",
        "yourCurrentBasicPlanAllowsAccess": "您当前的基本计划允许访问",
        "setAsDefault2": "设为默认",
        "createNewSchedule": "创建新日程",
        "enterTemplateName": "输入模板名称",
        "googleMeet": "Google Meet",
        "emailPreview": "电子邮件预览",
        "addTemplate": "添加模板",
        "simplifyYourWorkflowGroupYourSequenceTemplatesToKe": "简化您的工作流程！将您的工作流程模板分组以保持组织，方便查找、编辑和根据需要使用。",
        "startCreatingPersonalizedTemplatesForYourSequences": "开始为您的工作流程创建个性化模板！单击「+」按钮以创建新模板，轻松流畅地进行通信。",
        "noTemplateYet": "尚无模板",
        "preview": "预览",

        "creatNewGroup":"创建新群组", 
        "creatNewGroup2":"创建新群组", 
        "stayOrganizedWithYourSequenceTemplatesCreateGroups":"保持您的工作流程模板有序！创建群组以轻松管理和定位您的模板，使编辑和使用变得更加简单。", 
        "noGroupsHaveBeenCreated":"尚未创建任何群组", 
        "creatNewTemplate":"创建新模板", 
        "addSequenceTemplateToTheGroup":"将工作流程模板添加到群组", 
        "byCancelingYouEnsureThatNoChangesAreMade":"通过取消，您可以确保不会进行任何更改", 
        "sequenceTemplates":"工作流程模板", 
        "youCanDeselectAndClickSaveToRemoveTheTemplateFromT":"您可以取消选择并单击「保存」以将模板从群组中移除", 
        "selectTemplateToAddToGroup":"选择要添加到群组的模板", 
        "sequenceStatus":"工作流程状态", 
        "sequenceStep":"工作流程步骤", 
        "newField":"新字段", 
        "enableTailoredDataEntryAndAdaptationToUniqueBusine":"启用量身定制的数据输入和适应独特的业务流程", 
        "customContactFields":"自定义联系人字段", 
        "modality":"模式", 
        "enterADescriptionForCustomContactField":"输入自定义联系人字段的描述", 
        "description2":"描述", 
        "enterADefaultValueForCustomContactField":"输入自定义联系人字段的默认值", 
        "defaultValue":"默认值", 
        "enterAExampleForCustomContactField":"输入自定义联系人字段的示例", 
        "example":"示例", 
        "enterPicklistoptionsForCustomContactField":"输入自定义联系人字段的下拉选项", 
        "newCustomContactFields":"新的自定义联系人字段", 
        "engagementMetrics":"互动指标", 
        "message":"消息", 
        "to3":"至：", 
        "receiver":"接收者", 
        "claraHan":"Clara Han", 
        "createNewCategory":"创建新类别", 
        "bestRegards":"最好的问候，", 
        "thanksAgain":"再次感谢！", 
        "lookingForwardToAFruitfulCollaborationIfTheresAnyt":"期待富有成效的合作。如果有任何具体事项需要在开始之前解决，请随时告诉我们。", 
        "jacquelineLeungProjectInCharge":"Jacqueline Leung（负责项目）", 
        "kingLaiAdviser":"King Lai（顾问）", 
        "iAppreciateTheIntroductionToOurMainContacts":"感谢您介绍我们的主要联系人：", 
        "contacted":"已联系", 
        "sendTestEmail":"发送测试电子邮件", 
        "thankYouSoMuchForTheWarmWelcomeWereEquallyExcitedA":"非常感谢您的热情欢迎！我们同样对与Pubrio GTM启动项目和成为Venture AI团队的一部分感到兴奋。", 
        "hiJacqueline":"嗨，Jacqueline，",  
        "nov152023118Pm": "2023年11月15日，下午1:18",
        "message2": "{total_messages} 消息",
        "topEcommerceAutomationPlatf": "顶级电子商务自动化平台...",
        "cindyHsu": "Cindy Hsu",
        "includeSignature": "包含签名",
        "ch": "CH",
        "continueOnFail": "失败时继续",
        "welcomeToPubrioGtm": "欢迎来到Pubrio GTM",
        "replyToPreviousThread": "回复之前的线程",
        "sendAutomatic": "发送自动 {title}",
        "searchByContacts": "按联系人搜索",
        "encodingErrorOccurredMaximumNumberOfUniqueContac": "处理错误：每月唯一联系人的最大数量已达到。",
        "oct140954Pm": "10月14日，09:54 PM",
        "shiftdragToMoveTheCanvas": 'Shift+拖动以移动画布',
        "active": '活跃',
        "inactive": '不活跃',
        "archived": '已归档',
        "archive": '归档',
        "unarchive": '取消归档',
        "archivedSuccessfully": '成功归档',
        "unarchivedSuccessfully": '成功取消归档',
        "syncStatusSuccess": '同步状态成功',
        "sequenceArchivedSuccess": "工作流程成功归档",
        "sequenceUnarchivedSuccess": "工作流程成功取消归档",
        "alphabetical": "按字母顺序",
        "creationDate": "创建日期",
        "viewDate": "查看日期",
        "staredFirst": "首标星优先",
        "SaveAsANewVersion": "另存为新版本",
        "SaveCurrentVersion": "保存当前版本",
        "schedules": "日程",
        "EmailsSentPerDay": "每日发送的电子邮件",
        "EmailsSentPerHour": "每小时发送的电子邮件",
        "DelayBetweenEachEmailSent": "每封电子邮件发送之间的延迟（秒）",
        "RecommendedDailyLimit": "建议每日限制：50-100封电子邮件，只有在发送高度个性化的活动且保持强大的域名声誉时，才可例外达到100封以上。",
        "RecommendedHourlyLimit": "建议每小时限制：将电子邮件数量保持在100封以下。每小时不得超过120封电子邮件。",
        "currentDelayAllows": "当前延迟允许您每小时最多发送120封电子邮件。",
        "SetAsDefault": "设为默认",

        "monday":"星期一", 
        "tuesday":"星期二", 
        "wednesday":"星期三", 
        "thursday":"星期四", 
        "friday":"星期五", 
        "saturday":"星期六", 
        "sunday":"星期日", 
        "Templates":"模板", 
        "Analytics":"分析", 
        "Meetings":"会议", 
        "Settings":"设置", 
        "EnterTemplateName":"输入模板名称", 
        "EnterGroupName":"输入群组名称", 
        "ExportAllData":"导出所有数据", 
        "ExportAsCSV":"导出为CSV", 
        "Total":"总计", 
        "Opened":"已打开", 
        "Clicked":"已点击", 
        "NotOpened":"未打开", 
        "Bounced":"退回", 
        "Replied": "已回复",
        "Failed": "失败",
        "Inbox": "收件箱",
        "Report": "报告",
        "Log": "日志",
        "UseThisTemplate": "使用此模板",
        "UseThisFolder": "使用此文件夹",
        "UpdateThisTemplate": "更新此模板",
        "RemoveFromGroup": "从群组中移除",
        "DeleteThisTemplate": "删除此模板",
        "MarkAsFinished": "标记为完成",
        "Remove": "移除",
        "Pause": "暂停",
        "Resume": "恢复",
        "AddToSequence": "添加到工作流程",
        "Export": "导出",
        "InProgress": "进行中",
        "Finished": "完成",
        "Paused": "已暂停",
        "Engaged": "参与中",
        "Reply": "回复",
        "NotSent": "未发送",
        "MaxNumberOfEmailsPerDay": "每日最大电子邮件数",
        "MaxNumberOfEmailsPerHour": "每小时最大电子邮件数",
        "EnableOpensTracking": "启用打开跟踪",
        "EnableReplyTracking": "启用回复跟踪",
        "EnableClickTracking": "启用点击跟踪",
        "ReplyByMessage": "通过消息回复",
        "BookAMeeting": "预订会议",
        "ClickOnLink": "点击链接",
        "CreateManually": "手动创建",
        "AddContactsToTheSequenceManually": "手动将联系人添加到工作流程，一个一个。",
        "ImportFromCSV": "从CSV导入",
        "UploadCSVFileToAddContactsToSequence": "上传CSV文件以将联系人添加到工作流程。",
        "AddFromExisting": "从现有中添加",
        "AddExistingContactsIntoTheSequence": "将现有联系人添加到工作流程。",
        "ChooseFile": "选择文件",
        "MapAttributes": "映射属性",
        "Options": "选项",
        "ReviewAndImport": "审核并导入",
        "UpdateMissingFields": "更新缺失字段",
        "DetectContactTimeZoneAutomatically": "自动检测联系人时区",
        "account_website": "账户网站",
        "linkedin_url": "LinkedIn网址",
        "raw_address": "原始地址",
        "first_name": "名字",
        "last_name": "姓氏",
        "account_name": "账户名称",

        "Identifier":"识别码", 
        "firstName":"名字", 
        "lastName":"姓氏", 
        "EnterJobTitleOrPosition":"输入职位名称或职位", 
        "EnterCompanyName":"输入公司名称", 
        "EnterYourCustomerID":"输入您的客户ID", 
        "EnterCompanyWebsiteURL":"输入公司网站URL", 
        "EnterLinkedInProfileURL":"输入LinkedIn个人资料URL", 
        "EnterCity":"输入城市", 
        "versionName":"版本名称", 
        "PleaseEnterANewVersionName":"请输入新版本名称", 
        "EnterNewVersionName":"输入新版本名称", 
        "versions":"版本", 
        "SequenceNameUpdatedSuccessfully":"工作流程名称更新成功", 
        "SequenceNameCannotBeEmpty":"工作流程名称不能为空",
        "delete_success": "删除成功",
        "delete_fail": "删除失败",
        "set_default_success": "设置默认成功",
        "DuplicateVersionSuccessfully": "成功复制版本",
        "CreateNewVersionSuccessfully": "成功创建新版本",
        "UpdateSuccessfully": "更新成功",
        "AddToGroupSuccessfully": "成功添加到群组",
        "sendAutomaticEmail": "发送自动电子邮件",
        "sendMessage": "发送消息",
        "uploadFailed": "上传失败",
        "InvalidEmailFormat": "电子邮件格式无效",
        "FailedToSetUpEmailAccount": "设置电子邮件账户失败",
        "noAccount": "没有账户",
        "addAnAccountToPersonalizYourExperienceAndUnlock": "添加账户以个性化您的体验并解锁高级功能",
        "trackingFeaturesForThisSequence": "此工作流程的跟踪功能。",
        "UpdatedSuccessfully": "更新成功",
        "update_fail": "更新失败",
        "connectYourGoogleMeetAccountToPubrioConversations": "将您的Google Meet账户连接到Pubrio会话",
        "set_default_fail": "设置默认失败",
        "duplicatedSuccessfully": "成功复制",
        "noStepsInSequencePleaseAddStepsFirst": "工作流程中没有步骤，请先添加步骤。",
        "addLabelSuccessfully": "成功添加标签",
        "PleaseEnterSequenceName": "请输入工作流程名称",
        "sequenceNameCannotBeEmpty": "工作流程名称不能为空",
        "sequenceNameUpdatedSuccessfully": "工作流程名称更新成功",
        "createStepFlowSuccessfully": "成功创建步骤流程",
        "updateStepFlowSuccessfully": "成功更新步骤流程",
        "testingEmailSentSuccessfully": "测试电子邮件发送成功",
        "removeEmailAccountSuccessfully": "成功移除电子邮件账户",
        "pleaseSelectTheDataToBeExported": "请选择要导出的数据",
        "confirm": "确认",
        "saveFailed": "保存失败",
        "deleteThisStep": "删除此步骤",
        "Email":"电子邮件", 
        "LinkedIn":"LinkedIn", 
        "API":"API", 
        "Task":"任务", 
        "Custom":"自定义", 
        "proceedImmediately":"立即进行", 
        "dayss": "天",
        "hourss": "小时",
        "minutess": "分钟",
        "theConditionAndAllTheStepsThatFollowFromItWillBeDeleted": "该条件及其后所有步骤将被删除，您确定要继续吗？",
        "sendConnectionRequest": "发送连接请求",
        "viewProfile": "查看个人资料",
        "callAnAPI": "调用API",
        "createAManualTask": "创建手动任务",
        "hasEmailAddress": "有电子邮件地址",
        "hasLinkedInURL": "有LinkedIn网址",
        "hasPhoneNumber": "有电话号码",
        "customCondition": "自定义条件",
        "openedEmail": "已打开的电子邮件",
        "clickOnLinkInEmail": "点击电子邮件中的链接",
        "unsubscribeFromEmail": "退订电子邮件",
        "bookAMeeting": "预订会议",
        "acceptedInvite": "接受邀请",
        "conditions":"条件", 
        "Yes":"是", 
        "No":"否", 
        "newVariable":"新变量", 
        "selectVariable":"选择变量", 
        "ifLeadVariable":"如果潜在客户变量 ", 
        "condition":"条件", 
        "selectCondition":"选择条件", 
        "contains": "包含", 
        "is_equal_to": "等于", 
        "is_empty": "为空", 
        "is_not_empty": "不为空", 
        "picture": "图片", 
        "company_domain": "公司域名", 
        "company_name": "公司名称", 
        "icebreaker": "破冰话题", 
        "change_steps_conditions": "更改步骤/条件", 
        "ForceASpecificSenderForThisStep": "强制此步骤使用特定发件人", 
        "method": "方法", 
        "selectASender": "选择发件人", 
        "assignAPITaskTo": "将API任务分配给", 
        "enterCompanyNamesAndPressEnterKey": "输入公司名称并按Enter键", 
        "feelFreeToEditTheContentAndSaveYourChangesDirectly": "随时编辑内容并在点击后直接保存更改。",
        "permission": "权限", 
        "addAnyAdditionalStepsOrConditionsAsNeeded": "根据需要添加任何其他步骤或条件", 
        "reviewAndFinalizeSequenceSettingsAndPreferences": "审核并最终确定工作流程设置和偏好",

        // setting
        "nameOnCard": "卡片上的姓名",
        "expiryDate": "到期日期",
        "cvccvv": "CVC/CVV",
        "details": "详情",
        "notification": "通知",
        "markAsAllRead": "标记为全部已读",
        "Personal": "个人",
        "profile": "个人资料",
        "extensions": "扩展功能",
        "notifications": "通知",
        "Company": "公司",
        "roles": "角色",
        "teams": "团队",
        "current-usage": "当前使用情况",
        "upgrade-plan": "升级计划",
        "Products":"产品", 
        "APIKeys":"API金钥", 
        "popular":"热门", 
        "priceSequence":"工作流程（即将推出）", 
        "free":"免费计划", 
        "lite":"轻量计划", 
        "growth":"初创计划", 
        "business":"商务计划", 
        "enterEmailAddressesMultipleCommaSeparated":"输入电子邮件地址（多个，以逗号分隔）", 
        "sharingRulesSelectTeamToEnableThisField":"共享规则（选择团队以启用此字段）", 
        "allSeatsAreCurrentlyOccupiedAnyNewUsersNeedingAPaidSeatWillIncurAdditionalCharges":"所有座位目前已被占用。任何需要付费座位的新用户将产生额外费用。",
        "dueToday":"今天到期", 
        "costPerBillingCycle":"每个计费周期的成本", 
        "select...":"选择...", 
        "invite":"邀请", 
        "services":"企业服务", 
        "services-group":"服务组", 
        "all-notification-services":"所有通知服务", 
        "services-status-update":"服务状态更新", 
        "system-group":"系统组", 
        "all-notification-system":"所有通知系统", 
        "system-new-feature-release":"系统新功能发布", 
        "system-news-letter":"系统新闻信", 
        "system-generic-alarm":"系统通用警报", 
        "markAsRead":"标记为已读", 
        "showLess":"显示较少", 
        "showMore":"显示更多", 
        "changedStatusTo":"状态已更改为", 
        "access-management-group":"访问管理组",
        "access-management-roles": "访问管理角色",
        "access-management-create-roles": "访问管理创建角色",
        "access-management-edit-user-roles": "访问管理编辑用户角色",
        "access-management-add-role-to-users": "访问管理将角色添加到用户",
        "access-management-delete-users-role": "访问管理删除用户角色",
        "access-management-delete-roles": "访问管理删除角色",
        "access-management-read-roles-and-role-users": "访问管理读取角色及其用户",
        "access-management-teams": "访问管理团队",
        "access-management-delete-users-team": "访问管理删除用户团队",
        "access-management-read-teams-sharing-rules-teams-users": "访问管理读取团队共享规则及其用户",
        "access-management-create-teams": "访问管理创建团队",
        "access-management-edit-teams-sharing-rules": "访问管理编辑团队共享规则",
        "access-management-delete-teams-sharing-rules": "访问管理删除团队共享规则",
        "access-management-add-and-update-team-to-users": "访问管理将团队添加并更新至用户",
        "access-management-read-team-relations": "访问管理读取团队关系",
        "access-management-modify-team-relations": "访问管理修改团队关系",
        "intent-signal-group": "意图信号组",
        "market-intent-signal": "市场意图信号",
        "web-intent-signal": "网络意图信号",
        "web-intent-signal-add-and-activate": "网络意图信号添加和激活",
        "web-intent-signal-read-data": "网络意图信号读取数据",
        "web-intent-signal-export-data": "网络意图信号导出数据",
        "web-intent-signal-delete-data": "网络意图信号删除数据",
        "web-intent-signal-read-analytic": "网络意图信号读取分析",
        "search-group": "搜索组",
        "company-search": "公司搜索",
        "prospects-search": "潜在客户搜索",
        "sequence-group": "工作流程组",
        "sequences": "工作流程",
        "service-create-request": "服务创建请求",
        "service-update-request": "服务更新请求",
        "service-read-request": "服务读取请求",
        "service-delete-service-attachment": "服务删除服务附件",
        "service-delete-service-message": "服务删除服务消息",
        "service-send-and-edit-service-message": "服务发送和编辑服务消息",
        "service-upload-service-attachment": "服务上传服务附件",
        "settings-group": "设置组",
        "custom-fields":"自定义字段", 
        "user-management-and-billing-group":"用户管理和计费组", 
        "billing-read-credit-card":"计费读取信用卡", 
        "billing-download-invoice":"计费下载发票", 
        "billing-read-invoice":"计费读取发票", 
        "credit-usage":"信用点数使用", 
        "credit-usage-read-profile-detail":"信用点数使用读取个人资料详情", 
        "credit-usage-read-profile-usage":"信用点数使用读取个人资料使用情况", 
        "profile-and-team":"个人资料与团队", 
        "profile-and-team-invite-team-member":"个人资料与团队邀请团队成员", 
        "profile-and-team-update-profile":"个人资料与团队更新个人资料", 
        "profile-and-team-delete-team-member":"个人资料与团队删除团队成员", 
        "profile-and-team-get-user-secret-token":"个人资料与团队获取用户密钥令牌", 
        "profile-and-team-update-user-and-password":"个人资料与团队更新用户及密码", 
        "profile-and-team-read-profile-users":"个人资料与团队读取个人资料用户", 
        "upgrade-plan-read-subscription":"升级计划读取订阅", 
        "upgrade-plan-upgrade-subscription":"升级计划升级订阅",
        "all-access-access-management-roles":"所有访问权限管理角色", 
        "all-access-access-management-teams":"所有访问权限管理团队", 
        "all-access-market-intent-signal-page":"所有访问市场意图信号页面", 
        "all-access-web-intent-signal-page":"所有访问网络意图信号页面", 
        "all-access-company-search-page":"所有访问公司搜索页面", 
        "all-access-prospects-search-page":"所有访问潜在客户搜索页面", 
        "all-access-sequence-page":"所有访问工作流程页面", 
        "all-access-service-page":"所有访问服务页面", 
        "all-access-custom-fields-page":"所有访问自定义字段页面", 
        "all-access-billing-page":"所有访问计费页面", 
        "all-access-credit-usage-page":"所有访问信用点数使用页面", 
        "all-access-profile-and-team-page":"所有访问个人资料与团队页面",
        "newPermissionProfile":"新权限配置档", 
        "enterAName":"输入名称", 
        "addUserToRole":"将用户添加到角色", 
        "changePermission":"更改权限", 
        "selectPermissionProfiles":"选择权限配置档", 
        "backToRoles":"返回角色", 
        "updateRole":"更新角色", 
        "enterADescription":"输入描述", 
        "createANewRole":"创建新角色",
        "AreYouSureYouWantToRemoveThisRole":"您确定要移除此角色吗？", 
        "AreYouSureYouWantToRemoveThisUserFromTheTeam":"您确定要将此用户从团队中移除吗？",
        "ProceedingWithDeletionWillPermanentlyRemoveTheRoleFromTheUser":"进行删除将永久移除该用户的角色。", 
        "ProceedingWithDeletionWillPermanentlyRemoveTheUserFromTheTeam":"进行删除将永久移除该用户从团队中。",
        "relation":"关系",
        "newTeam":"新团队",
        "full-sharing-market-intent-signal-data": "完整共享市场意图信号数据",
        "full-sharing-web-intent-signal-data": "完整共享网络意图信号数据",
        "full-sharing-company-search-data": "完整共享公司搜索数据",
        "full-sharing-prospects-search-data": "完整共享潜在客户搜索数据",
        "full-sharing-sequence-data": "完整共享工作流程数据",
        "full-sharing-service-data": "完整共享服务数据",
        "share-service-attachments": "共享服务附件",
        "share-service-messages": "共享服务消息",
        "RenameSharingRules": "重命名共享规则",
        "enterNewSharingRulesNameBelow": "在下方输入新共享规则名称：",
        "enterNewSharingRulesName": "输入新共享规则名称",
        "enterNewSharingRulesDescriptionBelow": "在下方输入新共享规则描述：",
        "enterNewSharingRulesDescription": "输入新共享规则描述",
        "enterSharingRulesName": "输入共享规则名称",

        "currentUsers":"当前用户", 
        "pendingUsers":"待处理用户", 
        "addRole":"添加角色", 
        "addTeam":"添加团队", 
        "addPermission":"添加权限", 
        "Re-invite":"重新邀请", 
        "relations":"关系", 
        "is-parent-of":"是...的父项", 
        "addRelation":"添加关系", 
        "deleteRelation":"删除关系", 
        "updateRelation":"更新关系", 
        "API_Keys":"API金钥", 
        "createNewKey":"创建新金钥", 
        "createAPIKey":"创建API金钥", 
        "APIs":"API", 
        "setAsMasterKey":"设置为主金钥", 
        "growth-annual": "增长-年度",  
        "business": "商业",  
        "website-intent-data": "网站意图数据",  
        "demo-identification": "演示识别",

        // services
        "serviceMenuOverview": "概览",
        "serviceMenuTeams": "团队",
        "serviceMenuPipeline": "管道",
        "serviceMenuAnalytics": "分析",
        "serviceMenuResources": "资源",
        "salesServices": "企业服务",
        "chooseYourTypeofServicesToStreamlineAndEnhanceYourBusinessOperations": "选择您的服务类型，以简化和增强您的业务运营",
        "dataPreparation": "数据处理",
        "salesAssistant": "销售助手",
        "appointmentSetting": "预约设置",
        "othersCustomService": "其他（自定义服务）",
        "requestService": "请求服务",
        "learnMore": "了解更多",
        "serviceRequestStatus": "服务请求状态",
        "noServiceRequestsYet": "尚无服务请求",
        "submitYourFirstRequestToUnlockThePower": "提交您的第一次请求，以解锁我们销售服务的力量！在此跟踪您的请求状态，提升您的销售工作。让我们开始吧！",
        "serviceTableItemName": "名称", 
        "serviceTableItemStatus": "状态", 
        "serviceTableItemType": "类型", 
        "serviceTableItemProgress": "进度",
        "serviceTableItemStart": "开始时间",
        "serviceTableItemEnd": "预计结束时间",
        "serviceTableItemPriority": "优先级",
        "addASubject": "添加主题",
        "created": "已创建",
        "closed": "已关闭",
        "paused": "已暂停",
        "working": "工作中",
        "completed": "已完成",
        "resuming": "恢复中",
        "reviewing": "审核中",
        "others": "其他",

        "data_preparation":"数据处理", 
        "sales_assistant":"销售助手", 
        "appointment_setting":"预约设置", 
        "appointment_booking":"预约预订", 
        "low":"低", 
        "normal":"正常", 
        "urgent":"紧急", 
        "high":"高", 
        "requiredCredits":"所需信用点数", 
        "serviceCreated":"已创建", 
        "QandARecord":"问答记录", 
        "serviceAttachments":"附件", 
        "discussionBoard":"讨论板", 
        "wannaAddSomething":"想添加一些东西吗？", 
        "serviceEdit":"编辑", 
        "serviceDelete":"删除", 
        "addDescription":"添加描述", 
        "DragDropor":"拖放或", 
        "chooseFile":"选择文件", 
        "toUpload":"上传",
        "ourDataPreparationService": "我们的数据处理服务提供全面的解决方案，旨在优化您的销售数据管理。我们专注于简化流程，使您能够增强现有数据集，通过数据清理确保准确性，并创建完全符合您需求的自定义列表。这种方法使您获得灵活性和效率，以有效管理您的数据。",
        "moreoverWithOurDedicatedDataManagement": "此外，通过我们专门的数据管理服务Pubrio，过程变得更加轻松。您可以无缝地附加文件并增强您的数据集，添加额外信息。Pubrio不仅提供增强功能，还可以根据您的具体标准创建全新的列表。这项个性化服务确保您的数据集精确符合您的目标和行业标准，让您掌控自己的数据环境。",
        "flexibilityToAttach": "附加现有列表的灵活性：或指示Pubrio根据指定标准查找新列表。",
        "comprehensiveDataEnrichment": "全面的数据增强：添加缺失的详细信息，增强数据集的完整性。",
        "dataCleansingServicesToEnsureAccuracy": "数据清理服务以确保准确性：包括删除重复项和错误修正，以确保准确性和质量。",
        "abilityToInstructPubrio": "指示Pubrio的能力：根据精心定义的标准策划全新的列表。",
        "tailoringDatasetsPrecisely": "精确调整数据集：使其与特定目标和行业标准对齐。",
        "dataEnrichmentWithExistingList": "使用现有列表进行数据增强：轻松通过将当前列表上传到Pubrio来增强您的列表。我们将填补缺失的详细信息，例如电子邮件地址和电话号码，以满足您的要求。例如，提供您的客户数据库，我们将用必要的信息更新它。",
        "customListGenerationIf": "自定义列表生成：如果您没有列表，让Pubrio为您查找一个。只需告诉我们您的标准，如职位或行业，我们将创建完美的列表。例如，如果您需要特定区域内顶级技术高管的名单，我们将为您处理。",
        "dataCleansingForExistingLists": "现有列表的数据清理：通过Pubrio的数据清理服务确保您的数据准确可靠。将您的列表发送给我们，我们将删除重复项并修正任何错误。例如，如果您有一份最近潜在客户的名单，我们将确保它为您的营销活动做好准备。",
        "empoweringYourSalesEeavors": "通过我们的销售助手服务，赋能您的销售努力，我们提供全面支持，涵盖多个关键领域，以确保您的外展和沟通策略精心调整以取得成功。无论您是想创作引人入胜的内容、无缝组织活动、开发量身定制的演示文稿，还是完善销售和沟通策略，我们专门的团队都在这里帮助您提升销售工作并提高品牌在市场上的存在感。",
        "craftingCompellingContent": "创作引人入胜的内容：有效的沟通是吸引受众注意力的关键。我们的团队擅长创作针对您的品牌和受众偏好的引人入胜内容。从引人深思的LinkedIn帖子到信息丰富的博客文章和引人注目的社交媒体更新，我们确保您的信息与目标市场共鸣。",
        "seamlessEventAndWebinarPreparation": "无缝的活动和网络研讨会准备：活动和网络研讨会是与受众互动和生成潜在客户的强大工具。我们的销售助手服务处理所有细节，从计划和推广到执行。无论是举办信息丰富的网络研讨会还是组织网络活动，我们都确保您的活动留下深刻印象。",
        "tailoredContentDevelopmentAndPresentation": "量身定制内容开发和演示：用引人入胜的演示和个性化的信息吸引受众。我们的团队专门创建视觉吸引人的演示文稿并制作针对特定受众细分市场的信息。无论您是在向投资者推介还是培养潜在客户，我们都确保您的内容脱颖而出。",
        "strategicSalesAndCommunicationFrameworks": "战略销售和沟通框架：通过战略指导和有效的沟通脚本简化销售流程。我们的销售助手服务为您的团队提供推动转化和促进有意义联系所需的工具和策略。",
        "craftingCompellingContent": "创作引人入胜的内容",
        "companyLinkedInPosts": "公司 LinkedIn 帖子（频率：每周或每月）",
        "personalLinkedInPosts": "个人 LinkedIn 帖子（频率：灵活，根据偏好和内容可用性）",
        "websiteBlogPosts": "网站博客文章（频率：通常每周或每两周）",
        "socialMediaPosts": "社交媒体帖子（频率：每日或根据内容策略）",
        "emailMarketingCampaigns": "电子邮件营销活动（频率：每周或每两周）",
        "seamlessEventAndWebinarPreparation": "无缝的活动和网络研讨会准备",
        "webinarAndWorkshopPreparation": "网络研讨会和工作坊准备",
        "networkingAndOfflineEvent": "网络和线下活动协调",
        "tailoredContentDevelopment": "量身定制的内容开发和呈现",
        "companyDeckPreparation": "公司简报准备（用于演示、推介等）",
        "highlyPersonalizedMessage": "高度个性化的消息创建（针对特定受众群体量身定制）",
        "strategicSales": "战略销售和沟通框架",
        "salesCallScripts": "销售电话脚本",
        "emailOutreachTemplate": "电子邮件外展模板",
        "salesChannelStrategies": "销售渠道策略",
        "ourAppointmentSettingService": "我们的预约安排服务旨在提升您的业务，促进关键联系，增强行业曝光，并培养有价值的潜在客户。我们专注于个性化的行业曝光、无缝的研讨会和网络研讨会协调、热络新潜在客户，以及对新受众的吸引性接触。",
        "individualIndustryAppointment":"个别行业预约曝光和反应", 
        "tailoredOutreach":"量身定制的外展策略针对行业内的关键决策者和影响者，确保您的品牌获得精准曝光。", 
        "strategicFollowups":"战略性跟进帮助我们评估潜在客户的反应、解决他们的疑虑，并促进更深入的互动。", 
        "eachInteraction":"每次互动都根据收件人的特征和偏好进行个性化设计，以最大化影响力。", 
        "seminarWebinarCoordination":"研讨会与网络研讨会协调", 
        "ourExpertTeamHandles":"我们的专业团队负责所有研讨会和网络研讨会的协调工作，从最初的规划到活动结束后的跟进。", 
        "weMeticulouslyPlan":"我们精心策划和执行每一场活动，展示您的专业知识，确保参与者获得流畅而引人入胜的体验。", 
        "byLeveragingAdvancedTechnologyPlatforms":"通过利用先进的技术平台，我们增强互动性和参与度，最大化活动的影响力。", 
        "newProspectWarmUp":"新潜在客户预热", 
        "proactiveEngagementStrategies":"采用主动参与策略来热身新潜在客户，为成功的商业对话铺平道路。", 
        "tailoredCommunicationStrategies":"部署量身定制的沟通策略，以建立良好的关系、信任感和对您产品的兴趣。", 
        "throughStrategicNurturing":"通过战略性培养，我们保持潜在客户的参与度，为转化做好准备，提高成功预约的机会。", 
        "newAudienceApproach":"新受众接触策略", 
        "weEmployInnovativeStrategies":"我们采用创新策略来接触新受众，扩大您的业务范围，超越传统渠道。", 
        "targetedOutreachCampaigns":"根据深入的受众研究和细分制定针对性的外展活动，以确保相关性和效果。", 
        "ourApproachIsContinuouslyOptimized":"我们的方法根据反馈和见解不断优化，确保我们在接触和吸引新受众方面始终走在前列。", 
        "individualIndustry":"个别行业会议预约及跟进", 
        "personalizedAppointmentSetting":"与行业利益相关者进行个性化预约安排，以促进商机。", 
        "strategicFollowupCommunication":"进行战略性跟进沟通以促进进一步参与，增强转化率。", 
        "comprehensivePlanning":"全面规划和执行活动，以确保每个细节都能达成预期效果。", 
        "utilizationOfAdvancedTechnologies":"利用先进技术增强互动性。",
        "newProspectWarmUp": "新潜在客户预热",
        "proactiveEngagementStrategy": "针对潜在客户的主动参与策略。",
        "customizedCommunication": "量身定制的沟通以保持兴趣。",
        "newAudienceApproach": "新受众接触策略",
        "innovativeOutreach": "为新受众设计的创新外展活动。",
        "continuousRefinement": "根据反馈和见解不断完善。",
        "customService": "定制服务",
        "tailoredToMeet":"我们的定制服务旨在满足您的独特需求，提供灵活性和适应性，以应对各种要求。无论您需要协助进行网络研讨会参与者追踪、线下活动推广、广告增强或其他专业任务，我们的专业团队都能高效且有效地帮助您实现目标。", 
        "webinarParticipantsTracking":"网络研讨会参与者追踪", 
        "comprehensiveTrackingOfWebinar":"全面追踪网络研讨会参与者，以分析参与程度并收集有价值的见解。", 
        "customizedReportsAndAnalytics":"量身定制的报告和分析，以评估网络研讨会的有效性并优化未来策略。", 
        "seamlessIntegration":"与现有系统无缝集成，以简化数据管理和报告。", 
        "offlineEventPromotion":"线下活动推广", 
        "strategicPlanning":"战略规划和执行线下活动推广活动，以最大化出席率和参与度。", 
        "utilizationOfTargetedMarketingChannels":"利用针对性的营销渠道和推广策略，有效接触目标受众。", 
        "creativeApproaches":"根据每个活动的独特特征量身定制创意方法，确保最大影响力和投资回报率。", 
        "adsEnhancement":"广告增强", 
        "optimizationOfAdCampaigns":"优化各平台上的广告活动，以提高可见性、参与度和转换率。", 
        "ABTesting":"A/B测试和性能分析，以识别最有效的广告创意、信息传递和目标策略。", 
        "continuousMonitoring":"持续监控和调整广告活动，以适应市场动态变化并最大化结果。", 
        "customizedSolutions":"定制解决方案", 
        "ourCustomService":"我们的定制服务超越预定义功能，旨在满足您的具体需求和偏好。", 
        "whetherYouNeedAssistance":"无论您需要数据分析、内容创建、战略规划或业务其他方面的协助，我们都能根据您的需求量身定制服务。", 
        "ourFlexibleApproach":"我们灵活的方法使我们能够与您密切合作，开发定制解决方案，实现可见成果并推动业务增长。", 
        "setupAndImplementation":"网络研讨会参与者追踪系统的设置和实施。", 
        "customizedReporting":"量身定制的报告和分析，以衡量网络研讨会的表现和观众参与度。", 
        "developmentAndExecution":"针对性的线下活动推广活动的开发和执行。", 
        "coordinationOfPromotionalActivities":"推广活动的协调，包括印刷材料、直邮和地方广告。", 
        "evaluationAndOptimization":"对数字平台上的广告活动进行评估和优化。", 
        "creativeDevelopment":"创意开发、目标细化和性能监控，以最大化投资回报率。", 
        "tailoredServices":"量身定制的服务，以满足特定需求和目标。", 
        "collaborationWithOurTeam":"与我们的团队合作，开发满足您独特需求的定制解决方案。", 
        "serviceGetStarted": "开始使用",
        "keyFeatures": "主要特点",
        "dataEnrichment": "数据增强",
        "dataCleansing": "数据清理",
        "customListGeneration": "自定义列表生成",
        "serviceOfferings": "服务项目",
        "introDataPreparation": "我们的数据处理服务提供全面的解决方案，旨在优化您的销售数据管理。我们专注于简化流程，使您能够增强现有数据 ...",
        "introSalesAssitant": "销售助手服务超越了一般的行政支持，专注于销售过程中不可或缺的任务。无论您是想创作引人入胜的内容 ...",
        "introAppointmentSetting": "这项服务采取个性化和实地的方法，根据提供的信息将客户与其目标受众联系，增强行业曝光，并培养 ...",
        "introOtherService": "'其他'服务允许客户通过提交有关其独特需求的详细信息来请求定制服务，提供灵活性和适应性，以应对各种要求 ...",

        "questionCommonPagePriorityLevelTitle": "您对此任务的优先级别是什么？", 
        "questionCommonPagePriorityLevelDesc": "说明您的任务优先级有助于我们及时满足您的需求。您可以在提交后更改它。", 
        "questionCommonPageTimelineTitle": "考虑订阅期限或时间表吗？", 
        "questionCommonPageTimelineDesc": "告诉我们您首选的时间框架以及计划开始的时间。", 
        "questionCommonPageServiceRequestTitle": "准备开始了吗？首先，给您的申请一个独特的名称", 
        "questionCommonPageServiceRequestDesc": "我们将用它来跟踪并在您的仪表板上更新进度。", 
        "questionCommonPageServiceDurationTitle": "您的服务期将持续多久？", 
        "quesitonCommonPageInvestmentBudget": "您每月在业务发展和项目上的投资预算是否超过HKD 12000？", 
        "questionCommonPageHearAboutUs": "您从哪里听说我们的？", 
        "questionCommonPageUploadTitle": "请在此处上传您的Excel/CSV文件以进行数据增强或数据清理", 
        "questionCommonPageUploadDesc": "如果您选择创建自定义列表生成，可以跳过或点击'下一步'而不上传。", 
        "questionDataPreparationPageInterestedDataOptimizationTitle": "您对哪些服务感兴趣以进行数据优化？", 
        "questionDataPreparationPageInterestedDataOptimizationDesc": "您需要在下一步中附加一个CSV文件，以选择数据增强或数据清理。", 
        "questionDataPreparationPageDataAttributesTitle": "选择您希望纳入文件的特定数据属性（如适用）", 
        "questionDataPreparationPageDataAttributesDesc": "选择一个或多个", 
        "questionDataPreparationPageNumberOfLeadsTitle": "您希望为您的业务生成或获取多少潜在客户？", 
        "questionDataPreparationPageNumberOfLeadsDesc": "指定数量或范围，以便我们为您定制服务。",
        "website_url": "网站网址", 
        "contact_first_name": "联系人名字", 
        "contact_last_name": "联系人姓氏", 
        "contact_title": "联系人职称", 
        "contact_location": "联系人地点", 
        "contact_work_email": "联系人工作电子邮件", 
        "contact_personal_email": "联系人个人电子邮件", 
        "contact_work_phone": "联系人工作电话", 
        "contact_personal_phone": "联系人个人电话", 
        "company_size": "公司规模", 
        "company_industry": "公司行业", 
        "company_location": "公司地点", 
        "company_founded_year": "公司成立年份", 
        "company_keywords": "公司关键字", 
        "company_ranking_in_global": "公司在全球的排名", 
        "company_financial_status": "公司财务状况", 
        "company_office_address": "公司办公地址", 
        "website_total_visit": "网站总访问量", 
        "company_funding_status": "公司融资状况",
        "slugServiceName": "服务名称", 
        "slugServiceDesc": "服务描述",
        "errEnterServiceName": "请输入服务名称",
        "errEnterDescription": "请输入服务描述",
        "errSelectAService": "请选择一项服务",
        "errSelectSpecificDataAttributes": "请选择特定数据属性",
        "errInputTheQuantity": "请输入数量",
        "errSelectPriorityLevel": "请选择您的优先级别",
        "errSelectTimeline": "请选择时间表",
        "errInputContent": "请输入内容",
        "errInvestmentBudget": "请选择是或否",
        "errServiceDuration": "请选择服务期限",
        "errHearAboutUs": "请选择来源",

        "placeHolderEnterServiceName": "输入服务名称", 
        "placeHolderEnterServiceDescription": "输入服务描述", 
        "placeHolderEnterListGeneration": "输入列表生成", 
        "placeHolder150TO250": "150 - 250", 
        "Submit": "提交", 
        "serviceDownload": "下载", 
        "serviceExample": "范例", 
        "forYourReference": "供您参考", 
        "yourFileNeedsToInclude": "您的文件需要至少包括网站或公司名称", 
        "includeAll": "包括所有", 
        "serviceNext": "下一步", 
        "serviceBack": "返回", 
        "data_enrichment": "数据增强", 
        "data_cleansing": "数据清理", 
        "custom_list_generation": "自定义列表生成", 
        "job_title": "职位名称", 
        "timeline_within_one_weeks": "时间范围在一周内", 
        "timeline_one_to_two_weeks": "时间范围一到两周内", 
        "timeline_two_to_four_weeks": "时间范围两到四周内", 
        "timeline_recurring": "定期时间范围", 
        "timeline_custom_range": "自定义时间范围",
        "hear_about_us_social_media": "社交媒体", 
        "hear_about_us_google": "Google", 
        "hear_about_us_online_article": "在线文章", 
        "hear_about_us_webinar": '网络研讨会', 
        "hear_about_us_referral": '推荐', 
        "hear_about_us_custom_input": '其他',
        "during_last_than_one_month":"少于一个月", 
        "during_one_to_three_months":"一到三个月", 
        "during_over_three_months":"超过三个月", 
        "during_decide_later":"稍后决定",
        "yes":"是", 
        "no":"否",
        "byCancelingYouCanEnsureThatNoTasksWillBeClosed":"通过取消，您可以确保没有任务将被关闭", 
        "areYouSureYouWantToCloseThisTask":"您确定要关闭此任务吗？", 
        "onceTheTaskIsClosed":"一旦任务关闭，将无法撤销，您将无法恢复它。", 
        "Close":"关闭", 
        "ourTeamIsWorkingOnThisTask": "我们的团队正在处理此任务",
        "pleaseProviceYourReasonForThisAction": "请提供您此行动的理由",
        "sendRequest": "发送请求",
        "OK": "确定",
        "areYouSureYouWantToDelete": "您确定要删除吗？",
        "proceedingWithDeletionWillResultInPermanentRemovalOfTheFile": "进行删除将导致文件永久删除。",
        "byCancelYouEnsureThatNoFileWillBeDeleted": "通过取消，您可以确保不会删除任何文件。",
        "areYouSureYouWantToDeleteThis": "您确定要删除这个吗？",
        "serviceMessage": "消息",
        "serviceFile": "文件",

        "proceedingWithDeletionWillResultInPermanentRemovalOfThisMessage": "进行删除将导致此消息永久删除。",
        "proceedingWithDeletionWillResultInPermanentRemovalOfThisFile": "进行删除将导致此文件永久删除。",
        "youEnsureThatNoMessagesWillBeDeleted": "通过取消，您可以确保不会删除任何消息。",
        "youEnsureThatNoFilessWillBeDeleted": "通过取消，您可以确保不会删除任何文件。",
        "serviceDeleteFail": "删除失败",
        "enterYourReason": "输入您的理由",
        "seriveStartDate": "开始日期",
        "serviceEndDate": "预计结束日期",
        "yourRequestHasBeenSent": "您的请求已发送",
        "sendUsYourFeedback": "发送您的反馈给我们",
        "weAreConstantlyImproving": "我们不断改进！分享您的反馈以塑造我们平台的未来。",
        "descriptionYourExperienceHere": "在此描述您的体验...",
        "additionallyYouCanAttachAnyScreenshots": "此外，您可以附加任何截图或文件以提供更多细节。",
        "sendFeedback": "发送反馈",
        "serviceResume": "恢复",
        "yourRequestHasBeenResumed": "您的请求已恢复",
        "yourRequestIsCurrentlyPaused": "您的请求目前已暂停",
        "serviceEdited": "已编辑",
        "theFileSizeCannotExceed50M": "文件大小不能超过50M",
        "activateTheNewVersionNow": "立即启用新版本",
        "feedbackSendSuccessfully": "反馈已成功发送",
        "feedbackSendFailed": "反馈发送失败",
        "autoSave": "自动保存",
        "serviceDescShowMore":"显示更多", 
        "serviceDescShowLess":"显示较少", 
        "members":"成员", 
        "shareTo":"分享至...", 
        "findTeamMembers":"寻找团队成员", 
        "select":"选择", 
        "whenBalanceFallsBelow":"当余额低于", 
        "defaultPaymentMethod":"默认付款方式", 
        "creditCardDetails":"信用卡详细信息", 
        "automaticallyTopUp":"自动充值", 
        "autoTopupWillAutomaticallyChargeYourPaymentMethodT":"自动充值将自动向您的付款方式收费。要禁用此功能，请保持此框未勾选。", 
        "enableAutoTopups":"启用自动充值", 
        "validThru":"有效期至", 
        "currentBalance":"当前余额", 
        "cardHolder":"持卡人姓名", 
        "gotIt":"明白了",
        "pleaseEnsureYourPaymentMethodIsUptodateAndThatYouA": "请确保您的付款方式是最新的，并且在启用之前您对此自动过程感到满意。",
        "thresholdCredits": "阈值：{creditLimit} 信用点数",
        "creditAmountCredits": "充值金额：{choosedTopUpCredit} 信用点数",
        "enablingAutoTopupsWillAutomaticallyAddCreditsToYou": "启用自动充值将在您的余额低于指定阈值时自动向您的账户添加信用点数。这可能会导致对您选择的付款方式进行自动收费。",
        "thisWillAutomaticallyAddCreditsToYourAccountWhenev": "{choosedTopUpCredit} 信用点数将在您的余额低于 {creditLimit} 信用点数时自动添加到您的账户。",
        "autoTopupNotice": "自动充值通知",

        "roleName": "角色名称", 
        "addUser": "添加用户", 
        "newRole": "新角色", 
        "userName": "用户名", 
        "joined": "已加入", 
        "thisRoleCurrentlyHasNoUsersAssignedToItStartAddingTeamMembersToDefineTheirResponsibilitiesAndStreamlineYourWorkflow":"此角色目前没有分配任何用户。开始添加团队成员以定义他们的责任并简化工作流程。",
        "noUsersAssigned": "未分配用户",
        "addUserToTeam": "添加用户到团队", 
        "user": "用户", 
        "sharingRuleGroup": "共享规则组", 
        "updateTeam": "更新团队", 
        "createANewTeam": "创建新团队", 
        "backToTeams": "返回团队", 
        "listOfSharingRules": "共享规则列表", 
        "sharingRules": "共享规则", 
        "manageSharingRules": "管理共享规则", 
        "CreateAndCustomizeSharingRulesToControlAccessForDifferentUsersClickTheButtonBelowToAddNewSharingRules":"创建和自定义共享规则以控制不同用户的访问权限。单击下面的按钮以添加新的共享规则。",
        "newSharingRules": "新共享规则",
        "exportLimit": "导出上限", 
        "customRolePermission": "自定义角色权限", 
        "teamManagement": "团队管理", 
        "API": "API", 
        "linkedinAccount": "LinkedIn 账户", 
        "perPageLimit": "每页上限", 
        "recordUploadLimit": "记录上传上限", 
        "creditRollover": "信用点数结转", 
        "quotaSharing": "配额共享", 
        "quotaAdjustment": "配额调整", 
        "add_On": "附加功能", 
        "topUpCredits": "充值信用点数", 
        "webVisitorIdentification": "网络访客识别",
        "dataExportCredit": "数据导出",
        "companySearchCredit": "公司搜索",
        "peopleSearchCredit": "潜在客户搜索",
        "emailRedeemCredit": "电子邮件搜索",
        "phoneNumberRedeemCredit": "电话号码搜索",
        "recordSelection": "记录选择",
        "portal": "平台",
        "queryLimitPerHour": "每小时查询上限",
        "queryLimitPerDay": "每日查询上限",
        "callLimitPerHour": "每小时请求上限",
        "callLimitPerDay": "每日请求上限",
        "callLimitPerMonth": "每月请求上限",
        "basicSequencing": "基本工作流程设置",
        "numberOfSequences": "工作流程数量",
        "numberOfMailboxesPerUser": "每个用户的邮箱数量",
        "numberOfLinkedinAccountPerUser": "每个用户的LinkedIn账户数量",
        "numberOfSlackAccountPerUser": "每个用户的Slack账户数量",
        "automatedFollowUp": "自动跟进",
        "advancedSequencing": "进阶工作流程设置",

        "abTesting":"A/B测试", 
        "multipleEmailTemplates":"多个电子邮件模板", 
        "reporting":"报告", 
        "emailOpenTracking":"电子邮件打开跟踪", 
        "emailClickTracking":"电子邮件点击跟踪", 
        "emailReplyTracking":"电子邮件回复跟踪", 
        "support":"支持", 
        "priorityEmailSupport":"优先电子邮件支持", 
        "companySelectionLimit":"公司选择上限", 
        "prospectSelectionLimit":"潜在客户选择上限", 
        "totalPageLimit":"总页面上限", 
        "pricingYear":"年", 
        "growth-annual":"初创计划", 
        "business-annual":"商务计划", 
        "pricingSearch":"搜索", 
        "pricingSearches":"搜索次数", 
        "pricingMonth":"月", 
        "topupCredit":"充值信用点数", 
        "perUserPerYear":"每位用户，每年\n按年计费", 
        "perUserPerMonth":"每位用户，每月\n按月计费", 
        "customCredits":"自定义信用点数", 
        "customSearches":"自定义搜索", 
        "queryLimitPerMonth":"每月查询上限", 
        "customized":"自定义", 
        "custom":"自定义 ", 
        "free":"免费计划", 
        "growth":"初创计划", 
        "business":"商务计划", 
        "organization":"定制方案", 
        "search":"数据搜索",

        // setting
        "pleaseSelectTheUserYouWantToAdd": "请选择您想添加的用户", 
        "pleaseSelectTheUserYouWantToDelete": "请选择您想删除的用户", 
        "pleaseSelectTheUserYouWantToAddPermission": "请选择您想添加权限的用户"
}
