export default {
        //login
        loading: 'Loading...',
        SignUp: 'Sign Up',
        LoginSuccess: 'Logged in successfully',
        LoginOut: 'Logged out successfully',
        email: 'Email',
        password: 'Password',
        logIn: 'Log In',
        WelcomeBack: 'Welcome Back',
        PleaseEnterYourEmailAndPassword: 'Please enter your email and password',
        CharactersRequired: '8+ characters required',
        keepMeSignedIn: 'Keep me signed in',
        forgetPassword: 'Forget password',
        dontHaveAnAccount: 'Do not have an account? ',
        signUpNow: 'Sign Up Now',
        AllRightsReserved: 'All Rights Reserved',
        privacyPolicy: 'Privacy Policy',
        and: 'and',
        termsOfService: 'Terms of Service',
        Name: 'Name',
        viewAll: 'view all',
        fold: 'fold',
        updateCreditCard: 'Update Credit Card',
        role: 'Role',
        noLimit: "No Limit",

        //signup
        company: 'Company',
        companyName: 'Company Name',
        signUp: 'Sign Up',
        alreadyHaveAnAccount: 'Already have an account? ',
        getStarted: 'Get started! Empower your business with our platform',
        signUpSuccess: 'Signed up successfully',


        //foget
        noWorries: 'No worries, enter your registered email, and we will send you reset instructions',


        //reset
        reset: 'Reset Password',
        forgotPassword: 'Forgot Password',
        RequestPasswordReset: 'Request Password Reset',
        backToLogin: 'Back to Login',
        verifyPassword: 'Verify Password',
        EnterANewPasswordFor: 'Enter a new password for example@gmail.com',
        ResetPassword: 'Reset Password',
        newPassword: 'New Password',

        //top-nav
        Home: 'Home',
        Search: 'Search',
        Lead: "Lead",
        Prospects: 'Prospects',
        filterYourSearchToFindHighlySpecific: 'Filter your search to find highly specific',
        groupsOfTargetedLeads: 'groups of targeted leads',
        filterYourSearchToFindPreciselyTargeted: 'Filter your search to find precisely targeted',
        listsOfCompanies: 'lists of companies',
        transformAnonymousWebsiteTrafficInto: 'Transform anonymous website traffic into',
        b2bLeadsWithRealtimeIdentification: 'B2B leads with real-time identification',
        discoverAndConnectWithPotentialBuyers: 'Discover and connect with potential buyers',
        activelySeekingYourSolutionToday: 'actively seeking your solution today',
        Intent_Signals: 'Intent',
        Website_Intent: 'Website Intent',
        Market_Intent: 'Market Intent',
        Sequence: 'Sequence',
        Tools: 'Tools',
        Services: 'Services',
        technologyLookup: 'Technology Lookup',
        domainLookup: 'Domain Lookup',
        emailVerification: 'Email Verification',
        upgrade: 'Upgrade',
        helpCenter: 'Help Center',
        contactUs: 'Contact Us',
        feedback: 'Feedback',
        hello: 'Hello',
        clickToChange: 'Click to change',
        yourProfile: 'Your Profile',
        currentUsage: 'Current Usage',
        upgradePlan: 'Upgrade Plan',
        integrations: 'Integrations',
        featureRequests: 'Feature Requests',
        change: 'change',
        en: 'English',
        cn: '中文（简）',
        tw: '中文（繁）',
        logout: 'Log Out',
        switchLanguage: 'Switched Language successfully',


        //not available
        ComingSoon: 'Coming Soon!',
        TheFeatureIscurrentlyUnder: 'The feature is currently under',
        development: 'development',
        weAppreciateYourPatienceAsWeWorkToImprove: 'We appreciate your patience as we work to improve',
        yourExperience: 'your experience',
        inTheMeantimeIfYouHaveAnyQuestionsOrNeed: 'In the meantime, if you have any questions or need',
        assistancePleaseContactOurSupportTeam: 'assistance, please contact our support team',
        contactSupport: 'Contact Support',
        ok: 'OK',
        thankYouForBeingAValuedUserOfOurPlatform: 'Thank you for being a valued user of our platform.',

        //search-company
        people: 'People',
        companies: 'Companies',
        clearAll: 'Clear All',
        searchProspects: 'Search...',
        //actions
        isFoundIn: 'Is found in ',
        isNotFoundWithinAnyLists: 'is not found within any lists',
        l: ' lists',
        remove: 'Remove',
        saveToLists: 'Save to Lists',
        DeleteFailed: 'Delete Failed',
        //delete dialog
        areYouSureYouWantToDeleteThisRecord: 'Are you sure you want to delete this record?',
        proceedingWithDeletionWillResultInPermanentRemovalOfTheList: 'Proceeding with deletion will result in permanent removal of the list',
        delete: 'Delete',
        byCancelingYouEnsureThatNoListsWillBeRemoved: 'By canceling, you ensure that no lists will be removed',
        //export dialog
        holdOnWereGeneratingYourExport: "Hold on, we're generating your export",
        UseThisButtonOnlyIfYouWishToCancelYourAction: 'Use this button only if you wish to cancel your action',
        //save search pop
        saveSearch: 'Save Search',
        newSaveSearch: 'New Save Search',
        UpdateSavedSearch: 'Update Saved Search',
        enterNewSearchName: 'Enter New Search Name',
        visibility: 'Visibility',
        manageWhoCanSeeYourSavedSearch: 'Manage who can see your saved search',
        private: 'Private',
        onlyYouCanSeeYourSavedSearch: 'Only you can see your saved search',
        everyoneCanSeeYourSavedSearch: 'Everyone can see your saved search',
        pinSavedSearch: 'Pin Saved Search',
        findSavedSearch: 'Find saved search',
        enterSavedSearchName: 'Enter saved search name',
        duplicate: 'Duplicate',
        update: 'Update',
        noSavedSearches: 'No Saved Searches',
        RemoveCreditCard: 'Remove Credit Card',
        enterATitleForYourCard: 'Enter a title for your card',
        enterYourCardNumber: 'Enter your card number',
        digit: '3-digit',
        provideYourCompleteAddress: 'Provide your complete address',
        selectYourCountry: 'Select your country',
        //add credits pop
        ClickTheButtonBelowToAddMoreCredits: 'Click the button below to add more credits!',
        PleaseEnterTheAmountYouLikeToTopUpToYourCredit: "Please enter the amount you'd like to top up to your credit",
        //companyLink\contactBtn\functionBtn\default
        more: 'More',
        verified: 'Verified',
        GetEmailSuccessfully: 'Got Email Successfully',
        CopySuccessfully: 'Copied Successfully',
        recorded: 'Recorded',
        implement: 'Implement',
        sequence: 'Sequence',
        of: 'of',
        toAssistYouEffectivelyWeRequireFurtherDetailsAbout: 'To assist you effectively, we require further details about your search preferences. Please utilize the filter options in the left panel to specify your desired criteria, enabling us to refine the search results and provide you with targeted individuals that meet your requirements.',

        //filter
        all: 'All',
        my: 'My',
        team: 'Team',
        lists: 'Saved Lists',
        employees: 'Employees',
        technologies: 'Technologies',
        socialmedia: 'Social Media',
        enterSocialMedia: 'Enter Social Media',
        noResultsFound: 'No results found',
        searchCompanies: 'Search Companies',
        filters: 'Filters',
        enterCompanies: 'Enter Companies',
        accountHqLocation: 'Account HQ Location',
        CityStateCountryZIP: 'City/State/Country/ZIP',
        enterLocations: 'Enter Locations',
        revenue: 'Revenue',
        chooseARangeOfRevenue: 'Choose a range of revenue',
        ofRevenueIsUnknown: 'of revenue is unknown',
        technology: 'Technology',
        category: 'Category',
        categories: 'Categories',
        enterCategories: 'Enter Categories',
        enterTechnologies: 'Enter Technologies',
        enterIndustries: 'Enter Industries',
        employee: 'Employee',
        predefinedRange: 'Predefined Range',
        ofEmployeesIsUnknown: 'of employees is unknown',
        enterNames: 'Enter Names',
        jobTitle: 'Job Title',
        searchForAJobTitle: ' Search for a Job Title',
        founded: 'Founded',
        chooseARangeOfFoundedTime: 'Choose a range of time',
        from: 'From',
        to: 'To',
        specialties: 'Specialities',
        enterSpecialties: 'Enter Specialties',
        location: 'Location',
        total: 'Total',
        department: 'Department',
        EnterDepartments: 'Enter Departments',
        JobTitle: 'Job Title',
        EnterJobTitles: 'Enter Job Titles',
        ManagementLevel: 'Management Level',
        EnterManagementLevels: 'Enter Management Levels',
        AccountHQ: 'Account HQ',
        ContactHQ: 'Contact HQ',
        departmentFunction: 'Department Function',
        EnterJobFunctions: 'Enter Job Functions',
        enterNamesAndPressTheEnterKey: 'Enter names and press the Enter key...',
        EnterJobTitlesAndPressTheEnterLKey: "Enter titles with ',' and press the Enter key...",
        pleaseSelectRange: 'Please select a range',


        //table
        selectAllCompanies: 'Select All Companies',
        selected: 'Selected',
        export: 'Export',
        industry: 'Industry',
        keywords: 'Keywords',
        companyLocation: 'Company Location',
        action: 'Action',
        PleaseEnterTheSelectedQuantity: 'Please enter the selected quantity',
        contact: 'Contact',
        Title: 'Title',
        selectThisPage: 'Select This Page',
        clearSelection: 'Clear Selection',
        selectNumberOfCompanies: 'Select number of companies',
        maxCompanies: 'Max companies',
        youCanOnlySelectAMaximumOf25Companies: 'You can only select a maximum of 25 companies.',
        UpgradeYourPlanNow: 'Upgrade your plan now!',
        viewPlans: 'View Plans',
        applySelection: 'Apply Selection',
        selectNumberOfProspects: 'Select number of prospects',
        maxProspects: 'Max prospects',
        youCanOnlySelectAMaximumOf25Prospects: 'You can only select a maximum of 25 prospects.',
        PleaseSelectTheDataToBeExported: 'Please select the data to be exported',
        overview: 'Overview',
        genericContacts: 'Generic Contacts',
        size: 'Size',
        contactLocation: 'Contact Location',
        addYourCreditCardInformation: 'Add your credit card information',


        //account
        addCreditCard: 'Add Credit Card',
        zip: 'Zip',
        country: 'Country',
        state: 'State',
        city: 'City',
        address: 'Address',
        billingInformation: 'Billing Information',
        yourTeams: 'Your Teams',
        cvc: 'CVC',
        year: 'year',
        month: 'month',
        expirationDate: 'Expiration Date',
        creditCardNumber: 'Credit Card Number',
        cardInformation: 'Card Information',
        title: 'Title',
        fullName: 'Full Name',
        yourDetail: 'Your Detail',
        creditsToUse: 'credits to use.',
        youHave: 'You have',
        download: 'Download',
        buyMoreCredits: 'Buy more Credits',
        description: 'Description',
        topUpCredits: 'Top Up Credits',
        cancel: 'cancel',
        date: 'Date',
        invoiceHistory: 'Invoice History',
        confirmNewPass: 'Confirm new pass',
        creditsUsed: 'Credits used',
        newPass: 'New pass',
        youHaveNotEnteredAnyCreditCardInformationYet: 'You have not entered any credit card information yet.',
        oldPass: 'Old pass',
        cardNumber: 'Card Number:',
        changePassword: 'Change Password',
        creditUsage: 'Credit Usage',
        typeTheNewTitle: 'Type the new title',
        save: 'Save',
        addMoreCredits: 'Add more credits',
        featureRequest: 'Feature Request',
        creditsMo: '{credit} Credits / mo',
        apiIntegrations: 'API Integrations',
        profileSubscription: "Profile Subscription",
        'additional-user-licence': "Additional User Licence",
        'agency-white-label': "Agency White Label",
        'api-extended-limit': "API Extended Limit",
        'profile-subscription': "Profile Subscription",
        creditsInYourPlan: 'Credits (In your Plan)',
        plugin: "Plugin",
        settings: 'SETTINGS',
        phone: 'Phone',
        '1User': '1 user',
        users: 'Users',
        billing: 'Billing',
        monthlyQueryLimit: 'Monthly Query Limit',
        price: 'Price',
        whatsIncluded: 'What\'\'s Included',
        givenName: 'Given Name',
        profile_teams: 'Profile & Teams',
        purchasePlan: 'Purchase Plan',
        accountOverview: 'ACCOUNT OVERVIEW',
        familyName: 'Family Name',
        dailyQueryLimit: 'Daily Query Limit',
        rateLimit: 'Rate Limit',
        youAreOnA: 'You are on a',
        PlanAndYourCreditsWillRefreshOn: 'plan and your credits will refresh on',
        过期: '',
        searchSettings: 'Search Settings...',
        plan: 'Plan',
        accountInfo: 'Account Info',
        creditCardInformation: 'Credit Card Information',
        apiUsage: 'API Usage',
        mo: '/mo',
        perUserPerMonth: 'per User Per Month',
        billedAnnually: 'Billed Annually',
        dailyQuery: 'Daily Query',
        monthlyQuery: 'MonthlyQuery',
        currentPlan: 'Current Plan',
        everythingInPlus: 'Everything In Plus',
        mostPopular: 'mostPopular',
        topUpToIncreaseYourCredits: 'Top up to increase your credits',
        addCredits: 'Add Credits',
        allPlansInclude: 'All Plans Include',
        Amount: 'Amount',
        credits: 'Credits',
        purchaseSuccess: 'Purchased credits successfully, your total credits are ',
        Loading: 'Loading',
        Selected: 'Selected',
        SelectPlan: 'Select Plan',
        currentPlan: "Current Subscription",
        upgradeSuccess: 'Plan switched successfully',
        purchaseFailed: 'Purchase failed',
        upgradeFailed: 'Plan switch failed',
        inviteSentSuccessfully: 'Invite sent successfully',
        DeleteSuccessfully: 'Deleted Successfully',
        AccountupdatedSuccessfully: 'Account updated successfully',
        AccountUpdateFailed: 'Account Updated Failed',
        PasswordUpdatedSuccessfully: 'Password updated successfully',
        PasswordUpdateFailed: 'Password Updated Failed',
        teamDetails: 'Team Details',
        teamName: 'Team Name',
        changeTeam: 'Change Team',
        clickToChangeTeam: 'Click to change team',
        changeTeamSuccessfully: 'Changed team successfully',
        status: 'Status',
        Action: 'Action',
        pending: 'Pending',
        current: 'Current',
        AreYouSureYouWantToDeleteThisCreditCard: 'Are you sure you want to delete this credit card?',
        AreYouSureYouWantToDeleteThisTeamMember: 'Are you sure you want to delete this team member?',
        PasswordCannotBeEmpty: 'Password cannot be empty',
        newUser: 'New User',
        deleteUser: 'Delete User',
        nickname: 'Nickname',
        admin: 'Admin',
        inviteYourTeam: 'Invite Your Team',
        empowerEveryoneToGetMoreDealsDonefaster: 'Empower everyone to get more deals done faster',
        addMultipleEmailAddressesSeparatedByCommas: 'Add multiple email addresses separated by commas',
        sendInvites: 'Send Invites',
        FirstName: 'First Name',
        LastName: 'Last Name',
        timezone: 'Timezone',
        contactNo: 'Contact No',
        controlYourTeamMembersAndTheirAccountAccessRightHere: 'Control your team members and their account access right here.',
        pleaseEnterYourCurrentPasswordToChangeYourPassword: 'Please enter your current password to change your password.',
        updatePassword: 'Update Password',
        oldPassword: 'Old Password',
        confirmNewPassword: 'Confirm New Password',
        InviteYourTeam: 'Invite Your Team',
        empowerEveryoneToGetMoreDealsDoneFaster: 'Empower everyone to get more deals done faster.',
        enterEmailAddressesMultipleCommaseparated: 'Enter email addresses (multiple comma-separated)',
        invoice: 'Invoice',
        accountAdmin: 'Account Admin',
        setAsDefault: 'Set as Default',
        bankName: 'Bank Name',
        expiry: 'Expiry',
        viewAllYourPreviousInvoicesHere: 'View all your previous invoices here.',
        BillingHistory: 'Billing History',
        updateYourCreditCardInformation: 'Update your credit card information.',
        ManageCreditCard: 'Manage Credit Card',
        CardNumber: 'Card Number',
        billingAddress: 'Billing Address',
        yourCreditsWillRefreshOn: 'Your credits will refresh on',
        yourCreditsWillBeChargeOn: "Your credits will be charge on",
        "被收取": "",
        perYear: '/year',
        perMonth: '/month',
        monthlyCredit: "Monthly Credit",
        Usage: "Usage",
        features: 'Features',
        credits_mo: 'Credits/mo',
        credits_year: 'Credits/year',
        _mo: '/mo',
        seeOtherPlan: 'See Other Plans',
        subscriptionTiers: 'Subscription Tiers',
        experiencePricingThatsEasyToUnderstandAndAdaptable: "Experience pricing that's easy to understand and adaptable to your needs.",
        monthlyBilling: 'Monthly Billing',
        annualBilling: 'Annual Billing',
        forPlanCustomizationContactOurSalesTeam: 'For plan customization, contact our sales team',
        billedAnnually_user: 'Billed Annually/user',
        seePlanComparison: 'See Plan Comparison',
        topUp: 'Top Up',
        changeTeamNameFailed: 'Change team name failed',

        // purchaseCreditsAtTheRateOf100PerCreditCustomize: 'Purchase credits at the rate of $1.00 per credit. Customize your credit bundle based on your needs. ',
        totalPrice: 'Total Price',
        credit: 'Credit',
        collapse: 'Collapse',
        expand: 'Expand',

        // Intent
        intentOverview: "Overview",
        intentAnalytic: "Analytic",
        intentFilter: "Filter",
        searchCompany: 'Search companies, website, linkedin...',
        savedCompanyWebsite: 'Saved company website',
        companiesFound: 'Companies found',
        companyFound: 'Company found',
        filterList: {
                technologies: 'Technology',
                time: 'Time',
                locations: 'Account Location',
                verticals: 'Industry',
                pageview_greater_than: 'Pageview',
                session_greater_than: 'Session',
                advertisements: 'Advertisement',
                company_groups: 'Lists',
                start_date: 'Start Date',
                end_date: 'End Date',
                search_term: 'Search Term',
        },
        employeeFilterList: {
                industry: 'Industry',
                technology: 'Technology',
                jobTitle: 'Job Title',
                company: 'Company',
                list: 'Lists',
                name: 'Name',
                size: 'Size',
                location: 'Location',
                jobFunction: 'Job Function'
        },
        selectLists: 'Select Lists...',
        chooseARangeOfTime: 'Choose a range of time',
        greaterThan: 'Greater than',
        selectAdvertisement: 'Select Advertisement',
        // intentIndustry: 'Industry',
        tabActivity: 'Activity',
        tabOverview: 'Overview',
        tabEmployees: 'Employees',
        tabContacts: 'Generic Contacts',
        tabTechnologies: 'Technologies',
        intentStatistic: 'Statistic',
        intentCampaign: 'Campaign',
        newVisitors: 'New visitors',
        recurringVisitors: 'Recurring Visitors',
        mediocreLead: 'Mediocre lead',
        sessionsTracked: 'Sessions tracked',
        sessionTracked: 'Session tracked',
        lastSeenOn: 'Last seen on ',
        firstSeenOn: 'First seen on ',
        viewTrackedWebSession: 'View Tracked Web Session',
        webSession: 'Web Session',
        client: 'CLIENT',
        timeSpent: 'TIME SPENT',
        pageviews: 'Page Views',
        pageview: 'Page View',
        referrer: 'REFERRER',
        sessions: 'Sessions',
        session: 'Session',
        visited: 'Visited',
        AM: 'AM',
        PM: 'PM',
        companyDetails: 'Company Details',
        overviewList: {
                location: 'Location',
                industry: 'Industry',
                founded: 'Founded',
                ranking: 'Ranking',
                size: 'Size',
                revenue: 'Estimated Revenue',
                keywords: 'Keywords',
        },
        advancedSearch: 'Advanced Search',
        jobFunction: 'Job Function',
        employeeLocation: 'Location',
        concatLocation: 'Contact Location',
        contactEmail: 'Email',
        categoryAndTechnology: 'Category and Technology',
        firstDetected: 'First Detected',
        lastDetected: 'Last Detected',
        saved: 'Saved',
        addToContactList: 'Add to Contact List',
        noCampaignIdentified: 'No Campaign Identified',
        copyEmail: 'Copy Email',
        willBeSavedToTheFollowingSelectedLists: 'will be saved to the following selected lists:',
        addToLists: 'Add to Lists',
        noDataToDisplay: 'No Data to Display',
        intentFounded: 'FOUNDED',
        intentIndustry: 'INDUSTRY',
        intentRevenue: 'REVENUE',
        intentSize: 'SIZE',
        applyFilters: 'Apply Filters',
        youCanEditTheseFilters: 'You can edit these filters anytime to refine your results',
        installB2BDirectLeadFinder: 'Install B2B Direct Lead Finder on your site',
        addSite: 'Add Site',
        areYouSureYouWantToRemoveThisWebsite: 'Are you sure you want to remove this website?',
        Cancel: 'Cancel',
        installB2BLeadFinder: 'Install B2B Lead Finder',
        siteUrl: 'Site Url',
        copyToClipboard: 'Copy to clipboard',
        pleaseSelectAContactFirst: 'please select a contact first',
        pasteCodeBelowIntoTheHead: 'Paste the code below into the',
        ofEveryPageYouWishToTrackVisitorOn: 'of every page you wish to track visitors on.',
        forWordpressWeRecommendInstalling: 'For Wordpress, we recommend installing the',
        pluginFromStefanoLissa: 'plugin from Stefano Lissa.',
        afterInstallingAndActivating: 'After installing and activating the plugin, navigate to',
        inYourWordPressDashboard: 'in your WordPress dashboard.',
        youWillSeeANumberOfTabs: "You’ll see a number of tabs in the plugin’s interface. Make sure you're in the default 'Head and footer' tab, then copy & paste the following code snippet:",
        settingHeaderAndFooter: 'Settings → Header and Footer',
        dontForgetToClearYourCache: "Don't forget to clear your cache",
        ifYouAreUsingAnyCaChing: 'If you are using any caching plugins such as',
        orSimilar: "or similar, make sure you clear the cache after installing the code snippet!",
        GTMshouldAlreadyBeInstalled: 'Google Tag Manager should already be installed on your site before you follow these instructions',
        ifYouDontHaveGTM: "If you don't have Google Tag Manager installed yet you can find out how in the ",
        GTMsupportArticleAboutInstall: 'Google Tag Manager support article about installing it',
        signInToGTMAndClick: 'Sign in to Google Tag Manager, and click on the Add a New Tag button. Next,',
        clickWhereItSays: 'click where it says',
        chooseATagTypeToBeginSetup: ' Choose a tag type to begin setup',
        andSelect: ' and select ',
        fromTheList: 'from the list. Then, Copy & Paste the code following code into the text field:',
        nextClick: 'Next, click ',
        giveTheTagAName: '. Give the tag a name and then click the Save button.',
        dontForgetToPublishYourChange: "Don't forget to publish your changes",
        afterCreateTheCustom: "After creating the Custom HTML tag, don't forget to ",
        yourChanges: ' your changes!',
        submitAndPublish: 'Submit and Publish',
        copySuccessfully: 'Copied successfully',
        addToCompanyList: 'Add to company list',
        addToList: 'Add to list',
        enterOrCreateList: 'Enter or create lists',
        installCode: 'Install Code',
        wordpress: 'Wordpress',
        googleTagManager: 'Google Tag Manager',
        noVisitorsYet: 'No Visitors Yet',
        onceTheInsightsRollIn: "Once the insights roll in, you'll unlock valuable information about your visitors and more",
        noTechAreFound: 'No technologies are found',
        updateSuccessfully: 'Updated successfully',
        enterJobFunction: 'Enter Job Function',
        pinSuccessfully: 'Pinned successfully',
        cancelPinSuccessfully: 'Unpin successfully',
        adsName: 'ads name',
        utmTerm: "utm term",
        utmCampaign: "utm campaign",
        utmSource: "utm source",
        utmMedium: "utm medium",
        enterNumber: "Enter Number",
        enterName: "Enter Name",
        enterJobTitle: "Enter Job Title",
        areYouSureYouWantToRemovePeopleOfThisGroup: 'Are you sure you want to remove people of this group',
        areYouSureYouWantToRemoveCompanyOfThisGroup: 'Are you sure you want to remove company of this group',
        byCancelingYouEnsureThatNoPeopleWillBeRemoved: 'By canceling, you ensure that no people will be removed',
        byCancelingYouEnsureThatNoCompanyWillBeRemoved: 'By canceling, you ensure that no company will be removed',
        howToGetStarted: 'How to get started',
        Data: 'Data',
        deactivateWebsite: 'Deactivate Website',
        areYouCertainAboutDeactivatinYourWebsite: 'Are you certain about deactivating your website? Keep in mind that this would result in not receiving any potential company activities.',
        byCancelingYouWillContinueToActivateAndUse: 'By canceling, you will continue to activate and use this tool',
        yourTrialHasEnded: 'Your trial has ended',
        reactivateNow: "Reactivate Now",
        yourWebsiteHasBeenAutomaticallyInactivated: 'Your website has been automatically inactivated. Would you like to reactivate this tool to continue monitoring the data?',
        Inactivated: 'Inactivated',
        onTrial: 'on trial',
        Active: 'Active',
        youAreOnTrail: 'You are on a 7-day trial',
        yourTrailHasEnded: 'Your trial has ended',
        createNewWebIntent: 'Create New Web Intent',
        clickOnTheButton: '1. Click on the (+) button',
        typeInUrlOrWebAddress: '2. Type in URL or web address',
        theTrialWillActivate: 'The 7-day trial will activate upon checking this box',
        pleaseEnterASiteUrl: 'Please enter a site url',
        noMoreData: 'no more data',
        pleaseEnterANewName: 'please enter a new name',
        pleaseSelectASearchName: 'please select a seach name',
        clickToGetEmail: 'click to get email',
        emailIsNotAvailable: 'email is not available',
        enterOrCreateLists: 'Enter or create lists',
        viewMore: 'view more',
        removeFromContactList: 'Remove from Contact List',
        willBeRemovedFromTheFollowingLists: 'will be removed from the following lists',
        removeFromLists: 'Remove from Lists',
        pleaseSelectOrCreateAList: 'please select or create a list',
        chooseATriggerToMakeThisTagFire: 'Choose a trigger to make this tag fire...',
        youWontBeChargedYet: 'You won’t be charged yet',
        youDontHaveEnoughCredits: "You don't have enough credits",
        wouldYouLikeToAdd50Credits: " Would you like to add 50 credits (the minimum recharge amount) to proceed with sending the email?",
        ThisEmailRequire: 'This email requires',
        TheseEmailsRequires: 'These emails require',
        intentCredit: 'credit',
        intentCredits: 'credits',
        // analytic
        showNumber: 'show number',
        Website: 'Website',
        b2bTraffic: 'B2B Traffic',
        b2bReturningVsNew: 'B2B Returning VS New Visitors',
        visitors: 'Visitors',
        totalView: 'Total View',
        totalSessions: 'Total Sessions',
        operatingSystem: 'Operating System',
        browser: 'Browser',
        device: 'Device',
        companySize: 'Company Size',
        topIndustry: 'Top Industries',
        topCountries: 'Top Countries',
        topReferrals: 'Top Referrals',
        new: 'New',
        returning: 'Returning',
        countriesStatistics: 'Countries Statistics',
        topCompaigns: 'Top Campaigns',
        topPagesByVisits: 'Top Pages by Visits',
        topPagesByEngagement: 'Top Pages by Engagement',
        Referrals: 'Referrals',
        Pages: 'Pages',
        avgTime: "Avg Time",
        totalCompanies: 'TOTAL COMPANIES',
        b2bPageViews: 'B2B PAGE VIEWS',
        TotalSessions: 'TOTAL SESSIONS',
        viewCompanyList: 'View Companies List',
        noDiscussionsYet: "No Discussions Yet",
        useThisSpaceToAskQuestions: "You can use this space to ask questions, get updates, and discuss details directly with us",
        // mycontact
        prospects: 'Prospects',
        list: 'List',
        view: 'View',
        add: 'Add',
        newList: 'New List',
        savedLists: 'Saved Lists',
        searchList: 'Search list name',
        owner: 'Owner',
        records: 'Records',
        actions: 'Actions',
        lastModified: 'Last Modified',
        name: 'name',
        enterAListName: 'Enter a list name',
        pleaseSelectAListFirst: 'please select a list first',
        saveSuccessfully: 'Saved Successfully',
        pleaseEnterAListName: 'please enter a list name',
        editList: 'Edit List',
        fromThisList: 'from this list. Are you sure you want to continue',
        areYouSureYouWantToChange: 'Are you sure you want to change the list type to',
        // yes: 'Yes',
        noListCreated: 'No List Created',
        enhanceTheOrganization: 'Enhance the organization of your contacts and accounts within your target market. After creating a list, they will appear on this page.',
        createSuccessfully: 'Created Successfully',
        removeSuccessfully: 'Removed Successfully',
        createNewList: 'Create New List',
        groupTeam: 'Team',
        groupPersonal: 'Personal',
        edit: 'Edit',
        areYouSureYouWantToDeleteTheList: 'Are you sure you want to delete the list?',
        proceedingWithDeletion: 'Proceeding with deletion will result in permanent removal of the list.',
        byCanceling: 'By canceling, you ensure that no lists will be removed',
        byCancelingYouEnsureThatNo: 'By canceling, you ensure that no',
        willBeRemoved: 'will be removed',
        groupPeople: 'people',
        groupCompany: 'company',
        myList: 'My List',
        teamList: "Team's List",
        resetAssured: "Rest assured, you have the flexibility to switch between different types as needed.",
        wontBeChanged: "By canceling, you ensure that this list type won't be changed",
        priority: 'Priority',
        proceedingWithDeletionWillResultInPeopleDelete: 'Proceeding with deletion will result in permanent removal of the people',
        proceedingWithDeletionWillResultInCompanyDelete: 'Proceeding with deletion will result in permanent removal of the company',
        areYouSureYouWantToRemoveCompanyFromList: '1',
        areYouSureYouWantToRemovePeopleFromList: '2',
        groupExisted: 'group already exists',
        sorryTheListIsCurrrntlyOpen: 'Sorry, the list is currently open. Are you sure you want to delete it?',
        listName: 'Name',
        pleaseEnterAValidSiteUrl: 'Please enter a valid site url',

        // market-intent
        comingSoon: 'COMING SOON',
        weAreCurrentlyWorkingOnThisFeature: 'We are currently working on this feature and will launch soon',
        stayConnectedWithUs: 'Stay connected with us through our social media channels to receive updates.',
        selectAWebsite: 'Select a website',

        // sequence
        Sequences: 'Sequences',
        label: 'Label',
        template: 'Template',
        folder: 'Folder',
        single: 'Single',
        group: 'Group',
        steps: 'Steps',
        sizes: 'Sizes',
        time: 'Time',
        //email
        SendATestEmail: 'Send a Test Email',
        Categories: 'Categories',
        addToCategory: 'Add to Category',
        EnterOrCreateCategories: 'Enter or create categories',
        addSuccessfully: 'Added Successfully',
        Channels: 'Channels',
        emails: 'Emails',
        enterChannel: 'Enter Channel',
        CategoryName: 'Category Name',
        PleaseEnterANewCategoryName: 'Please enter a new category name:',
        EnterANewCategoryName: 'Enter a new category name',
        Steps: 'Steps',
        Actions: 'Actions',
        Time: 'Time',
        From: 'From',
        To: 'To',
        CancelSequenceCreation: 'Cancel Sequence Creation',
        AreYouSureYouWantToCancelTheSequenceCreationProcess: 'Are you sure you want to cancel the sequence creation process?',
        SaveAsDraft: 'Save as Draft',
        CancelSequence: 'Cancel Sequence',
        ByCancelingAllSettingsWillBeLost: "By canceling, all the settings and progress you've made will be lost.",
        ImportContacts: 'Import Contacts',
        PleaseUploadACSVFileByDraggingAndDroppingItHere: 'Please upload a CSV file by dragging and dropping it here',
        ChooseAFile: 'Choose a file',
        CSVFileFields: 'CSV File Fields',
        ReplyFields: 'Reply Fields',
        continue: 'Continue',
        Contacts: 'Contacts',
        Back: 'Back',
        Import: 'Import',
        Imported: 'Imported',
        Skipped: 'Skipped',
        Done: 'Done',
        NewCustomField: 'New Custom Field',
        EnterNewLabel: 'Enter new label',
        EnterNewType: 'Enter new type',
        removeRecorded: 'Remove Recorded',
        YouHaveUnsavedChanges: 'You have unsaved changes',
        LeavingDiscard: 'Leaving this page will discard any unsaved content. Are you sure you want to continue?',
        StayPage: 'Stay on Page',
        LeavePage: 'Leave Page',
        NewCategory: 'New Category',
        Create: 'Create',
        UnlockingSequence: 'Unlocking this sequence enables you to make modifications',
        ModifyingLeadDeselection: 'Modifying this content will lead to the deselection of the current template version',
        Modify: 'Modify',
        CancelingPreserveCurrent: 'Canceling will preserve the current version',
        rename: 'Rename',
        CreatNewLabel: 'Create New Label',
        NewLabel: 'New Label',
        PleaseEnterANewLabelName: 'Please enter a new label name:',
        EnterANewLabelName: 'Enter a new label name',
        SequenceName: 'Sequence Name',
        PleaseProvideNameForNewSequence: 'Please provide a name for your new sequence',
        EnterNewSequenceName: 'Enter new sequence name',
        SetUpEmail: 'Set up an email account for sending',
        NewSchedule: 'New Schedule',
        PleaseEnterANewScheduleName: 'Please enter a new schedule name:',
        EnterNewScheduleName: 'Enter new schedule name',
        AddNewTemplate: 'Add New Template',
        RemoveFromTemplate: 'Remove From Template',
        deleteSuccess: 'Deleted successfully',
        removeSuccess: 'Removed successfully',
        GroupName: 'Group Name',
        PleaseEnterNewGroupName: 'Please enter new group name',
        EnterNewGroupName: 'Enter new group name',
        TemplateName: 'Template Name',
        PleaseEnterANewTemplateName: 'Please enter a new template name',
        EnterNewTemplateName: 'Enter new template name',
        renameSuccessfully: 'Renamed Successfully',
        CreateSuccessfully: 'Created Successfully',
        addToGroup: 'Add to Group',
        SelectChannelForNewTemplate: 'Select channel for new template',
        Role: 'Role',
        Account: "Account",
        customFields: 'Custom Fields',
        contactsToStartEngagingWithThem: 'contacts to start engaging with them.',
        thisSequenceDoesntHaveAnyProspectsOrLeadsYetConsid: 'This sequence doesn\'\'t have any prospects or leads yet. Consider adding',
        noProspectsAdded: 'No prospects added',
        deliveryCurrentlyNoMessagesHaveBeenSentThroughTheS: 'delivery. Currently, no messages have been sent through the sequence.',
        ensureEfficientCommunicationByMonitoringYourSequen: 'Ensure efficient communication by monitoring your sequence\'\'s message',
        noMessagesHaveBeenSent: 'No messages have been sent',
        yourNeedsGetStartedByClickingNewSequence: 'your needs. Get started by clicking [+ New Sequence]',
        beginBuildingYourSalesStrategiesByDesigningSequenc: 'Begin building your sales strategies by designing sequences tailored to',
        noSequencesCreatedYet: 'No Sequences Created Yet',
        leadActions: 'Lead actions',
        leadInformation: 'Lead information',
        thisGroupHasNoTemplatesYet: 'This group has no templates yet',
        key: '{expr}',
        copyContent: 'Copy content',
        default: 'Default',
        selectVersion: 'Select version',
        subject: 'Subject',
        searchTemplatesfolders: 'Search templates/folders',
        send: 'Send',
        subject2: 'Subject:',
        enterASubjectOrLeaveItBlankToContinueInTheSameThre: 'Enter a subject or leave it blank to continue in the same thread',
        chooseYourChannel: 'choose your channel',
        kl: 'KL',
        addContacts: 'Add Contacts',
        addContactsToSequence: 'Add contacts to sequence',
        addremoveCustomContactField: 'Add/Remove Custom Contact Field',
        contactCustomFields: 'Contact Custom Fields',
        type: 'Type',
        typePhoneNumber: 'Type phone number',
        addAPhoneNumber: 'Add a Phone Number',
        fieldType: 'Field Type',
        fieldLabel: 'Field Label',
        skipTheFollowingNationalHolidaysLaborDayIndependen: 'Skip the following national holidays: Labor Day, Independence Day, Memorial Day, Thanksgiving, Christmas Eve, Christmas, New Year\'\'s Day',
        checked: 'checked',
        useTheContactsLocalTimeZoneInsteadOfTheSchedulesTi: 'Use the contact\'\'s local time zone instead of the schedule\'\'s time zone, if the contact contains location data.',
        definePostreplyOrMeetingActionAndSequenceCompletio: 'Define post-reply or meeting action and sequence completion criteria',
        stopRepliesHandling: 'Stop Replies Handling',
        timeZone: 'Time Zone:',
        country2: 'country',
        setMaxInitialEmailsDelaysEnabledisableOpensAndLink: 'Set max initial emails, delays, enable/disable opens and link tracking',
        configuration: 'Configuration',
        additionalInformation: 'Additional Information',
        connectALinkedinAccount: 'Connect a linkedin account',
        newLinkedinAccount: 'New LinkedIn account',
        feelFreeToRevisitAnyOfThePreviousStepsAtAnyTimeToR: 'Feel free to revisit any of the previous steps at any time to review or update your information before you \'\'Launch\'\' the sequence.',
        template2: 'Template:',
        next: 'Next',
        jacquelineLeung: 'Jacqueline Leung',
        settings2: 'settings',
        editThisTemplateScheduleFromThe: 'Edit this template schedule from the',
        typeEmail: 'Type Email',
        templateScheduling: 'Template Scheduling',
        launch: 'Launch',
        linkedin: 'Linkedin',
        addAEmail: 'Add a Email',
        addEmailAccount: 'Add email account',
        addStep: 'Add Step',
        newEmailAccount: 'New Email account',
        craftYourLeadgenerationJourneyWithANewSequenceDefi: 'Craft your lead-generation journey with a new sequence! Define your target audience, messages, and actions to create effective lead generation sequences.',
        craftYourCampaign: 'Craft Your Campaign',
        tailorYourOwnHoursToSuitSpecificSchedulingNeeds: 'Tailor your own hours to suit specific scheduling needs.',
        personalizedScheduling: 'Personalized Scheduling',
        requiredInformation: 'Required Information',
        chooseEmailAccountsForSendingCampaignMessages: 'Choose email accounts for sending campaign messages',
        accounts: 'Accounts',
        enterSequenceName: 'Enter sequence name',
        thisPageEmpowersYouToTailorYourSchedulingSequenceS: 'This page empowers you to tailor your scheduling sequence settings to match your preferences',
        schedule: 'Schedule',
        useThisOptionToAddContactsToTheSequenceYouHaveCrea: 'Use this option to add contacts to the sequence you have created.',
        addProspects: 'Add Prospects',
        save2: 'save',
        days: 'days',
        days2: 'days',
        within: 'within',
        opened: 'Opened',
        day: 'day',
        day2: 'day',
        waitFor: 'Wait for',
        sendTestEmailToMe: 'Send test email to me',
        create: 'Create',
        enterANameForCustomContactField: 'Enter a name for custom contact field',
        a: 'A',
        byCancelingYouEnsureThatNoStepsWillBeDeleted: 'By canceling, you ensure that no steps will be deleted',
        to2: 'to',
        enterNameForNewVersion: 'Enter name for new version',
        activated: 'Activated',
        currentVersion: 'Current Version',
        setTheNewVersionDefaultNow: 'Set the new version default now',
        enterNewName: 'Enter new name',
        newVersion: 'New Version',
        dontForgetToSetTheNewVersionDefaultOnceYouveFinish: 'Don\'\'t forget to set the new version default once you\'\'ve finished modifying it or',
        theDeletionOfThisConditionWillAlsoRemoveAllSubsequ: 'The deletion of this condition will also remove all subsequent steps',
        pleaseEnterANewName2: 'Please enter a new name:',
        aNewVersionOfTheSequenceHasBeenCreated: 'A new version of the sequence has been created.',
        youCanEitherEditTheCurrentVersionOrSaveItAndCreate: 'You can either edit the current version or save it and create a new one.',
        areYouSureYouWantToProceed: 'Are you sure you want to proceed?',
        newVersionName: 'New Version Name',
        sequenceStart: 'Sequence start',
        chooseActions: 'choose actions',
        chooseSteps: 'choose steps',
        enterChannel2: 'enter Channel',
        chooseCategories: 'choose categories',
        htmlMode: 'HTML Mode',
        considerBroadeningYourSearchCriteria: '- Consider broadening your search criteria.',
        checkYourSpellingAndMakeSureThereAreNoTypos: '- Check your spelling and make sure there are no typos.',
        tryUsingDifferentKeywordsOrPhrases: '- Try using different keywords or phrases.',
        noResultsFoundForYourSearchCriteria: 'No results found for your search criteria',
        fromScratch: 'From Scratch',
        sender: 'Sender',
        finished: 'Finished',
        inProgress: 'In Progress',
        fromTemplate: 'From Template',
        newSequence: 'New Sequence',
        nameOwner: 'Name owner',
        sort: 'Sort',
        ownedBy: 'Owned by',
        starredOnly: 'Starred Only',
        starred: 'Starred',
        box2: 'Box 2',
        box1: 'Box 1',
        add2: '+ Add',
        lastSynced: 'Last Synced',
        hourlyLimit: 'Hourly Limit',
        dailyLimit: 'Daily Limit',
        account: 'account',
        skipTheFollowingNationalHolidaysLaborDayIndependen2: 'Skip the following national holidays: Labor Day, Independence Day, Memorial Day, Thanksgiving, Christmas Eve, Christmas, New Year\'\'s Day',
        useTheContactsLocalTimeZoneInsteadOfTheSchedulesTi2: 'Use the contact\'\'s local time zone instead of the schedule\'\'s time zone, if the contact contains location data.',
        saveChanges: 'Save changes',
        emailSignature: 'Email signature',
        reset2: 'Reset',
        pleaseNoteTheValueShouldBe0OrEmails: 'Please note the value should be > 0 or < {expr}',
        youHaveNoScheduledTemplateYet: 'You have no scheduled template yet',
        discoverPremiumOptions: 'Discover Premium Options',
        mailbox: 'Mailbox',
        sendingLimits: 'Sending limits',
        connect: 'Connect',
        joinOurCommunityOfPremiumUsersTransformingEmailEng: 'Join our community of premium users transforming email engagement and achieving outstanding sales outcomes through Pubrio.',
        enterYourEmailAddress: 'Enter your email address',
        toJustMailbox: 'to just {mailNum} mailbox',
        yourCurrentBasicPlanAllowsAccess: 'Your current Basic plan allows access',
        setAsDefault2: 'Set as default',
        createNewSchedule: 'Create new schedule',
        enterTemplateName: 'Enter template name',
        googleMeet: 'Google Meet',
        emailPreview: 'Email Preview',
        addTemplate: 'Add Template',
        simplifyYourWorkflowGroupYourSequenceTemplatesToKe: 'Simplify your workflow! Group your sequence templates to keep them organized, making it easy to find, edit, and use them as needed.',
        startCreatingPersonalizedTemplatesForYourSequences: 'Start creating personalized templates for your sequences! Click the \'\'+\'\' button to create a new template and streamline your communication effortlessly.',
        noTemplateYet: 'No Template Yet',
        preview: 'Preview',
        creatNewGroup: 'Create New Group',
        creatNewGroup2: 'Create new group',
        stayOrganizedWithYourSequenceTemplatesCreateGroups: 'Stay organized with your sequence templates! Create groups to effortlessly manage and locate your templates, making editing and utilization even simpler for you.',
        noGroupsHaveBeenCreated: 'No groups have been created',
        creatNewTemplate: 'Create new template',
        addSequenceTemplateToTheGroup: 'Add sequence template to the group',
        byCancelingYouEnsureThatNoChangesAreMade: 'By canceling, you ensure that no changes are made',
        sequenceTemplates: 'Sequence Templates',
        youCanDeselectAndClickSaveToRemoveTheTemplateFromT: 'You can deselect and click \'\'Save\'\' to remove the template from the group',
        selectTemplateToAddToGroup: 'Select template to add to group',
        sequenceStatus: 'Sequence Status',
        sequenceStep: 'Sequence Step',
        newField: 'New Field',
        enableTailoredDataEntryAndAdaptationToUniqueBusine: 'Enable tailored data entry and adaptation to unique business processes',
        customContactFields: 'Custom contact fields',
        modality: 'modality',
        enterADescriptionForCustomContactField: 'Enter a description for custom contact field',
        description2: 'description',
        enterADefaultValueForCustomContactField: 'Enter a default value for custom contact field',
        defaultValue: 'default Value',
        enterAExampleForCustomContactField: 'Enter a example for custom contact field',
        example: 'example',
        enterPicklistoptionsForCustomContactField: 'Enter picklist_options for custom contact field',
        newCustomContactFields: 'New Custom Contact Fields',
        engagementMetrics: 'Engagement Metrics',
        message: 'Message',
        to3: 'To:',
        receiver: 'Receiver',
        claraHan: 'Clara Han',
        createNewCategory: 'Create new category',
        bestRegards: 'Best regards,',
        thanksAgain: 'Thanks again!',
        lookingForwardToAFruitfulCollaborationIfTheresAnyt: 'Looking forward to a fruitful collaboration. If there\'\'s anything specific we need to address before we get started, please feel free to let us know.',
        jacquelineLeungProjectInCharge: 'Jacqueline Leung (Project in Charge)',
        kingLaiAdviser: 'King Lai (Adviser)',
        iAppreciateTheIntroductionToOurMainContacts: 'I appreciate the introduction to our main contacts:',
        contacted: 'Contacted',
        sendTestEmail: 'Send test email',
        thankYouSoMuchForTheWarmWelcomeWereEquallyExcitedA: 'Thank you so much for the warm welcome! We\'\'re equally excited about kicking off our project with Pubrio GTM and being a part of Venture AI\'\'s Team.',
        hiJacqueline: 'Hi Jacqueline,',
        nov152023118Pm: 'Nov 15, 2023, 1:18 PM',
        message2: '{total_messages} Message',
        topEcommerceAutomationPlatf: 'Top E-commerce Automation Platf...',
        cindyHsu: 'Cindy Hsu',
        includeSignature: 'Include signature',
        ch: 'CH',
        continueOnFail: 'Continue on fail',
        welcomeToPubrioGtm: 'Welcome to Pubrio GTM',
        replyToPreviousThread: 'Reply to previous thread',
        sendAutomatic: 'Send automatic {title}',
        searchByContacts: 'Search by contacts',
        processingErrorOccurredMaximumNumberOfUniqueContac: 'Processing error occurred: Maximum number of unique contacts per month is reached',
        oct140954Pm: 'Oct 14, 09:54 PM',
        shiftdragToMoveTheCanvas: 'Shift+drag to move the canvas',
        active: 'Active',
        inactive: 'Inactive',
        archived: 'Archived',
        archive: 'Archive',
        unarchive: 'Unarchive',
        archivedSuccessfully: 'Archived successfully',
        unarchivedSuccessfully: 'Unarchived successfully',
        sequenceArchivedSuccess: 'sequence archived successfully',
        sequenceUnarchivedSuccess: 'sequence unarchived successfully',
        alphabetical: 'Alphabetical',
        creationDate: 'Creation Date',
        viewDate: 'View Date',
        staredFirst: 'stared First',
        SaveAsANewVersion: 'Save as a new version',
        SaveCurrentVersion: 'Save current version',
        schedules: 'Schedules',
        EmailsSentPerDay: 'Emails Sent Per Day',
        EmailsSentPerHour: 'Emails Sent Per Hour',
        DelayBetweenEachEmailSent: 'Delay Between Each Email Sent (seconds)',
        RecommendedDailyLimit: "Recommended daily limit: 50-100 emails, with exceptions for 100+ only if you're sending highly personalized campaigns and maintain a strong domain reputation.",
        RecommendedHourlyLimit: 'Recommended hourly limit: Keep the number of emails under 100. Do not exceed 120 emails per hour.',
        currentDelayAllows: 'The current delay allows you to send a maximum of 120 emails per hour.',
        SetAsDefault: 'Set as default',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        Templates: 'Templates',
        Analytics: 'Analytics',
        Meetings: 'Meetings',
        Settings: 'Settings',
        EnterTemplateName: 'Enter template name',
        EnterGroupName: 'Enter group name',
        ExportAllData: 'Export All Data',
        ExportAsCSV: 'Export as CSV',
        Total: 'Total',
        Opened: 'Opened',
        Clicked: 'Clicked',
        NotOpened: 'Not Opened',
        Bounced: 'Bounced',
        Replied: 'Replied',
        Failed: 'Failed',
        Inbox: 'Inbox',
        Report: 'Report',
        Log: 'Log',
        UseThisTemplate: 'Use this Template',
        UseThisFolder: 'Use this Folder',
        UpdateThisTemplate: 'Update this Template',
        RemoveFromGroup: 'Remove from Group',
        DeleteThisTemplate: 'Delete this Template',
        MarkAsFinished: 'Mark as Finished',
        Remove: 'Remove',
        Pause: 'Pause',
        Resume: 'Resume',
        AddToSequence: 'Add to Sequence',
        Export: 'Export',
        InProgress: 'In Progress',
        Finished: 'Finished',
        Paused: 'Paused',
        Engaged: 'Engaged',
        Reply: 'Reply',
        NotSent: 'Not Sent',
        MaxNumberOfEmailsPerDay: 'Max Number of Emails Per Day',
        MaxNumberOfEmailsPerHour: 'Max Number of Emails Per Hour',
        EnableOpensTracking: 'Enable Opens Tracking',
        EnableReplyTracking: 'Enable Reply Tracking',
        EnableClickTracking: 'Enable Click Tracking',
        ReplyByMessage: 'Reply by Message',
        BookAMeeting: 'Book a Meeting',
        ClickOnLink: 'Click on Link',
        CreateManually: 'Create Manually',
        AddContactsToTheSequenceManually: 'Add contacts to the sequence manually, one by one.',
        ImportFromCSV: 'Import from CSV',
        UploadCSVFileToAddContactsToSequence: 'Upload a CSV file to add contacts to the sequence.',
        AddFromExisting: 'Add from Existing',
        AddExistingContactsIntoTheSequence: 'Add existing contacts into the sequence.',
        ChooseFile: 'Choose File',
        MapAttributes: 'Map Attributes',
        Options: 'Options',
        ReviewAndImport: 'Review and Import',
        UpdateMissingFields: 'Update Missing Fields',
        DetectContactTimeZoneAutomatically: 'Detect contact time zone automatically',
        account_website: 'Account Website',
        linkedin_url: 'Linkedin URL',
        raw_address: 'Raw Address',
        first_name: 'First Name',
        last_name: 'Last Name',
        account_name: 'Account Name',
        Identifier: 'Identifier',
        firstName: 'First Name',
        lastName: 'Last Name',
        EnterJobTitleOrPosition: 'Enter job title or position',
        EnterCompanyName: 'Enter company name',
        EnterYourCustomerID: 'Enter your customer ID',
        EnterCompanyWebsiteURL: 'Enter company website URL',
        EnterLinkedInProfileURL: 'Enter LinkedIn profile URL',
        EnterCity: 'Enter city',
        versionName: 'Version Name',
        PleaseEnterANewVersionName: 'Please enter a new version name',
        EnterNewVersionName: 'Enter new version name',
        versions: 'Versions',
        SequenceNameUpdatedSuccessfully: 'Sequence name updated successfully',
        SequenceNameCannotBeEmpty: 'Sequence name cannot be empty',


        delete_success: 'Deleted successfully',
        delete_fail: 'Delete failed',
        set_default_success: 'Set default successfully',
        DuplicateVersionSuccessfully: 'Duplicated version successfully',
        CreateNewVersionSuccessfully: 'Created new version successfully',
        UpdateSuccessfully: 'Updated successfully',
        AddToGroupSuccessfully: 'Added to group successfully',
        sendAutomaticEmail: 'Send automatic email',
        sendMessage: 'Send message',
        uploadFailed: 'Upload failed',
        InvalidEmailFormat: 'Invalid email format',
        FailedToSetUpEmailAccount: 'Failed to set up email account',
        noAccount: 'No account',
        addAnAccountToPersonalizYourExperienceAndUnlock: 'Add an account to personalize your experience and unlock premium features ',
        trackingFeaturesForThisSequence: 'tracking features for this sequence.',
        UpdatedSuccessfully: 'Updated successfully',
        update_fail: 'Update failed',
        connectYourGoogleMeetAccountToPubrioConversations: 'Connect your Google Meet account to Pubrio Conversations',
        set_default_fail: 'Set default failed',
        duplicatedSuccessfully: 'Duplicated successfully',
        noStepsInSequencePleaseAddStepsFirst: 'No steps in sequence, Please add steps first',
        addLabelSuccessfully: 'Added label successfully',
        PleaseEnterSequenceName: 'Please enter a sequence name',
        sequenceNameCannotBeEmpty: 'Sequence name cannot be empty',
        sequenceNameUpdatedSuccessfully: 'Sequence name updated successfully',
        createStepFlowSuccessfully: 'Created step flow successfully',
        updateStepFlowSuccessfully: 'Updated step flow successfully',
        testEmailSentSuccessfully: 'Test email sent successfully',
        removeEmailAccountSuccessfully: 'Removed email account successfully',
        pleaseSelectTheDataToBeExported: 'Please select the data to be exported',
        confirm: 'Confirm',
        saveFailed: 'Save failed',
        deleteThisStep: 'Delete this step',
        Email: 'Email',
        LinkedIn: 'LinkedIn',
        API: 'API',
        Task: 'Task',
        Custom: 'Custom',
        proceedImmediately: 'Proceed immediately',
        dayss: 'day/s',
        hourss: 'hour/s',
        minutess: 'minute/s',
        theConditionAndAllTheStepsThatFollowFromItWillBeDeleted: 'The condition and all the steps that follow from it will be deleted, do you want to continue?',
        sendConnectionRequest: 'Send connection request',
        viewProfile: 'View profile',
        callAnAPI: 'Call an API',
        createAManualTask: 'Create a manual task',
        hasEmailAddress: 'Has Email address',
        hasLinkedInURL: 'Has LinkedIn URL',
        hasPhoneNumber: 'Has phone number',
        customCondition: 'Custom condition',
        openedEmail: 'Opened email',
        clickOnLinkInEmail: 'Click on link in Email',
        unsubscribeFromEmail: 'Unsubscribe from email',
        bookAMeeting: 'Book a meeting',
        acceptedInvite: 'Accepted invite',
        conditions: 'Conditions',
        Yes: ' Yes ',
        No: ' No ',
        newVariable: 'New Variable',
        selectVariable: 'Select Variable',
        ifLeadVariable: 'If lead variable ',
        condition: 'Condition',
        selectCondition: 'Select Condition',
        value: 'Value',
        contains: 'Contains',
        is_equal_to: 'Is equal to',
        is_empty: 'Is empty',
        is_not_empty: 'Is not empty',
        picture: 'Picture',
        company_domain: 'Company Domain',
        company_name: 'Company Name',
        icebreaker: 'Icebreaker',
        change_steps_conditions: 'Change Steps/Conditions',
        ForceASpecificSenderForThisStep: 'Force a specific sender for this step',
        method: 'Method',
        selectASender: 'Select a sender',
        assignAPITaskTo: 'Assign API task to',
        enterCompanyNamesAndPressEnterKey: 'Enter company names and press Enter key',
        feelFreeToEditTheContentAndSaveYourChangesDirectly: 'Feel free to edit the content and save your changes directly after clicking.',
        permission: 'Permission',
        addAnyAdditionalStepsOrConditionsAsNeeded: 'Add any additional steps or conditions as needed',
        reviewAndFinalizeSequenceSettingsAndPreferences: 'Review and finalize sequence settings and preferences',

        // setting
        nameOnCard: 'Name on card',
        expiryDate: 'Expiry date',
        cvccvv: 'CVC/CVV',
        details: 'Details',
        notification: 'Notification',
        markAsAllRead: 'Mark as all read',
        Personal: 'Personal',
        profile: 'Profile',
        extensions: 'Extensions',
        notifications: 'Notifications',
        Company: 'Company',
        roles: 'Roles',
        teams: 'Teams',
        'current-usage': 'Current Usage',
        'upgrade-plan': 'Upgrade Plan',
        Products: 'Products',
        APIKeys: 'API Keys',
        popular: 'Popular',
        priceSequence: 'Sequence (Coming Soon)',
        free: 'Free',
        lite: 'Lite',
        growth: 'Growth',
        business: 'Business',
        enterEmailAddressesMultipleCommaSeparated: 'Enter email addresses [multiple, comma-separated]',
        sharingRulesSelectTeamToEnableThisField: 'Sharing rules[Select team to enable this field]',
        allSeatsAreCurrentlyOccupiedAnyNewUsersNeedingAPaidSeatWillIncurAdditionalCharges: 'All seats are currently occupied. Any new users needing a paid seat will incur additional charges.',
        dueToday: 'Due Today',
        costPerBillingCycle: 'Cost per billing cycle',
        'select...': 'Select...',
        invite: 'Invite',
        services: 'Services',
        'services-group': 'Services Group',
        'all-notification-services': 'All Notification Services',
        'services-status-update': 'Services Status Update',
        'system-group': 'System Group',
        'all-notification-system': 'All Notification System',
        'system-new-feature-release': 'System New Feature Release',
        'system-news-letter': 'System News Letter',
        'system-generic-alarm': 'System Generic Alarm',
        markAsRead: 'Mark as read',
        showLess: 'Show less',
        showMore: 'Show more',
        changedStatusTo: 'changed status to',
        'access-management-group': 'Access Management Group',
        'access-management-roles': 'Access Management Roles',
        'access-management-create-roles': 'Access Management Create Roles',
        'access-management-edit-user-roles': 'Access Management Edit User Roles',
        'access-management-add-role-to-users': 'Access Management Add Role To Users',
        'access-management-delete-users-role': 'Access Management Delete Users Role',
        'access-management-delete-roles': 'Access Management Delete Roles',
        'access-management-read-roles-and-role-users': 'Access Management Read Roles And Role Users',
        'access-management-teams': 'Access Management Teams',
        'access-management-delete-users-team': 'Access Management Delete Users Team',
        'access-management-read-teams-sharing-rules-teams-users': 'Access Management Read Teams Sharing Rules Teams Users',
        'access-management-create-teams': 'Access Management Create Teams',
        'access-management-edit-teams-sharing-rules': 'Access Management Edit Teams Sharing Rules',
        'access-management-delete-teams-sharing-rules': 'Access Management Delete Teams Sharing Rules',
        'access-management-add-and-update-team-to-users': 'Access Management Add And Update Team To Users',
        'access-management-read-team-relations': 'Access Management Read Team Relations',
        'access-management-modify-team-relations': 'Access Management Modify Team Relations',
        'intent-signal-group': 'Intent Signal Group',
        'market-intent-signal': 'Market Intent Signal',
        'web-intent-signal': 'Web Intent Signal',
        'web-intent-signal-add-and-activate': 'Web Intent Signal Add And Activate',
        'web-intent-signal-read-data': 'Web Intent Signal Read Data',
        'web-intent-signal-export-data': 'Web Intent Signal Export Data',
        'web-intent-signal-delete-data': 'Web Intent Signal Delete Data',
        'web-intent-signal-read-analytic': 'Web Intent Signal Read Analytic',
        'search-group': 'Search Group',
        'company-search': 'Company Search',
        'prospects-search': 'Prospects Search',
        'sequence-group': 'Sequence Group',
        'sequences': 'Sequences',
        'service-create-request': 'Service Create Request',
        'service-update-request': 'Service Update Request',
        'service-read-request': 'Service Read Request',
        'service-delete-service-attachment': 'Service Delete Service Attachment',
        'service-delete-service-message': 'Service Delete Service Message',
        'service-send-and-edit-service-message': 'Service Send And Edit Service Message',
        'service-upload-service-attachment': 'Service Upload Service Attachment',
        'settings-group': 'Settings Group',
        'custom-fields': 'Custom Fields',
        'user-management-and-billing-group': 'User Management And Billing Group',
        'billing-read-credit-card': 'Billing Read Credit Card',
        'billing-download-invoice': 'Billing Download Invoice',
        'billing-read-invoice': 'Billing Read Invoice',
        'credit-usage': 'Credit Usage',
        'credit-usage-read-profile-detail': 'Credit Usage Read Profile Detail',
        'credit-usage-read-profile-usage': 'Credit Usage Read Profile Usage',
        'profile-and-team': 'Profile And Team',
        'profile-and-team-invite-team-member': 'Profile And Team Invite Team Member',
        'profile-and-team-update-profile': 'Profile And Team Update Profile',
        'profile-and-team-delete-team-member': 'Profile And Team Delete Team Member',
        'profile-and-team-get-user-secret-token': 'Profile And Team Get User Secret Token',
        'profile-and-team-update-user-and-password': 'Profile And Team Update User And Password',
        'profile-and-team-read-profile-users': 'Profile And Team Read Profile Users',
        'upgrade-plan-read-subscription': 'Upgrade Plan Read Subscription',
        'upgrade-plan-upgrade-subscription': 'Upgrade Plan Upgrade Subscription',
        'all-access-access-management-roles': 'All Access Access Management Roles',
        'all-access-access-management-teams': 'All Access Access Management Teams',
        'all-access-market-intent-signal-page': 'All Access Market Intent Signal Page',
        'all-access-web-intent-signal-page': 'All Access Web Intent Signal Page',
        'all-access-company-search-page': 'All Access Company Search Page',
        'all-access-prospects-search-page': 'All Access Prospects Search Page',
        'all-access-sequence-page': 'All Access Sequence Page',
        'all-access-service-page': 'All Access Service Page',
        'all-access-custom-fields-page': 'All Access Custom Fields Page',
        'all-access-billing-page': 'All Access Billing Page',
        'all-access-credit-usage-page': 'All Access Credit Usage Page',
        'all-access-profile-and-team-page': 'All Access Profile And Team Page',
        newPermissionProfile: 'New Permission Profile',
        enterAName: 'Enter a name',
        addUserToRole: 'Add user to role',
        changePermission: 'Change permission',
        selectPermissionProfiles: 'Select permission profiles',
        backToRoles: 'Back to roles',
        updateRole: 'Update role',
        enterADescription: 'Enter a description',
        createANewRole: 'Create a new role',
        AreYouSureYouWantToRemoveThisRole: 'Are you sure you want to remove this role?',
        AreYouSureYouWantToRemoveThisUserFromTheTeam: 'Are you sure you want to remove this user from the team?',
        ProceedingWithDeletionWillPermanentlyRemoveTheRoleFromTheUser: 'Proceeding with deletion will permanently remove the role from the user.',
        ProceedingWithDeletionWillPermanentlyRemoveTheUserFromTheTeam: 'Proceeding with deletion will permanently remove the user from the team.',
        relation: 'Relation',
        newTeam: 'New Team',
        'full-sharing-market-intent-signal-data': 'Full Sharing Market Intent Signal Data',
        'full-sharing-web-intent-signal-data': 'Full Sharing Web Intent Signal Data',
        'full-sharing-company-search-data': 'Full Sharing Company Search Data',
        'full-sharing-prospects-search-data': 'Full Sharing Prospects Search Data',
        'full-sharing-sequence-data': 'Full Sharing Sequence Data',
        'full-sharing-service-data': 'Full Sharing Service Data',
        'share-service-attachments': 'Share Service Attachments',
        'share-service-messages': 'Share Service Messages',
        RenameSharingRules: 'Rename Sharing Rules',
        enterNewSharingRulesNameBelow: 'Enter new sharing rules name below:',
        enterNewSharingRulesName: 'Enter new sharing rules name',
        enterNewSharingRulesDescriptionBelow: 'Enter new sharing rules description below:',
        enterNewSharingRulesDescription: 'Enter new sharing rules description',
        enterSharingRulesName: 'Enter sharing rules name',
        currentUsers: 'Current Users',
        pendingUsers: 'Pending Users',
        addRole: 'Add role',
        addTeam: 'Add team',
        addPermission: 'Add permission',
        'Re-invite': 'Re-invite',
        relations: 'Relations',
        'is-parent-of': 'Is parent of',
        addRelation: 'Add relation',
        deleteRelation: 'Delete relation',
        updateRelation: 'Update relation',
        API_Keys: 'API Keys',
        createNewKey: 'Create new key',
        createAPIKey: 'Create API Key',
        APIs: 'APIs',
        setAsMasterKey: 'Set as master key',
        'growth-annual': "Growth-Annual ",
        business: "Business ",
        "website-intent-data": "Website Intent Data",
        'demo-identification': "Demo Identification",



        // services
        serviceMenuOverview: 'Overview',
        serviceMenuTeams: 'Teams',
        serviceMenuPipeline: 'Pipeline',
        serviceMenuAnalytics: 'Analytics',
        serviceMenuResources: 'Resources',
        salesServices: 'Sales Services',
        chooseYourTypeofServicesToStreamlineAndEnhanceYourBusinessOperations: 'Choose your type of services to streamline and enhance your business operations',
        dataPreparation: 'Data Preparation',
        salesAssistant: 'Sales Assistant',
        appointmentSetting: 'Appointment Setting',
        othersCustomService: 'Others (Custom Service)',
        requestService: 'Request Service',
        learnMore: 'Learn more',
        serviceRequestStatus: 'Service Request Status',
        noServiceRequestsYet: 'No Service Requests Yet',
        submitYourFirstRequestToUnlockThePower: "Submit your first request to unlock the power of our sales services! Track your requests' status here to supercharge your sales efforts. Let's get started!",
        serviceTableItemName: 'Name',
        serviceTableItemStatus: 'Status',
        serviceTableItemType: 'Type',
        serviceTableItemProgress: 'Progress',
        serviceTableItemStart: 'Start',
        serviceTableItemEnd: 'End',
        serviceTableItemPriority: 'Priority',
        addASubject: 'Add a subject',
        created: 'created',
        closed: 'closed',
        paused: 'paused',
        working: 'working',
        completed: 'completed',
        resuming: 'resuming',
        reviewing: 'reviewing',
        others: 'Others',
        data_preparation: 'Data Preparation',
        sales_assistant: 'Sales Assistant',
        appointment_setting: 'Appointment Setting',
        appointment_booking: 'Appointment Booking',
        low: 'Low',
        normal: 'Normal',
        urgent: 'Urgent',
        high: 'High',
        requiredCredits: 'Required Credits',
        serviceCreated: 'Created',
        QandARecord: 'Q&A Record',
        serviceAttachments: 'Attachments',
        discussionBoard: 'Discussion Board',
        wannaAddSomething: "wanna add something?",
        serviceEdit: 'Edit',
        serviceDelete: 'Delete',
        addDescription: 'Add Description',
        DragDropor: 'Drag & Drop or',
        chooseFile: 'Choose file',
        toUpload: 'to upload',
        ourDataPreparationService: 'Our Data Preparation service provides comprehensive solutions tailored to optimize your sales data management. We focus on simplifying the process, allowing you to enhance your existing datasets, ensure accuracy through data cleansing, and create customized lists that perfectly align with your needs. This approach grants you the flexibility and efficiency required to effectively manage your data.',
        moreoverWithOurDedicatedDataManagement: 'Moreover, with our dedicated data management service, Pubrio, the process becomes even more effortless. You can seamlessly attach files and augment your datasets with additional information. Pubrio offers more than just enrichment; it also allows for the creation of entirely new lists based on your specific criteria. This personalized service ensures that your datasets are precisely tailored to meet your objectives and industry standards, putting you in control of your data landscape.',
        flexibilityToAttach: "Flexibility to attach existing lists or instruct Pubrio to find new lists based on specified criteria.",
        comprehensiveDataEnrichment: "Comprehensive data enrichment to add missing details and enhance dataset completeness.",
        dataCleansingServicesToEnsureAccuracy: "Data cleansing services to ensure accuracy and quality, including duplicate removal and error correction.",
        abilityToInstructPubrio: "Ability to instruct Pubrio to curate entirely new lists based on meticulously defined criteria.",
        tailoringDatasetsPrecisely: 'Tailoring datasets precisely to align with specific objectives and industry standards.',
        dataEnrichmentWithExistingList: "Data Enrichment with Existing List: Easily enhance your current list by uploading it to Pubrio. We'll fill in missing details, such as email addresses and phone numbers, to meet your requirements. For example, provide your customer database, and we'll update it with essential information.",
        customListGenerationIf: "Custom List Generation: If you don't have a list, let Pubrio find one for you. Just tell us your criteria, like job titles or industries, and we'll create the perfect list. For instance, if you need a list of top technology executives in a specific area, we'll handle it for you.",
        dataCleansingForExistingLists: "Data Cleansing for Existing Lists: Ensure your data is accurate and reliable with Pubrio's data cleansing service. Send us your list, and we'll remove duplicates and correct any errors. For example, if you have a list of recent leads, we'll make sure it's ready for your marketing campaigns.",
        empoweringYourSalesEeavors: "Empowering your sales endeavors, our Sales Assistant service offers comprehensive support across various critical areas, ensuring your outreach and communication strategies are finely tuned for success. Whether you're looking to craft compelling content, seamlessly organize events, develop tailored presentations, or refine your sales and communication strategies, our dedicated team is here to supercharge your sales efforts and elevate your brand's presence in the market.",
        craftingCompellingContent: "Crafting Compelling Content: Effective communication is key to capturing your audience's attention. Our team excels in crafting engaging content tailored to your brand and audience preferences. From thought-provoking LinkedIn posts to informative blog articles and eye-catching social media updates, we ensure your message resonates with your target market.",
        seamlessEventAndWebinarPreparation: "Seamless Event and Webinar Preparation: Events and webinars are powerful tools for engaging with your audience and generating leads. Our Sales Assistant service takes care of all the details, from planning and promotion to execution. Whether it's hosting informative webinars or organizing networking events, we ensure your events leave a lasting impression.",
        tailoredContentDevelopmentAndPresentation: "Tailored Content Development and Presentation: Captivate your audience with compelling presentations and personalized messaging. Our team specializes in creating visually appealing decks and crafting tailored messages that resonate with specific audience segments. Whether you're pitching to investors or nurturing leads, we ensure your content stands out.",
        strategicSalesAndCommunicationFrameworks: "Strategic Sales and Communication Frameworks: Streamline your sales processes with strategic guidance and effective communication scripts. Our Sales Assistant service equips your team with the tools and strategies needed to drive conversions and foster meaningful connections.",
        craftingCompellingContent: "Crafting Compelling Content",
        companyLinkedInPosts: "Company LinkedIn Posts (Frequency: Weekly or monthly)",
        personalLinkedInPosts: "Personal LinkedIn Posts (Frequency: Flexible, based on preference and content availability)",
        websiteBlogPosts: "Website Blog Posts (Frequency: Typically weekly or bi-weekly)",
        socialMediaPosts: "Social Media Posts (Frequency: Daily or as per content strategy)",
        emailMarketingCampaigns: "Email Marketing Campaigns (Frequency: Weekly or bi-weekly)",
        seamlessEventAndWebinarPreparation: "Seamless Event and Webinar Preparation",
        webinarAndWorkshopPreparation: "Webinar and Workshop Preparation",
        networkingAndOfflineEvent: "Networking and Offline Event Coordination",
        tailoredContentDevelopment: "Tailored Content Development and Presentation",
        companyDeckPreparation: "Company Deck Preparation (For presentations, pitches, etc.)",
        highlyPersonalizedMessage: "Highly Personalized Message Creation (Tailored for specific audience segments)",
        strategicSales: "Strategic Sales and Communication Frameworks",
        salesCallScripts: "Sales Call Scripts",
        emailOutreachTemplate: "Email Outreach Templates",
        salesChannelStrategies: "Sales Channel Strategies",
        ourAppointmentSettingService: "Our Appointment Setting service is tailored to elevate your business by facilitating crucial connections, fostering industry exposure, and nurturing valuable prospects. We specialize in individualized industry exposure, seamless seminar and webinar coordination, warming up new prospects, and initiating engaging approaches to new audiences.",
        individualIndustryAppointment: "Individual Industry Appointment Exposure and Reaction",
        tailoredOutreach: "Tailored outreach to key decision-makers and influencers within your industry ensures targeted exposure.",
        strategicFollowups: "Strategic follow-ups allow us to gauge reactions, address concerns, and foster deeper engagements.",
        eachInteraction: "Each interaction is personalized based on the recipient's profile and preferences, maximizing impact.",
        seminarWebinarCoordination: "Seminar & Webinar Coordination",
        ourExpertTeamHandles: "Our expert team handles every aspect of seminar and webinar coordination, from initial planning to post-event follow-up.",
        weMeticulouslyPlan: "We meticulously plan and execute events to showcase your expertise, ensuring a seamless and engaging experience for participants.",
        byLeveragingAdvancedTechnologyPlatforms: "By leveraging advanced technology platforms, we enhance interaction and participation, maximizing the impact of your events.",
        newProspectWarmUp: "New Prospect Warm-Up",
        proactiveEngagementStrategies: "Proactive engagement strategies are employed to warm up new prospects, laying the groundwork for fruitful discussions.",
        tailoredCommunicationStrategies: "Tailored communication strategies are deployed to build rapport, trust, and interest in your offerings.",
        throughStrategicNurturing: 'Through strategic nurturing, we keep prospects engaged and primed for conversion, increasing the likelihood of successful appointments.',
        newAudienceApproach: "New Audience Approach",
        weEmployInnovativeStrategies: "We employ innovative strategies to reach new audiences and expand your business's reach beyond traditional channels.",
        targetedOutreachCampaigns: "Targeted outreach campaigns are crafted based on thorough audience research and segmentation, ensuring relevance and effectiveness.",
        ourApproachIsContinuouslyOptimized: "Our approach is continuously optimized based on feedback and insights, ensuring that we stay ahead of the curve in reaching and engaging new audiences.",
        individualIndustry: "Individual Industry Appointment Exposure and Reaction",
        personalizedAppointmentSetting: "Personalized appointment setting with industry stakeholders.",
        strategicFollowupCommunication: "Strategic follow-up communication for further engagement.",
        comprehensivePlanning: "Comprehensive planning and execution of events.",
        utilizationOfAdvancedTechnologies: "Utilization of advanced technologies for enhanced interaction.",
        newProspectWarmUp: 'New Prospect Warm-Up',
        proactiveEngagementStrategy: "Proactive engagement strategy tailored to prospects.",
        customizedCommunication: "Customized communication to maintain interest.",
        newAudienceApproach: "New Audience Approach",
        innovativeOutreach: "Innovative outreach campaigns designed for new audiences.",
        continuousRefinement: "Continuous refinement based on feedback and insights.",
        customService: "Custom Service",
        tailoredToMeet: "Tailored to meet your unique needs, our Custom Service offering provides flexibility and adaptability to address a wide range of requirements. Whether you're seeking assistance with webinar participant tracking, offline event promotion, ads enhancement, or any other specialized task, our dedicated team is here to help you achieve your goals efficiently and effectively.",
        webinarParticipantsTracking: "Webinar Participants Tracking",
        comprehensiveTrackingOfWebinar: "Comprehensive tracking of webinar participants to analyze engagement levels and gather valuable insights.",
        customizedReportsAndAnalytics: "Customized reports and analytics to assess the effectiveness of your webinars and optimize future strategies.",
        seamlessIntegration: "Seamless integration with your existing systems for streamlined data management and reporting.",
        offlineEventPromotion: "Offline Event Promotion",
        strategicPlanning: "Strategic planning and execution of offline event promotion campaigns to maximize attendance and engagement.",
        utilizationOfTargetedMarketingChannels: 'Utilization of targeted marketing channels and promotional tactics to reach your desired audience effectively.',
        creativeApproaches: 'Creative approaches tailored to the unique characteristics of each event, ensuring maximum impact and ROI.',
        adsEnhancement: "Ads Enhancement",
        optimizationOfAdCampaigns: "Optimization of ad campaigns across various platforms to enhance visibility, engagement, and conversion rates.",
        ABTesting: "A/B testing and performance analysis to identify the most effective ad creatives, messaging, and targeting strategies.",
        continuousMonitoring: "Continuous monitoring and adjustment of ad campaigns to adapt to changing market dynamics and maximize results.",
        customizedSolutions: "Customized Solutions",
        ourCustomService: "Our Custom Service offering extends beyond predefined features to address your specific requirements and preferences.",
        whetherYouNeedAssistance: "Whether you need assistance with data analysis, content creation, strategic planning, or any other aspect of your business, we can tailor our services to suit your needs.",
        ourFlexibleApproach: "Our flexible approach allows us to collaborate closely with you to develop customized solutions that deliver tangible results and drive business growth.",
        setupAndImplementation: "Setup and implementation of webinar participant tracking systems.",
        customizedReporting: "Customized reporting and analytics to measure webinar performance and audience engagement.",
        developmentAndExecution: "Development and execution of targeted offline event promotion campaigns.",
        coordinationOfPromotionalActivities: "Coordination of promotional activities, including print materials, direct mail, and local advertising.",
        evaluationAndOptimization: "Evaluation and optimization of ad campaigns across digital platforms.",
        creativeDevelopment: "Creative development, targeting refinement, and performance monitoring to maximize ROI.",
        tailoredServices: "Tailored services to address specific needs and objectives.",
        collaborationWithOurTeam: "Collaboration with our team to develop customized solutions that meet your unique requirements.",
        serviceGetStarted: "Get Started",
        keyFeatures: 'Key Features',
        dataEnrichment: 'Data Enrichment',
        dataCleansing: "Data Cleansing",
        customListGeneration: "Custom List Generation",
        serviceOfferings: "Service Offerings",
        introDataPreparation: "Our Data Preparation service provides comprehensive solutions tailored to optimize your sales data management. We focus ...",
        introSalesAssitant: 'The Sales Assistant service goes beyond general administrative support by specifically focusing on tasks integral to the sales process. It ...',
        introAppointmentSetting: "This service involves a personalized and hands-on approach to connecting customers with their target audience based on the provid ...",
        introOtherService: 'The "Others" service, which allows customers to request custom services by submitting details about their unique needs, falls unde ...',
        questionCommonPagePriorityLevelTitle: 'What is your priority level for this task?',
        questionCommonPagePriorityLevelDesc: 'Stating your task priority helps us address your needs promptly. You can change it post-submission.',
        questionCommonPageTimelineTitle: 'Thinking about a subscription term or timeline?',
        questionCommonPageTimelineDesc: 'Let us know your preferred timeframe and when you plan to start.',
        questionCommonPageServiceRequestTitle: 'Ready to start? First, give your application a distinctive name',
        questionCommonPageServiceRequestDesc: "We'll use it to track and update you on the progress in your dashboard.",
        questionCommonPageServiceDurationTitle: "How long will your service period last?",
        quesitonCommonPageInvestmentBudget: "Does your investment budget for business development and projects exceed HKD 12000 per month?",
        questionCommonPageHearAboutUs: 'Where did you hear about us?',
        questionCommonPageUploadTitle: 'Please upload your Excel/CSV file here for data enrichment or data cleansing',
        questionCommonPageUploadDesc: "You may skip or click 'Next' without uploading if you choose to create a custom list generation",
        questionDataPreparationPageInterestedDataOptimizationTitle: 'Which services are you interested in for your data optimization?',
        questionDataPreparationPageInterestedDataOptimizationDesc: 'You are required to attach a CSV file in the next step for choosing either data enrichment or data cleansing',
        questionDataPreparationPageDataAttributesTitle: 'Choose the specific data attributes you wish to incorporate into the file (if applicable)',
        questionDataPreparationPageDataAttributesDesc: 'Select one or more',
        questionDataPreparationPageNumberOfLeadsTitle: 'How many leads are you looking to generate or acquire for your business?',
        questionDataPreparationPageNumberOfLeadsDesc: 'Specify the quantity or range to customize our services for you',
        website_url: 'website url',
        contact_first_name: 'contact first name',
        contact_last_name: 'contact last name',
        contact_title: 'contact title',
        contact_location: 'contact location',
        contact_work_email: 'contact work email',
        contact_personal_email: 'contact personal email',
        contact_work_phone: 'contact work phone',
        contact_personal_phone: 'contact personal phone',
        company_size: 'company size',
        company_industry: 'company industry',
        company_location: 'company location',
        company_founded_year: 'company founded year',
        company_keywords: 'company keywords',
        company_ranking_in_global: 'company ranking in global',
        company_financial_status: 'company financial status',
        company_office_address: 'company office address',
        website_total_visit: 'website total visit',
        company_funding_status: 'company funding status',
        slugServiceName: 'Service Name',
        slugServiceDesc: 'Service Description',
        errEnterServiceName: 'please enter service name',
        errEnterDescription: "please enter service description",
        errSelectAService: "please select a service",
        errSelectSpecificDataAttributes: "please select specific data attributes",
        errInputTheQuantity: "please input the quantity",
        errSelectPriorityLevel: "please select your priority level",
        errSelectTimeline: "please select timeline",
        errInputContent: "please input content",
        errInvestmentBudget: "please select yes or no",
        errServiceDuration: "please select service duration",
        errHearAboutUs: 'please select content',
        placeHolderEnterServiceName: "Enter service name",
        placeHolderEnterServiceDescription: 'Enter service description',
        placeHolderEnterListGeneration: "Enter list generation",
        placeHolder150TO250: "150 - 250",
        Submit: 'Submit',
        serviceDownload: "Download",
        serviceExample: 'example',
        forYourReference: 'for your reference',
        yourFileNeedsToInclude: 'Your file needs to include at least websites or company names',
        includeAll: 'Include all',
        serviceNext: 'Next',
        serviceBack: "Back",
        data_enrichment: "Data Enrichment",
        data_cleansing: "Data Cleansing",
        custom_list_generation: "Custom List Generation",
        job_title: "Job Title",
        timeline_within_one_weeks: "Timeline within one weeks",
        timeline_one_to_two_weeks: 'Timeline one to two weeks',
        timeline_two_to_four_weeks: "Timeline two to four weeks",
        timeline_recurring: "Timeline recurring",
        timeline_custom_range: "Timeline custom range",
        hear_about_us_social_media: 'social media',
        hear_about_us_google: 'google',
        hear_about_us_online_article: "online article",
        hear_about_us_webinar: 'webinar',
        hear_about_us_referral: "referral",
        hear_about_us_custom_input: 'others',
        duration_last_than_one_month: "last than one month",
        duration_one_to_three_months: 'one to three months',
        duration_over_three_months: 'over three months',
        duration_decide_later: "decide later",
        yes: "Yes",
        no: "No",
        byCancelingYouCanEnsureThatNoTasksWillBeClosed: 'By canceling, you ensure that no tasks will be closed',
        areYouSureYouWantToCloseThisTask: 'Are you sure you want to close this task?',
        onceTheTaskIsClosed: "Once the task is closed, it cannot be undone, and you won't be able to resume it",
        Close: 'Close',
        ourTeamIsWorkingOnThisTask: "Our team is working on this task",
        pleaseProviceYourReasonForThisAction: "Please provide your reason for this action",
        sendRequest: 'Send Request',
        OK: "OK",
        areYouSureYouWantToDelete: "Are you sure you want to delete",
        proceedingWithDeletionWillResultInPermanentRemovalOfTheFile: "Proceeding with deletion will result in permanent removal of file.",
        byCancelYouEnsureThatNoFileWillBeDeleted: "By canceling, you ensure that no file will be deleted",
        areYouSureYouWantToDeleteThis: "Are you sure you want to delete this",
        serviceMessage: "message",
        serviceFile: "file",
        proceedingWithDeletionWillResultInPermanentRemovalOfThisMessage: "Proceeding with deletion will result in permanent removal of this message.",
        proceedingWithDeletionWillResultInPermanentRemovalOfThisFile: "Proceeding with deletion will result in permanent removal of this file.",
        youEnsureThatNoMessagesWillBeDeleted: "By canceling, you ensure that no messages will be deleted",
        youEnsureThatNoFilessWillBeDeleted: "By canceling, you ensure that no files will be deleted",
        serviceDeleteFail: 'Delete failed',
        enterYourReason: "Enter your reason",
        seriveStartDate: "Start Date",
        serviceEndDate: "End Date",
        yourRequestHasBeenSent: "Your request has been sent",
        sendUsYourFeedback: "Send us your feedback",
        weAreConstantlyImproving: "We're constantly improving! Share your feedback to shape the future of our platform.",
        describeYourExperienceHere: "Describe your experience here...",
        additionallyYouCanAttachAnyScreenshots: "Additionally, you can attach any screenshots or files to provide further details.",
        sendFeedback: 'Send Feedback',
        serviceResume: 'Resume',
        yourRequestHasBeenResumed: 'Your request has been resumed',
        yourRequestIsCurrentlyPaused: 'Your request is currently paused',
        serviceEdited: 'Edited',
        theFileSizeCannotExceed50M: "The file size cannot exceed 50M",
        activateTheNewVersionNow: 'Activate the new version now',

        feedbackSendSuccessfully: "feedback sent successfully",
        feedbackSendFailed: "feedback sending failed",

        autoSave: 'Auto save',
        serviceDescShowMore: 'Show more',
        serviceDescShowLess: 'Show less',
        members: "Members",
        shareTo: "Share to...",
        findTeamMembers: "Find team members",
        select: 'Select',
        whenBalanceFallsBelow: 'When balance falls below',
        defaultPaymentMethod: 'Default Payment Method',
        creditCardDetails: 'Credit Card Details',
        automaticallyTopUp: 'Automatically top up',
        autoTopupWillAutomaticallyChargeYourPaymentMethodT: 'Auto top-up will automatically charge your payment method. To disable it, leave this box unchecked',
        enableAutoTopups: 'Enable auto top-ups',
        validThru: 'VALID THRU',
        currentBalance: 'Current balance',
        cardHolder: 'CARD HOLDER',
        gotIt: 'Got it',
        pleaseEnsureYourPaymentMethodIsUptodateAndThatYouA: 'Please ensure your payment method is up-to-date and that you are comfortable with this automatic process before enabling.',
        thresholdCredits: 'Threshold: {creditLimit} credits',
        creditAmountCredits: 'Credit Amount: {choosedTopUpCredit} credits',
        enablingAutoTopupsWillAutomaticallyAddCreditsToYou: 'Enabling auto top-ups will automatically add credits to your account when your balance falls below the specified threshold. This may result in automatic charges to your selected payment method.',
        thisWillAutomaticallyAddCreditsToYourAccountWhenev: 'This will automatically add {choosedTopUpCredit} credits to your account whenever your balance drops below {creditLimit} credits',
        autoTopupNotice: 'Auto top-up notice',
        roleName: 'Role Name',
        addUser: 'Add User',
        newRole: 'New Role',
        userName: 'User Name',
        joined: 'Joined',
        thisRoleCurrentlyHasNoUsersAssignedToItStartAddingTeamMembersToDefineTheirResponsibilitiesAndStreamlineYourWorkflow: 'This role currently has no users assigned to it. Start adding team members to define their responsibilities and streamline your workflow.',
        noUsersAssigned: 'No users assigned',


        addUserToTeam: 'Add user to team',
        user: 'User',
        sharingRuleGroup: 'Sharing Rule Group',
        updateTeam: 'Update Team',
        createANewTeam: 'Create a new team',
        backToTeams: 'Back to teams',
        listOfSharingRules: 'List of sharing rules',
        sharingRules: 'Sharing Rules',
        manageSharingRules: 'Manage sharing rules',
        CreateAndCustomizeSharingRulesToControlAccessForDifferentUsersClickTheButtonBelowToAddNewSharingRules: 'Create and customize sharing rules to control access for different users. Click the button below to add new sharing rules.',
        newSharingRules: 'New Sharing Rules',


        // price
        "exportLimit": "Export Limit",
        "customRolePermission": "Custom Role Permission",
        "teamManagement": "Team Management",
        "API": "API",
        "linkedinAccount": "Linkedin Account",
        "perPageLimit": "Per Page Limit",
        "recordUploadLimit": "Record Upload Limit",
        "creditRollover": "Credit Rollover",
        "quotaSharing": "Quota Sharing",
        "quotaAdjustment": "Quota Adjustment",
        "add_On": "Add-On",
        "topUpCredits": "Top Up Credits",
        "webVisitorIdentification": "Web Visitor Identification",
        "dataExportCredit":"Data Export",
        "companySearchCredit":"Company Search",
        "peopleSearchCredit":"Prospect Search",
        "emailRedeemCredit":"Email Search",
        "phoneNumberRedeemCredit":"Phone Number Search",
        "recordSelection": "Record Selection",
        "portal": "Portal",
        "queryLimitPerHour": "Query Limit Per Hour",
        "queryLimitPerDay": "Query Limit Per Day",
        "callLimitPerHour": "Call Limit Per Hour",
        "callLimitPerDay": "Call Limit Per Day",
        "callLimitPerMonth": "Call Limit Per Month",
        "basicSequencing": "Basic Sequencing",
        "numberOfSequences": "Number of Sequences",
        "numberOfMailboxesPerUser": "Number of Mailboxes Per User",
        "numberOfLinkedinAccountPerUser": "Number of Linkedin Account Per User",
        "numberOfSlackAccountPerUser": "Number of Slack Account Per User",
        "automatedFollowUp": "Automated Follow Ups",
        "advancedSequencing": "Advanced Sequencing",
        "abTesting": "A/B Testing",
        "multipleEmailTemplates": "Multiple Email Templates",
        "reporting": "Reporting",
        "emailOpenTracking": "Email Open Tracking",
        "emailClickTracking": "Email Click Tracking",
        "emailReplyTracking": "Email Reply Tracking",
        "support": "Support",
        "priorityEmailSupport": "Priority Email Support",
        "companySelectionLimit": "Company Selection Limit",
        "prospectSelectionLimit": "Prospect Selection Limit",
        "totalPageLimit": "Total Page Limit",
        "pricingYear": "Year",
        "growth-annual": "Growth",
        "business-annual": "Business",
        "pricingSearch": "Search",
        "pricingSearches": "Searches",
        "pricingMonth": "Month",
        "pricingYear": "Year",
        "topupCredit": "topup credit",
        "perUserPerYear": "Per user, per year \n Billed annually",
        "perUserPerMonth": "Per user, per month \n Billed monthly",
        "customCredits": "Custom Credits",
        "customSearches": "Custom Searches",
        "queryLimitPerMonth": "Query Limit Per Month",
        "customized": "Custom",
        "custom": "Custom ",
        "free": "Free",
        "growth": "Growth",
        "business": "Business",
        "organization": "Organization",
        "search": "Search",

        // setting users
        pleaseSelectTheUserYouWantToAdd: "Please select the users you want to add",
        pleaseSelectTheUserYouWantToDelete: "Please select the users you want to delete",
        pleaseSelectTheUserYouWantToAddPermission: "Please select the users you want to add permission"


}
